import styled from 'styled-components'

import {StatusActive, StatusInactive} from '../../styles/_colors'
import {BodyText, BookFontWeight} from '../../styles/_texts'

export const StatusText = styled.div`
  font-size: ${BodyText};
  line-height: 20px;
  font-weight: ${BookFontWeight};
  color: ${({active}) => (active ? StatusActive : StatusInactive)};
`

export const StatusSpan = styled.div`
  float: left;
`

export const StatusImg = styled.img`
  float: left;
  margin-left: 10px;
  margin-top: 4px;
`
