import React, {useState} from 'react'

import {Col, Row} from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'
import {useParams} from 'react-router'
import {change, getFormValues} from 'redux-form'

import SetHasShippedIcon from '../../../../assets/icons/validation_approve.svg'
import {GetOrderB2CDetails, GetOrderB2COrderItemsDetails, GetOrderPackagesInfo, SetOrderItemDelivered} from '../../../../infra/requests/OrdersB2CRequests'
import Address from '../../../../shared/components/address/AddressComponent'
import BaseButton from '../../../../shared/components/buttons/BaseButton'
import BaseLoading from '../../../../shared/components/loading/BaseLoading'
import BaseList from '../../../../shared/components/table/BaseList'
import GetCoverImage from '../../../../shared/logic/arrays/ImageCoverFunction'
import ActiveTranslation from '../../../../shared/logic/translations/ActiveTranslation'
import {TableHeaderColor} from '../../../../shared/styles/_colors'
import {Margin} from '../../../../shared/styles/BasicStyles'
import {GetStatusColor} from '../helpers/StatusTypes'
import {TransformOrderBillingAddress, TransformOrderShippingAddress} from '../helpers/TransformContactsAddresses'
import EditOrderHeaderTitle from './EditOrderHeaderTitle'
import EditOrderStatus from './EditOrderStatus'
import {
  EditOrderTitle,
  ProductImage,
  ProductDescription,
  ProductTitle,
  ProductSelection
} from './Styles'

class OrderItemsTypes {
  static Delivered = 'DELIVERED'

  static Shipped = 'SHIPPED'

  static Unshipped = 'UNSHIPPED'
}

const GetProductsShipped = (item, orderBox) => orderBox.reduce((acc, box) =>
  acc + (box.sent ?
    box.items.reduce((subAcc, subItem) => subAcc + (subItem.orderItemId === item.orderItemId ?
      subItem.itemQuantity
      : 0), 0)
    : 0), 0)

const FinalData = ({paymentMethods, countries, currentFormValues, dispatch}) => {
  const shippingAddress = TransformOrderShippingAddress(currentFormValues)
  const billingAddress = TransformOrderBillingAddress(currentFormValues)
  const [selected, setSelected] = useState([])
  const [loading, setLoading] = useState(false)
  const enabledSetShipped = currentFormValues?.orderStatusId === 4
  const {id} = useParams()

  const setHasShipped = async () => {
    let isSuccess = true
    setLoading(true)
    for await (const element of selected) {
      const {success} = await SetOrderItemDelivered(element.orderItemId)
      if (!success) {
        isSuccess = false
        break
      }
    }

    if (isSuccess && selected.length > 0) {
      const {data} = await GetOrderB2CDetails(id)
      const resultPackages = await GetOrderPackagesInfo(id)
      const resultItems = await GetOrderB2COrderItemsDetails(id)

      dispatch(change('manage-order-b2b2c-b2c', 'orderStatus', data.orderStatus))
      dispatch(change('manage-order-b2b2c-b2c', 'orderStatusId', data.orderStatusId))
      dispatch(change('manage-order-b2b2c-b2c', 'orderItem', resultItems?.data?.items))
      dispatch(change('manage-order-b2b2c-b2c', 'orderBox', resultPackages?.data))
      setSelected([])
    }

    setLoading(false)
  }

  const setSelectedItemsWrapper = (item) => {
    if (selected.some((x) => x.orderItemId === item.orderItemId)) {
      setSelected(selected.filter((x) => x.orderItemId !== item.orderItemId))
    } else {
      setSelected([...selected, item])
    }
  }

  const renderOrderItemDetails = (type, orderItem) => (
    <>

      <ProductImage
        src={GetCoverImage(
            orderItem?.productCombination?.product?.productImage
        )}
      >
        {enabledSetShipped && type === OrderItemsTypes.Shipped && (
          <ProductSelection checked={selected.some((x) => x.orderItemId === orderItem.orderItemId)} defaultChecked={false} />
        )}
      </ProductImage>
      <Margin size='10' />
      <ProductTitle>
        <Translate id='REF' />:
      </ProductTitle>
      <ProductDescription $color={TableHeaderColor}>
        {orderItem?.productCombination?.reference}
      </ProductDescription>
      <ProductTitle>
        <Translate id='NAME' />
      </ProductTitle>
      <ProductDescription>
        <ActiveTranslation
          value={
              orderItem?.productCombination?.product?.productTranslation
          }
          tag='name'
        />
      </ProductDescription>
      <ProductTitle>
        <Translate id='SIZE' />
      </ProductTitle>
      <ProductDescription>
        {orderItem?.productCombination?.size?.number}
      </ProductDescription>
      <ProductTitle>
        <Translate id='COLOR' />
      </ProductTitle>
      <ProductDescription>
        <ActiveTranslation
          value={
              orderItem?.productCombination?.color?.colorTranslation
          }
          tag='name'
        />
      </ProductDescription>
      <ProductTitle>
        <Translate id='QUANTITY' />
      </ProductTitle>
      <ProductDescription>{orderItem?.quantity}</ProductDescription>
    </>
  )

  const renderOrderItems = (type) => {
    let newDatasource = []
    switch (type) {
      case OrderItemsTypes.Delivered:
        newDatasource = [...currentFormValues.orderItem.filter((x) => x.quantityDelivered > 0).map((x) => ({...x, quantity: x.quantityDelivered}))]
        break
      case OrderItemsTypes.Shipped:
        newDatasource = [...currentFormValues.orderItem.filter((x) => GetProductsShipped(x, currentFormValues.orderBox) && x.quantityDelivered < x.quantity)
          .map((x) => ({...x, quantity: GetProductsShipped(x, currentFormValues.orderBox) - x.quantityDelivered}))]
        break
      case OrderItemsTypes.Unshipped:
        newDatasource = [...currentFormValues.orderItem.filter((x) => GetProductsShipped(x, currentFormValues.orderBox) < x.quantity)
          .map((x) => ({...x, quantity: x.quantity - GetProductsShipped(x, currentFormValues.orderBox)}))]
        break
      default:
        break
    }

    if (newDatasource.length > 0) {
      return (
        <>
          <Row>
            <Col xs={12} lg={18}>
              <EditOrderTitle>
                <Translate id={`ORDER_DETAILS_${type}`} />
              </EditOrderTitle>
            </Col>
            {
              (type === OrderItemsTypes.Shipped && enabledSetShipped) && (
                <Col xs={12} lg={6}>
                  <BaseButton
                    onClick={setHasShipped}
                    type='secondary'
                    imagePath={SetHasShippedIcon}
                    disabled={selected.length <= 0}
                  >
                    <Translate id='SET_HAS_DELIVERED' />
                  </BaseButton>
                </Col>
              )
            }
          </Row>
          <Margin size={20} />
          <Row>
            <Col xs={24}>
              <BaseList
                grid={{
                  gutter: 30,
                  xs: 1,
                  sm: 2,
                  md: 4,
                  lg: 4,
                  xl: 6,
                  xxl: 6
                }}
                datasource={newDatasource}
                renderItem={(item) => renderOrderItemDetails(type, item)}
                pagination={{
                  render: false
                }}
                onClickRow={(enabledSetShipped && (type === OrderItemsTypes.Shipped && enabledSetShipped)) ? (item) => setSelectedItemsWrapper(item) : undefined}

              />
            </Col>
          </Row>
        </>
      )
    }
  }

  if (loading) {
    return (
      <BaseLoading />
    )
  }

  return (
    <>
      <Row gutter={[20, 20]} justify='start' align='bottom'>
        <Col xs={24}>
          <EditOrderStatus
            label={<Translate id='ORDER_STATUS' />}
            values={[
              {
                text: (
                  <ActiveTranslation
                    value={currentFormValues?.orderStatus?.orderStatusTranslation}
                    tag='name'
                  />
                ),
                background: GetStatusColor(
                  currentFormValues?.orderStatus?.orderStatusId
                ),
                border: GetStatusColor(currentFormValues?.orderStatus?.orderStatusId),
                color: 'white'
              }
            ]}
          />
        </Col>
      </Row>
      <Margin size={40} />
      <Row>
        <Col xs={24}>
          <EditOrderTitle>
            <Translate id='PAYMENT_DATA' />
          </EditOrderTitle>
        </Col>
      </Row>
      <Margin size={20} />
      <Row>
        <Col xs={4}>
          <EditOrderHeaderTitle
            label={<Translate id='PAYMENT_METHOD' />}
            value={
              paymentMethods.find(
                (x) => x.paymentMethodID == currentFormValues.paymentMethod
              )?.name || 'N/A'
            }
          />
        </Col>
        <Col xs={4}>
          <EditOrderHeaderTitle
            label={<Translate id='PAYMENT_AMOUNT' />}
            value={`${currentFormValues?.totalValue}€`}
          />
        </Col>
        {currentFormValues?.paymentTransactionId && (
          <Col xs={4}>
            <EditOrderHeaderTitle
              label={<Translate id='PAYMENT_ID' />}
              value={currentFormValues?.paymentTransactionId}
            />
          </Col>
        )}
        {currentFormValues?.datePaid && (
          <Col xs={4}>
            <EditOrderHeaderTitle
              label={<Translate id='DATE' />}
              value={moment(currentFormValues?.datePaid).format('DD/MM/YYYY')}
            />
          </Col>
        )}
      </Row>
      <Margin size={40} />
      <Row gutter={[20, 20]}>
        <Col xs={4}>
          <Address countries={countries} address={shippingAddress} />
        </Col>
        <Col xs={4}>
          <Address countries={countries} address={billingAddress} />
        </Col>
        <Col xs={4}>
          <EditOrderHeaderTitle
            margin
            label={<Translate id='NAME' />}
            value={currentFormValues?.deliveryContactDetail?.name || 'N/A'}
          />
        </Col>
        <Col xs={4}>
          <EditOrderHeaderTitle
            margin
            label={<Translate id='MOBILE_NUMBER' />}
            value={currentFormValues?.deliveryTelephone || currentFormValues?.deliveryContactDetail?.telephone || currentFormValues?.deliveryContactDetail?.mobile || 'N/A'}
          />
        </Col>
        <Col xs={4}>
          <EditOrderHeaderTitle
            margin
            label={<Translate id='EMAIL' />}
            value={currentFormValues?.deliveryContactDetail?.email || 'N/A'}
          />
        </Col>
      </Row>
      <Margin size={40} />
      {renderOrderItems(OrderItemsTypes.Delivered)}
      {renderOrderItems(OrderItemsTypes.Shipped)}
      {renderOrderItems(OrderItemsTypes.Unshipped)}
    </>
  )
}

FinalData.propTypes = {
  countries: PropTypes.array.isRequired
}

FinalData.defaultProps = {
}

export default withLocalize(connect((state) => ({
  currentFormValues: getFormValues('manage-order-b2b2c-b2c')(state)
}))(FinalData))
