import React, {Component} from 'react'

import {Row, Col} from 'antd'
import moment from 'moment'
import {Translate} from 'react-localize-redux'
import {connect} from 'react-redux'

import Download from '../../../../assets/icons/download_file.svg'
import {GetBackOrders} from '../../../../infra/requests/OrdersB2CRequests'
import BaseButton from '../../../../shared/components/buttons/BaseButton'
import DateRangeInput from '../../../../shared/components/inputs/DateRangeInput'
import SearchInput from '../../../../shared/components/inputs/SearchInput'
import SelectInput from '../../../../shared/components/inputs/SelectInput'
import TableOrderStatus from '../../../../shared/components/orderStatus/TableOrderStatus'
import BaseTable from '../../../../shared/components/table/BaseTable'
import GetObjectByID from '../../../../shared/logic/arrays/GetObjectByID'
import {ConstructQuery, ConstructSort} from '../../../../shared/logic/queries/EndpointQueries'
import {Margin} from '../../../../shared/styles/BasicStyles'
import CheckIfReturn from '../../helpers/CheckIfReturn'
import {BackOrderStatusList} from '../../helpers/OrderStatusFilters'

let timeout

class OnBackOrdersTab extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      rows: [],
      page: 1,
      pageSize: 10,
      total: 0,
      selectedOrders: [],
      filters: {search: ''},
      dateRange: undefined
    }
  }

  getColumns = () => {
    const {paymentMethods} = this.props

    return [
      {
        dataIndex: 'orderId',
        type: 'ordered',
        sorter: true,
        title: <Translate id='ORDER_NMR' />,
        render: (value, row) => (row.number && row.number != 'NULL') ? row.number : value
      },
      {
        dataIndex: 'date',
        type: 'ordered',
        sorter: true,
        title: <Translate id='DATE' />,
        render: (date) => moment(date).format('DD/MM/YYYY')
      },
      {
        dataIndex: 'deliveryCountry',
        render: (country) => country?.name,
        title: <Translate id='COUNTRY' />
      },
      {
        dataIndex: 'employee',
        title: <Translate id='CLIENT' />,
        render: (value) => value?.b2bclient?.name
      },
      {
        dataIndex: 'employee',
        title: <Translate id='EMPLOYEE' />,
        render: (value) =>
          value ? `${value?.firstName} ${value?.lastName}` : 'N/A'
      },
      {
        dataIndex: 'totalQuantity',
        title: <Translate id='QTY' />
      },
      {
        dataIndex: 'totalValue',
        title: <Translate id='AMOUNT' />
      },
      {
        dataIndex: 'currency',
        title: <Translate id='CURRENCY' />,
        render: (value) => value?.code
      },
      {
        dataIndex: 'paymentMethod',
        type: 'ordered',
        sorter: true,
        title: <Translate id='PAYMENT_METHOD' />,
        render: (value) =>
          GetObjectByID(paymentMethods, 'paymentMethodID', value)?.name
      },
      {
        dataIndex: 'orderStatus',
        type: 'ordered',
        sorter: true,
        title: <Translate id='ORDER_STATUS' />,
        render: (data) => <TableOrderStatus id={data?.orderStatusId} paid={data.paid} />
      }
    ]
  }

  componentDidMount = async () => {
    try {
      this.updateTable()
    } catch (error) {
      console.warn(error)
    }
  }

  updateTable = async () => {
    const {page, pageSize, filters, sort} = this.state
    this.setState({loading: true})

    const {data} = await GetBackOrders(
      true,
      page,
      pageSize,
      ConstructQuery(filters),
      ConstructSort(sort)
    )
    this.setState({
      rows: data?.items,
      total: data?.totalItems,
      loading: false
    })
  }

  onChangePagination = (page) => this.setState({page}, this.updateTable)

  onRowSelection = (selectedOrders) => this.setState({selectedOrders})

  onChangeSort = (sort) => this.setState({sort}, this.updateTable)

  setFilters = (values, time) => {
    this.setState({filters: values, page: 1}, () => {
      if (timeout) clearTimeout(timeout)
      timeout = setTimeout(this.updateTable, time)
    })
  }

  render() {
    const {
      loading,
      selectedOrders,
      rows,
      page,
      pageSize,
      total,
      dateRange,
      filters
    } = this.state
    const {orderStatus} = this.props

    return (
      <>
        <Row gutter={[24, 16]}>
          <Col xs={24} md={6} lg={6}>
            <BaseButton
              onClick={() => this.setState({loading: false})}
              type='secondary'
              imagePath={Download}
              auto
            >
              <Translate id='EXPORT' />
            </BaseButton>
          </Col>

          <Col xs={24} md={6} lg={6}>
            <SearchInput
              input={{
                value: filters.search,
                onChange: (value) =>
                  this.setFilters({...filters, search: value}, 1000),
                onSearch: () => this.setFilters({...filters}, 0)
              }}
            />
          </Col>
          <Col xs={24} md={6} lg={6}>
            <SelectInput
              input={{
                value: filters.orderStatusId,
                onChange: (value) => this.setFilters({...filters, orderStatusId: value}, 0)}}
              data={BackOrderStatusList(orderStatus)}
              placeholder={<Translate id='ORDER_STATUS' />}
              dataLabel='orderStatusTranslation'
              dataKey='orderStatusId'
            />
          </Col>
          <Col xs={24} md={6} lg={6}>
            <DateRangeInput
              input={{
                value: dateRange,
                onChange: (date) => {
                  this.setFilters(
                    {
                      ...filters,
                      DateFrom: date ? date[0].startOf('day') : undefined,
                      DateTo: date ? date[1].endOf('day') : undefined
                    },
                    0
                  )
                }
              }}
            />
          </Col>
        </Row>
        <Margin size={24} />
        <Row>
          <Col xs={24}>
            <BaseTable
              rowKey='orderId'
              datasource={rows}
              columns={this.getColumns()}
              sortCallback={this.onChangeSort}
              pagination={{
                render: true,
                page,
                pageSize,
                total,
                onChangePagination: this.onChangePagination
              }}
              rowSelection={{
                selected: selectedOrders,
                onRowSelection: this.onRowSelection
              }}
              linkAddress={(row) => {
                if (CheckIfReturn(row)) {
                  return `/returns/b2b2c/${row.orderId}`
                }
                return `/orders/b2b2c/${row.orderId}`
              }}
              loading={loading}
            />
          </Col>
        </Row>
      </>
    )
  }
}

OnBackOrdersTab.propTypes = {
}

const mapStateToProps = (state) => ({
  orderStatus: state.info.orderStatus,
  paymentMethods: state.info.paymentMethods
})

export default connect(mapStateToProps)(OnBackOrdersTab)
