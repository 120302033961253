import React from "react";

import { BarChartOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import moment from "moment";
import PropTypes from "prop-types";
import { Translate } from "react-localize-redux";
import {
  VictoryChart,
  VictoryTheme,
  VictoryBar,
  VictoryTooltip,
  VictoryGroup,
  VictoryLabel,
  VictoryAxis,
  VictoryContainer,
} from "victory";

import { StyledFlex } from "../../../shared/styles/BasicStyles";
import {
  EmptyDashboardMessage,
  StyledVictoryChartWrapper,
  ChartLoading,
} from "../DashboardStyles";
import LegendItem from "./LegendItem";

const calculateTickLegendValue = (value) => {
  if (value > 1000001) {
    const label = parseInt(value, 10).toString();
    return `${label.slice(0, -6)}M`;
  }

  if (value > 1001) {
    const label = parseInt(value, 10).toString();
    return `${label.slice(0, -3)}K`;
  }

  if (value % 1 === 0) {
    return parseInt(value, 10);
  }
  return parseFloat(value).toFixed(2);
};

const GroupBarComponent = ({ info, loading }) => {
  if (info.options.empty) {
    return (
      <EmptyDashboardMessage>
        <BarChartOutlined />
        <div>
          {" "}
          <Translate id="NO_AVAILABLE_INFO" />
        </div>
      </EmptyDashboardMessage>
    );
  }
  if (!info.options.x) {
    return (
      <EmptyDashboardMessage>
        <BarChartOutlined />
        <div>
          {" "}
          <Translate id="NO_XAXIS_DEFINITION" />
        </div>
      </EmptyDashboardMessage>
    );
  }
  if (!info.options.y || info.options.y.length === "") {
    return (
      <EmptyDashboardMessage>
        <BarChartOutlined />
        <div>
          <Translate id="NO_YAXIS_DEFINITION" />
        </div>
      </EmptyDashboardMessage>
    );
  }

  const shouldRotateText = (key, items) =>
    items.some((x) => x[key].length > 12) && items.length > 2;

  const setTickLabelsText = (key, items) => {
    if (shouldRotateText(key, items)) {
      return { tickLabels: { angle: 20 } };
    }
    return null;
  };

  return (
    <>
      <StyledFlex $direction="row" $justify="space-between" $top="12">
        <StyledFlex $direction="row">
          {info.options.y.map((label, index) => (
            <LegendItem
              key={label.key}
              color={label.color}
              name={label.name}
              left={index > 0 ? "10" : "0"}
            />
          ))}
        </StyledFlex>
      </StyledFlex>
      <StyledVictoryChartWrapper>
        {loading && (
          <ChartLoading>
            <Spin />
          </ChartLoading>
        )}

        <VictoryChart
          fixLabelOverlap={true}
          theme={VictoryTheme.material}
          width={550}
          domainPadding={0}
          minDomain={{ x: 0 }}
          maxDomain={{ x: 10 }}
          containerComponent={
            <VictoryContainer
              style={{
                pointerEvents: "none",
                userSelect: "auto",
                touchAction: "auto",
              }}
            />
          }
        >
          <VictoryAxis
            scale="time"
            tickValues={info.items}
            style={setTickLabelsText(info.options.x.key, info.items)}
            tickLabelComponent={
              <VictoryLabel
                {...(shouldRotateText(info.options.x.key, info.items) && {
                  textAnchor: "start",
                  dy: -6,
                  dx: -6,
                })}
                text={({ index }) => {
                  const value = info.items[index][info.options.x.key];
                  if (info.options.x.key == "x_ReferenceCode") {
                    return value;
                  }
                  if (moment(value, "MM/YYYY").isValid()) {
                    return moment(value, "MM/YYYY")
                      .format("MMM YYYY")
                      .split(" ");
                  }
                  // style doesn't affect the text max width, manually add the "..." on a string
                  return value.length > 10 ? `${value.slice(0, 10)}...` : value;
                }}
                style={[
                  { fill: "#231F20", fontSize: 14 },
                  {
                    fill: "#231F20",
                    opacity: "50%",
                    fontSize: 14,
                  },
                ]}
              />
            }
          />
          <VictoryAxis
            dependentAxis
            tickFormat={calculateTickLegendValue}
            tickLabelComponent={
              <VictoryLabel dx={6} style={{ fontSize: 14 }} />
            }
          />
          <VictoryGroup offset={-15}>
            {info.options.y.map((label) => (
              <VictoryBar
                key={label.key}
                style={{ data: { fill: label.color, width: 15 } }}
                cornerRadius={{ topLeft: 1, topRight: 1 }}
                x={info.options.x.key}
                y={label.key}
                barRatio={0.8}
                animate={{
                  duration: 2000,
                  onLoad: { duration: 1000 },
                }}
                labelComponent={<VictoryTooltip />}
                labels={({ datum }) =>
                  `${datum[info.options.x.key]} - ${datum[label.key]}`
                }
                data={info.items}
              />
            ))}
          </VictoryGroup>
        </VictoryChart>
      </StyledVictoryChartWrapper>
    </>
  );
};

GroupBarComponent.propTypes = {
  info: PropTypes.object.isRequired,
};

export default GroupBarComponent;
