import React from 'react'

import {SearchOutlined} from '@ant-design/icons'
import {PropTypes} from 'prop-types'

import {
  InputBox,
  SelectInputStyled,
  SelectArrow,
  SelectLoading,
  NotFoundContainer
} from '../../../../../shared/components/inputs/InputStyles'

const DeliverySelect = ({
  input,
  disabled,
  afterChange,
  placeholder,
  data,
  loading
}) => {
  const handleChange = (value) => {
    input.onChange(value)
    if (typeof afterChange === 'function') {
      setTimeout(() => afterChange(value || undefined), 100)
    }
  }

  const defineValue = (value) => {
    if (value) {
      const found = data.find((x) => x.value == value)
      return found?.name || undefined
    }
    return undefined
  }

  return (
    <InputBox>
      <SelectInputStyled
        $small
        $adjustable
        placeholder={placeholder}
        value={defineValue(input.value)}
        onChange={handleChange}
        allowClear={false}
        suffixIcon={
          loading ? <SelectLoading $small /> : <SelectArrow $small />
        }
        disabled={disabled}
        options={data}
        loading={loading}
        notFoundContent={
          <NotFoundContainer>
            <SearchOutlined />
            {placeholder}
          </NotFoundContainer>
        }
      />
    </InputBox>
  )
}

DeliverySelect.propTypes = {
  input: PropTypes.object,
  disabled: PropTypes.bool,
  afterChange: PropTypes.func
}

DeliverySelect.defaultProps = {
  input: {value: undefined, onChange: () => null},
  disabled: false,
  afterChange: undefined
}

export default DeliverySelect
