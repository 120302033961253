import React from 'react'

import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'

import ActiveIcon from '../../../assets/icons/status_active.svg'
import InactiveIcon from '../../../assets/icons/status_inactive.svg'
import { StatusText, StatusSpan, StatusImg } from './StatusStyles'

const StatusColumn = ({ value, onText, offText }) => {
  return (
    <StatusText active={value}>
      <StatusSpan>{value ? onText : offText}</StatusSpan>
      <StatusImg
        src={value ? ActiveIcon : InactiveIcon}
        alt={<Translate id='STATE' />}
      />
    </StatusText>
  )
}

StatusColumn.propTypes = {
  value: PropTypes.any,
  onText: PropTypes.object,
  offText: PropTypes.object
}

StatusColumn.defaultProps = {
  value: false,
  onText: <Translate id='ACTIVE' />,
  offText: <Translate id='INACTIVE' />
}

export default StatusColumn
