import client from '../config/AxiosConfig'

export const GetSupplierOrders = async (page, pageSize, filters) =>
  client.get(
    `/OrderSupplier/GetOrderSupplierSets?ItemsPerPage=${pageSize}&Page=${page}${filters}`
  )

export const UpdateSupplierOrder = async (id) =>
  client.put(`/OrderSupplier/UpdateOrderSupplier/${id}`)

export const UpdateOrderSupplierDate = async (id, data) =>
  client.put(`/OrderSupplier/UpdateOrderSupplierDate/${id}`, data)

export const EditMovement = async (movementId, data) =>
  client.put(`/Movements/EditMovement/${movementId}`, data)
