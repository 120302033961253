import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { TableTextColor } from '../../styles/_colors';
import { BodyText } from '../../styles/_texts';
import { BodyH3 } from '../../styles/BasicStyles';
import {
    InputBox,
    InputLabel,
    ProductPriceInputBox,
    ProductPriceInputStyled,
} from './InputStyles';

const DefaultValueInput = ({
    input,
    label,
    meta,
    type,
    placeholder,
    afterChange,
    min,
    max,
    step,
    disabled,
    additionalText,
    inputDescriptionText,
    initialValue, // New prop to accept initial value
}) => {
    const [value, setValue] = useState(initialValue || '');

    useEffect(() => {
        setValue(initialValue || '');
    }, [initialValue]);

    const handleChange = (event) => {
        const newValue = event.target.value.replace(/[^\d]/g, ''); // Allow only digits
        setValue(newValue);
        input.onChange(newValue); // Update form field value
        if (typeof afterChange === 'function') {
            afterChange(newValue); // Execute any additional change handlers
        }
    };

    const handleBlur = () => {
        const newValue = value === '' ? '' : parseInt(value, 10);
        setValue(newValue);
        input.onChange(newValue); // Update form field value
        if (typeof afterChange === 'function') {
            afterChange(newValue); // Execute any additional change handlers
        }
    };

    const hasError = meta.invalid && meta.submitFailed;

    return (
        <InputBox>
            {label && <InputLabel>{label}</InputLabel>}
            <ProductPriceInputBox disabled={disabled} $hasError={hasError}>
                <ProductPriceInputStyled
                    type={type}
                    placeholder={placeholder}
                    value={value}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    min={min}
                    max={max}
                    step={step}
                    disabled={disabled}
                    onKeyPress={(e) => e.key === 'e' && e.preventDefault()}
                />
                {inputDescriptionText && (
                    <BodyH3 $color={disabled ? 'white' : TableTextColor}>
                        {inputDescriptionText}
                    </BodyH3>
                )}
            </ProductPriceInputBox>
            {additionalText && <BodyH3 $size={BodyText}>{additionalText}</BodyH3>}
        </InputBox>
    );
};

DefaultValueInput.propTypes = {
    input: PropTypes.object,
    meta: PropTypes.object,
    label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    placeholder: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    type: PropTypes.string,
    afterChange: PropTypes.func,
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    disabled: PropTypes.bool,
    additionalText: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
    ]),
    inputDescriptionText: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
    ]),
    initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // PropType for initial value
};

DefaultValueInput.defaultProps = {
    input: { value: undefined, onChange: () => null },
    label: undefined,
    placeholder: undefined,
    meta: {},
    type: 'number',
    afterChange: undefined,
    min: 0,
    max: undefined,
    step: 1,
    disabled: false,
    additionalText: undefined,
    inputDescriptionText: undefined,
    initialValue: '', // Default initial value prop
};

export default DefaultValueInput;
