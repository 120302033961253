// Importing necessary dependencies and components from external libraries and modules
import React from "react";
import { BarChartOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import moment from "moment";
import PropTypes from "prop-types";
import { Translate } from "react-localize-redux";
import {
  VictoryChart,
  VictoryTheme,
  VictoryBar,
  VictoryTooltip,
  VictoryGroup,
  VictoryLabel,
  VictoryAxis,
  VictoryZoomContainer,
  VictoryContainer,
} from "victory";

// Importing styled components and custom components
import { StyledFlex } from "../../../shared/styles/BasicStyles";
import {
  EmptyDashboardMessage,
  StyledVictoryChartWrapper,
  ChartLoading,
} from "../DashboardStyles";
import LegendItem from "./LegendItem";

// Function to calculate tick label values based on maximum value
const calculateTickLegendValue = (t, maxima) => {
  const value = t * maxima;
  if (value > 1000001) {
    const label = parseInt(value, 10).toString();
    return `${label.slice(0, -6)}M`;
  }

  if (value > 1001) {
    const label = parseInt(value, 10).toString();
    return `${label.slice(0, -3)}K`;
  }

  if (value % 1 === 0) {
    return parseInt(value, 10);
  }
  return parseFloat(value).toFixed(2);
};

// Functional component for rendering a double axis graphic
const DoubleAxisGraphic = ({ info, loading }) => {
  // Handling cases where there is no data or incomplete options
  if (info.options.empty) {
    return (
      <EmptyDashboardMessage>
        <BarChartOutlined />
        <div>
          <Translate id="NO_AVAILABLE_INFO" />
        </div>
      </EmptyDashboardMessage>
    );
  }
  if (!info.options.x) {
    return (
      <EmptyDashboardMessage>
        <BarChartOutlined />
        <div>
          <Translate id="NO_XAXIS_DEFINITION" />
        </div>
      </EmptyDashboardMessage>
    );
  }
  if (!info.options.y || info.options.y.length !== 2) {
    return (
      <EmptyDashboardMessage>
        <BarChartOutlined />
        <div>
          <Translate id="NO_DOUBLE_YAXIS_DEFINITION" />
        </div>
      </EmptyDashboardMessage>
    );
  }

  // Function to determine if text should be rotated on the axis
  const shouldRotateText = (key, items) =>
    items.some((x) => x[key].length > 12) && items.length > 6;

  // Function to set tick labels text rotation
  const setTickLabelsText = (key, items) => {
    if (shouldRotateText(key, items)) {
      return { tickLabels: { angle: 20 } };
    }
    return null;
  };

  // Finding maximum values for each y-axis
  const maxima = info.options.y.map((option) =>
    Math.max(1, ...info.items.map((d) => d[option.key]))
  );

  return (
    <>
      {/* Rendering legend items */}
      <StyledFlex $direction="row" $justify="space-between" $top="12">
        <StyledFlex $direction="row">
          {info.options.y.map((label, index) => (
            <LegendItem
              key={label.key}
              color={label.color}
              name={label.name}
              left={index > 0 ? "10" : "0"}
            />
          ))}
        </StyledFlex>
      </StyledFlex>

      {/* Rendering the Victory chart */}
      <StyledVictoryChartWrapper>
        {loading && (
          <ChartLoading>
            <Spin />
          </ChartLoading>
        )}
        <VictoryChart
          theme={VictoryTheme.material}
          width={550}
          domainPadding={0}
          minDomain={{ x: 0 }}
          maxDomain={{ x: 10 }}
          containerComponent={
            <VictoryContainer
              style={{
                pointerEvents: "none",
                userSelect: "auto",
                touchAction: "auto",
              }}
            />
          }
        >
          {/* X-axis */}

          <VictoryAxis
            fixLabelOverlap={true}
            scale="time"
            tickValues={info.items.map((item) => item[info.options.x.key])} // Set x-axis ticks based on data
            style={setTickLabelsText(info.options.x.key, info.items)}
            tickLabelComponent={
              <VictoryLabel
                {...(shouldRotateText(info.options.x.key, info.items) && {
                  textAnchor: "start",
                  dy: -6,
                  dx: -6,
                })}
                text={({ index }) => {
                  const value = info.items[index][info.options.x.key];
                  if (info.options.x.key === "x_ReferenceCode") {
                    return value;
                  }
                  if (moment(value, "MM/YYYY").isValid()) {
                    return moment(value, "MM/YYYY")
                      .format("MMM YYYY")
                      .split(" ");
                  }
                  return value.length > 10 ? `${value.slice(0, 10)}...` : value;
                }}
                style={[
                  { fill: "#231F20", fontSize: 14 },
                  {
                    fill: "#231F20",
                    opacity: "50%",
                    fontSize: 14,
                  },
                ]}
              />
            }
          />

          {/* Y-axis 1 */}
          <VictoryAxis
            dependentAxis
            tickValues={[0.25, 0.5, 0.75, 1, 1.25]}
            tickFormat={(t) => calculateTickLegendValue(t, maxima[0])}
            tickLabelComponent={
              <VictoryLabel dx={6} style={{ fontSize: 14 }} />
            }
          />

          {/* Y-axis 2 */}
          <VictoryAxis
            dependentAxis
            offsetX={-450}
            style={{
              ticks: { padding: -25 },
              tickLabels: { textAnchor: "start", fontSize: 14 },
            }}
            tickValues={[0.25, 0.5, 0.75, 1, 1.25]}
            tickFormat={(t) => calculateTickLegendValue(t, maxima[1])}
            tickLabelComponent={
              <VictoryLabel dx={10} style={{ fontSize: 14 }} />
            }
          />

          {/* Grouping bars and rendering */}
          <VictoryGroup offset={-15}>
            {info.options.y.map((label, index) => (
              <VictoryBar
                key={label.key}
                style={{ data: { fill: label.color, width: 15 } }}
                cornerRadius={{ topLeft: 1, topRight: 1 }}
                x={info.options.x.key}
                y={(datum) => datum[label.key] / maxima[index]}
                barRatio={0.8}
                animate={{
                  duration: 2000,
                  onLoad: { duration: 1000 },
                }}
                labelComponent={<VictoryTooltip />}
                labels={({ datum }) =>
                  `${datum[info.options.x.key]} - ${datum[label.key]}`
                }
                data={info.items}
              />
            ))}
          </VictoryGroup>
        </VictoryChart>
      </StyledVictoryChartWrapper>
    </>
  );
};

// Prop types validation for the DoubleAxisGraphic component
DoubleAxisGraphic.propTypes = {
  info: PropTypes.object.isRequired,
  loading: PropTypes.bool,
};

// Default props for the DoubleAxisGraphic component
DoubleAxisGraphic.defaultProps = {
  loading: false,
};

// Exporting the DoubleAxisGraphic component
export default DoubleAxisGraphic;
