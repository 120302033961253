import React, {Component} from 'react'

import {Row, Col} from 'antd'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {
  GetInfoOrdersB2B2C
} from '../../../infra/requests/OrdersB2B2CRequests'
import Highlight from '../../../shared/components/highlight/Highlight'
import {Margin, StyledTabPane, StyledTabs, StyledTabTitle, StyledTabTitleContainer} from '../../../shared/styles/BasicStyles'
import HistoricalOrdersTab from './tabs/HistoricalOrdersTab'
import NewOrdersTab from './tabs/NewOrdersTab'
import OnBackOrdersTab from './tabs/OnBackOrdersTab'

class OrdersB2B2C extends Component {
  constructor(props) {
    super(props)
    this.state = {
      infoOrders: {}
    }
  }

  componentDidMount = async () => {
    try {
      const {data} = await GetInfoOrdersB2B2C()
      this.setState({infoOrders: data})
    } catch (error) {
      console.warn(error)
    }
  }

  render() {
    const {
      infoOrders
    } = this.state
    const {router} = this.props

    return (
      <>
        {/* <Row gutter={[24, 16]}>
          <Col>
            <BaseButton
              onClick={() => router.history.push('/orders/b2b2c/new')}
              imagePath={AddWhite}
            >
              <Translate id='NEW_ORDER' />
            </BaseButton>
          </Col>
        </Row>
        <Margin size={24} /> */}
        <Row gutter={[24, 16]}>
          <Col xs={12} md={6}>
            <Highlight
              title='Nº Active Orders'
              text={infoOrders?.activeOrders}
            />
          </Col>
          <Col xs={12} md={6}>
            <Highlight
              title='Nº Back Orders'
              text={infoOrders?.backOrders}
            />
          </Col>
          <Col xs={12} md={6}>
            <Highlight
              title='Waiting Payment'
              text={infoOrders?.waitingPayment}
            />
          </Col>
          <Col xs={12} md={6}>
            <Highlight title='Returns' text={infoOrders?.returns} />
          </Col>
          <Col xs={12} md={6}>
            <Highlight
              title='Conversion Rate'
              text={infoOrders?.conversionRate}
            />
          </Col>
          <Col xs={12} md={6}>
            <Highlight
              title='Abandoned Cart'
              text={infoOrders?.abandonedCart}
            />
          </Col>
          <Col xs={12} md={6}>
            <Highlight title='Daily Sales' text={infoOrders?.dailySales} />
          </Col>
          <Col xs={12} md={6}>
            <Highlight
              title='Monthly Sales'
              text={infoOrders?.monthlySales}
            />
          </Col>
        </Row>
        <Margin size={30} />
        <StyledTabs destroyInactiveTabPane>
          <StyledTabPane
            tab={
              <StyledTabTitleContainer>
                <StyledTabTitle smaller>New Orders</StyledTabTitle>
              </StyledTabTitleContainer>
            }
            key={1}
          >
            <NewOrdersTab router={router} />
          </StyledTabPane>
          <StyledTabPane
            tab={
              <StyledTabTitleContainer>
                <StyledTabTitle smaller>On Back Orders</StyledTabTitle>
              </StyledTabTitleContainer>
            }
            key={2}
          >
            <OnBackOrdersTab router={router} />
          </StyledTabPane>
          <StyledTabPane
            tab={
              <StyledTabTitleContainer>
                <StyledTabTitle smaller>Historical Orders</StyledTabTitle>
              </StyledTabTitleContainer>
            }
            key={3}
          >
            <HistoricalOrdersTab router={router} />
          </StyledTabPane>
        </StyledTabs>
      </>
    )
  }
}

OrdersB2B2C.propTypes = {
  router: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  orderStatus: state.info.orderStatus,
  paymentMethods: state.info.paymentMethods
})

export default connect(mapStateToProps)(OrdersB2B2C)
