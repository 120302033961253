import React from 'react'

import PropTypes from 'prop-types'

import {Margin} from '../../../../shared/styles/BasicStyles'
import DepartureHeader from './sections/DepartureHeader'
import GenerateBoxesTable from './sections/GenerateBoxesTable'
import KitsDetailList from './sections/KitsDetailList'
import KitsTable from './sections/KitsTable'

const SecondStep = ({formValues, workgroups, disabled, products, setProducts, boxes, setBoxes}) => (
  <>
    <DepartureHeader formValues={formValues} />
    {(!formValues.movementStatusId || formValues.movementStatusId < 2) && (
      <GenerateBoxesTable
        disabled={disabled}
        products={products}
        setProducts={setProducts}
        boxes={boxes}
        setBoxes={setBoxes}
        workgroups={workgroups}
      />
    ) }
    <Margin size='50' />
    {(!formValues.movementStatusId || formValues.movementStatusId < 2) ? (
      <KitsTable
        products={products}
        setProducts={setProducts}
        boxes={boxes}
        setBoxes={setBoxes}
        clientId={formValues?.clientId}
        workgroups={workgroups}
      />
    ) : (
      <KitsDetailList
        boxes={boxes}
        setBoxes={setBoxes}
      />
    )}
  </>
)
SecondStep.propTypes = {
  formValues: PropTypes.object
}

SecondStep.defaultProps = {
  formValues: {}
}

export default SecondStep
