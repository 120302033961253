import React, {useEffect, useState} from 'react'

import {Row, Col} from 'antd'
import moment from 'moment'
import {Translate} from 'react-localize-redux'
import {useParams} from 'react-router-dom'

import {GetWarehouseTransferDetail, GetShipmentDetail} from '../../../../infra/requests/DepartureRequests'
import BackButton from '../../../../shared/components/buttons/BackButton'
import PropertyDescription from '../../../../shared/components/description/PropertyDescription'
import BaseLoading from '../../../../shared/components/loading/BaseLoading'
import BaseTable from '../../../../shared/components/table/BaseTable'
import ActiveTranslation from '../../../../shared/logic/translations/ActiveTranslation'
import {
  Margin,
  PageForm,
  PageTitle,
  TitleH3
} from '../../../../shared/styles/BasicStyles'
import ShippingDetail from '../components/shipping/ShippingDetail'
import TransferDetail from './TransferDetail'

const TransferDetailPage = ({router}) => {
  const [loading, setLoading] = useState(true)
  const [details, setDetails] = useState()
  const [shipment, setShipment] = useState()
  const {id} = useParams()

  useEffect(() => {
    const init = async () => {
      const {data, success} = await GetWarehouseTransferDetail(id)
      if (!success) return router.history.push('/logistics#depart')
      setDetails(data)

      if (data?.shipmentId) {
        const {data: shipData, success: shipSuccess} = await GetShipmentDetail(data.shipmentId)
        if (shipSuccess) setShipment(shipData)
      }
      setLoading(false)
    }
    init()
  }, [id])

  const productColumns = [
    {
      dataIndex: 'referenceCode',
      title: <Translate id='REFERENCE' />
    },
    {
      dataIndex: 'barcode',
      title: <Translate id='EAN_CODE' />
    },
    {
      dataIndex: 'productNameTranslations',
      title: <Translate id='DESCRIPTION' />,
      render: (value) => <ActiveTranslation value={value} tag='name' />
    },
    {
      dataIndex: 'colorTranslations',
      title: <Translate id='COLOR' />,
      render: (value) => <ActiveTranslation value={value} tag='name' />
    },
    {
      dataIndex: 'sizeName',
      title: <Translate id='SIZE' />
    },
    {
      dataIndex: 'productWidthTranslations',
      title: <Translate id='WIDTH' />,
      render: (value) => <ActiveTranslation value={value} tag='name' />
    },
    {
      dataIndex: 'quantity',
      title: <Translate id='QUANTITY' />
    }
  ]

  if (loading) return <BaseLoading margin='200' />

  return (
    <PageForm>
      <Row gutter={[0, 50]}>
        <Col xs={24}>
          <BackButton
            label='Back to logistics'
            onClick={() => router.history.push('/logistics#depart')}
          />
        </Col>
        <Col xs={24}>
          <PageTitle>WH Transfer #{id}</PageTitle>
        </Col>
      </Row>
      <Margin size={50} />
      <Row gutter={[40, 20]}>
        <Col xs={4}>
          <PropertyDescription
            label='Client'
            value={details?.b2bclientName || 'N/A'}
          />
        </Col>
        <Col xs={4}>
          <PropertyDescription
            label='Current warehouse'
            value={details?.warehouseName || 'N/A'}
          />
        </Col>
        <Col xs={4}>
          <PropertyDescription
            label='Departure Date'
            value={details?.date ? moment(details?.date).format('DD-MM-YYYY') : 'N/A'}
          />
        </Col>
        <Col xs={4}>
          <PropertyDescription
            label='Status'
            value={<ActiveTranslation value={details?.movementStatusTranslations} tag='name' />}
          />
        </Col>
        <Col xs={24}>
          <TitleH3>Products</TitleH3>
          <BaseTable
            rowKey='barcode'
            columns={productColumns}
            datasource={details?.productCombinationWarehouseMovements || []}
            pagination={{render: false}}
          />
        </Col>
        {shipment && (
          <Col xs={24}>
            <TitleH3>Shipment #{details?.shipmentId}</TitleH3>
            <ShippingDetail delivery={shipment} />
          </Col>
        )}
        {details?.warehouseTransferIds?.length > 0 && (
          <Col xs={24}>
            <TitleH3>Wharehouse Transfers</TitleH3>
            <Row>
              {details.warehouseTransferIds.map((transferId) => (
                <Col xs={24} key={transferId}>
                  <TransferDetail id={transferId} />
                </Col>
              ))}
            </Row>
          </Col>
        )}
      </Row>
    </PageForm>
  )
}

export default TransferDetailPage
