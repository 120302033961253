import React, {useState, useEffect} from 'react'

import {DropboxOutlined
} from '@ant-design/icons'
import {Row, Col} from 'antd'
import styled from 'styled-components'

import {ReactComponent as DropdownIcon} from '../../../../../assets/icons/dropdown_blue.svg'
import SearchInput from '../../../../../shared/components/inputs/SearchInput'
import TableBasePagination from '../../../../../shared/components/table/components/TableBasePagination'
import {
  TableRow,
  ListCol,
  TableTitle,
  TableText,
  FooterRow
} from '../../../../../shared/components/table/CustomTableStyles'
import {TitleH3} from '../../../../../shared/styles/BasicStyles'
import ItemsInBox from '../../components/boxes/ItemsInBox'

const ToggleDropdown = styled(DropdownIcon)`
  display: inline-block;
  transform: ${({open}) => (open ? 'rotate(-180deg)' : 'rotate(0)')};
  transition: all 0.2s ease;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 5px;
`

const EmptyTable = styled.div`
  display: flex;
  width: 100%;
  margin-top: 3px;
  background: white;
  padding: 50px 30px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 0.6;
  font-size: 18px;
  border-radius: 4px;

  .anticon {
    font-size: 40px;
    margin-bottom: 10px;
  }
`

const Flex = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const SearchSize = styled.div`
width: 350px;
`

const CalculateBoxQuantity = (box) => {
  let total = 0
  if (box && box.items) {
    box.items.forEach((item) => {
      total += item.quantity
    })
  }
  return total
}

const SearchOnList = (list, search) => {
  if (search && search !== '') {
    return list.filter((box) => {
      if (box.packageId) {
        if (box.packageId.toUpperCase().includes(search.toUpperCase())) return true
      }
      if (!Number.isNaN(search)) {
        const total = CalculateBoxQuantity(box)
        if (total == search) return true
      }

      if (box.employeeName) {
        if (box.employeeName.toUpperCase().includes(search.toUpperCase())) return true
      }
      return false
    })
  }
  return list
}

const KitsDetailList = ({boxes, setBoxes}) => {
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState()
  const [manual, setManual] = useState(boxes)
  const [total, setTotal] = useState(boxes.length)
  const limit = 30

  useEffect(() => {
    const list = SearchOnList(boxes, search)
    setTotal(list.length)
    setManual(list.slice((page - 1) * limit, page * limit))
    if (limit * (page - 1) > list.length) setPage(1)
  }, [boxes, search, page, limit])

  const toggleBox = (realIndex) => {
    setBoxes((old) => {
      const result = [...old]
      result[realIndex].open = !result[realIndex].open
      return result
    })
  }

  if (!boxes.length) return null

  return (
    <Row gutter={[16, 40]}>
      <Col xs={24}>
        <TitleH3 $op>Kits and Boxes</TitleH3>
      </Col>
      <Col xs={24}>
        <Flex>
          <SearchSize>
            <SearchInput
              input={{value: search, onChange: setSearch}}
            />
          </SearchSize>
        </Flex>
      </Col>
      <Col xs={24}>
        <TableRow $header>
          <ListCol xs={4}>
            <TableTitle first text='Package ID' />
          </ListCol>
          <ListCol xs={2}>
            <TableTitle text='Type' />
          </ListCol>
          <ListCol xs={1}>
            <TableTitle text='Items' />
          </ListCol>
          <ListCol xs={5}>
            <TableTitle text='Employee' />
          </ListCol>
          <ListCol xs={5}>
            <TableTitle text='Delivery Address' />
          </ListCol>
          <ListCol xs={5}>
            <TableTitle text='Final Address' />
          </ListCol>
          <ListCol xs={2} />
        </TableRow>

        {manual.length === 0 && (
          <EmptyTable>
            <DropboxOutlined />
            There are no boxes for this search
          </EmptyTable>
        )}

        {manual.map((box) => (
          <TableRow key={box.realIndex}>
            <ListCol xs={4}>
              <TableText $first $align='left'>{box.packageId}</TableText>
            </ListCol>
            <ListCol xs={2}>
              <TableText $align='left'>
                {box.isKit ? 'Kit' : 'Box'}
              </TableText>
            </ListCol>
            <ListCol xs={1}>
              <TableText>{CalculateBoxQuantity(box)}</TableText>
            </ListCol>
            <ListCol xs={5}>
              <TableText $align='left'>
                {box.isKit ? (
                  box.employeeName
                ) : '-'}
              </TableText>
            </ListCol>
            <ListCol xs={6}>
              <TableText $align='left'>
                {`${box.deliveryAddressLine1 || ''} ${box.deliveryAddressLine2 || ''} ${
                  box.deliveryAddressLine3 || ''
                } ${box.deliveryAddressCity || ''} ${box.deliveryAddressZipcode || ''}`}
              </TableText>
            </ListCol>
            <ListCol xs={5}>
              <TableText $align='left'>
                {box.isKit ? (
                  `${box.finalAddressLine1 || ''} ${box.finalAddressLine2 || ''} ${
                    box.finalAddressLine3 || ''
                  } ${box.finalAddressCity || ''} ${box.finalAddressZipcode || ''}`
                ) : '-'}
              </TableText>
            </ListCol>
            <ListCol xs={1}>
              <TableText $first>
                <ToggleDropdown
                  open={box.open}
                  onClick={() => toggleBox(box.realIndex)}
                />
              </TableText>
            </ListCol>
            <ItemsInBox box={box} />
          </TableRow>
        ))}
        <FooterRow gutter={[16, 10]}>
          <ListCol xs={24}>
            <TableBasePagination
              small
              page={page}
              pageSize={limit}
              total={total}
              onChangePagination={setPage}
            />
          </ListCol>
        </FooterRow>
      </Col>
    </Row>
  )
}

export default KitsDetailList
