import styled from 'styled-components'

import {StyledButton} from '../../../shared/components/buttons/ButtonsStyles'
import {
  TableTextColor,
  PrimaryButtonColor,
  BorderColor,
  PrimaryColor
} from '../../../shared/styles/_colors'
import {
  BookFontWeight,
  LabelText,
  SemiBoldFontWeight,
  BodyText,
  BoldFontWeight,
  TabsText
} from '../../../shared/styles/_texts'

export const CollapsableContainer = styled.div`
  margin-right: ${({$last}) => ($last ? 0 : 25)}px;
  margin-left: ${({$left}) => ($left ? 25 : 0)}px;
`

export const CollapsableName = styled.h5`
  color: ${TableTextColor};
  font-size: ${LabelText};
  font-weight: ${SemiBoldFontWeight};
  line-height: 18px;
`
export const CollapsableDescription = styled.div`
  color: ${TableTextColor};
  font-size: ${BodyText};
  font-weight: ${BookFontWeight};
  line-height: 18px;
`

export const POBaseTitle = styled.h4`
  color: ${TableTextColor};
  font-size: ${TabsText};
  font-weight: ${BoldFontWeight};
  margin-top: 40px;
  position: relative;
`

export const POOrderNumber = styled.span`
  color: ${PrimaryButtonColor};
  margin-left: 5px;
`

export const POBaseContainer = styled.div`
  padding: 10px 0px;
  border-top: 1px solid ${BorderColor};
  border-bottom: ${({$noBorderBottom}) =>
    $noBorderBottom ? 'none' : `1px solid ${BorderColor}`};
  margin-bottom: ${({$jointly}) => ($jointly ? '-1px' : 0)};
`
export const POHeaderCollapsable = styled.div`
  display: inline-block;
  width: 100%;
  cursor: pointer;
  position: relative;
`

export const POLeftContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: ${({$left}) => ($left ? 'start' : 'space-between')};
  align-items: center;
`

export const POTableContainer = styled.div`
  transform: ${({open}) => (open ? 'scale(1,1)' : 'scale(1,0)')};
  max-height: ${({open}) => (open ? 'auto' : '0px')};
  transition: all 0.1s ease-in-out;
  transform-origin: 0 0;
  margin-top: ${({open}) => (open ? '10px' : '0px')};
`

export const LocationIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const LocationPin = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5px;
  padding-left: 10px;
  height: 10px;
`

export const POTableFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  background: white;
  border: 1px solid ${BorderColor};
  border-radius: 4px;
  padding: 10px;
`

export const POTotalSection = styled.div`
  display: inline-block;
  padding-right: ${({$last}) => ($last ? 0 : 30)}px;
  position: relative;
`
export const FooterLine = styled.div`
  font-size: 14px;
  font-weight: ${({bold}) => (bold ? 'bold' : 'regular')};
  color: ${TableTextColor};
  text-align: right;
  span {
    color: ${PrimaryButtonColor};
  }
`

export const ReviewToggle = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`

export const ValidationSection = styled.div`
  margin-top: 15px;
  display: inline-block;
  width: 100%;
  text-align: right;
`

export const ValidationMessage = styled.div`
  color: #f01700;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  margin-top: 10px;
`

export const WarningTooltip = styled.div`
  position: absolute;
  top: 0;
  right: 8px;
  width: 20px;
  height: 20px;
  background: red;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`

export const SupplierToggle = styled.div`
  position: absolute;
  top: calc(50% - 12px);
  right: 0;
`

export const TableLabel = styled.div`
  font-size: 14px;
  line-height: 17px;
  text-align: right;
`

export const ExportButtons = styled.div`
  text-align: right;

  ${StyledButton} {
    margin-left: 15px;
  }
`

export const EmptyContainer = styled.div`
  margin-top: 30px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
export const EmptyMessage = styled.div``

export const Message = styled.div`
  opacity: 0.5;
  color: #231f20;
  font-size: 18px;
  line-height: 21px;
  display: inline-block;
`

export const MessageTrigger = styled.div`
  display: inline-block;
  opacity: 1;
  color: ${PrimaryColor};
  font-size: 18px;
  line-height: 21px;
  margin: 0 3px;
  cursor: pointer;
`

export const SecondaryMessageTrigger = styled.div`
  display: inline-block;
  opacity: 1;
  color: ${TableTextColor};
  font-size: 13px;
  font-weight: ${BoldFontWeight};
  margin: 0px 25px 0px 0px;
  cursor: pointer;
`

export const EmptyIcon = styled.img`
  width: 180px;
  margin-top: 30px;
`

export const SOTotalSection = styled.div`
  text-align: right;
  padding: 5px 15px;
  display: flex;
  justify-content:flex-end;
`
