import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom'; // Import useHistory hook
import { Translate } from 'react-localize-redux';
import EditIcon from '../../../assets/icons/eye.svg';
import { LineContent, EditButton } from '../../../pages/logistics/LogisticStyles';
import ActiveTranslation from '../../logic/translations/ActiveTranslation';

const ProductLink = ({ product }) => {
  const history = useHistory(); // Get history object from useHistory hook

  const handleProductClick = () => {
    if (product.productId) {
      history.push(`/products/${product.productId}`); // Redirect to product details page
    }
  };

  return (
    <LineContent>
      <ActiveTranslation
        value={product?.productTranslation || product.description}
        tag='name'
      />
      <EditButton
        src={EditIcon}
        alt={<Translate id='EDIT' />}
        onClick={handleProductClick} // Call handleProductClick function on button click
      />
    </LineContent>
  );
};

ProductLink.propTypes = {
  product: PropTypes.object.isRequired // Ensure product prop is required and is an object
};

export default ProductLink;
