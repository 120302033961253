import React from 'react'

import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'

import SwitchInput from '../../../shared/components/inputs/SwitchInput'
import CollapsableHeaderTitle from '../components/CollapsableHeaderTitle'
import {SupplierToggle} from '../components/POStyles'

const CollapsableHeader = ({SO, onSave, profit}) => (
  <>
    <CollapsableHeaderTitle
      max={300}
      name={<Translate id='SUPPLIER' />}
      description={SO?.supplier?.name}
    />
    <CollapsableHeaderTitle
      name={<Translate id='PROFIT_VARIATION' />}
      description={`${profit.variation}%`}
    />
    <CollapsableHeaderTitle
      name={<Translate id='PROFIT_AMOUNT' />}
      description={`${profit.amount}€`}
    />
    <SupplierToggle onClick={(e) => e.stopPropagation()}>
      <SwitchInput
        input={{
          value: SO.consolidationReviewed,
          onChange: onSave
        }}
        checkedText={<Translate id='REVIEWED' />}
        uncheckedText={<Translate id='REVIEWED' />}
      />
    </SupplierToggle>
  </>
)

CollapsableHeader.propTypes = {
  SO: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  profit: PropTypes.object.isRequired
}

CollapsableHeader.defaultProps = {}

export default CollapsableHeader
