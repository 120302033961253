import React, { useEffect, useState } from "react";

import { Row, Col } from "antd";
import PropTypes from "prop-types";
import { Translate } from "react-localize-redux";
import { FieldArray } from "redux-form";

import ProductLink from "../../../shared/components/links/ProductLink";
import StatusColumn from "../../../shared/components/Status/StatusColumn";
import BaseTable from "../../../shared/components/table/BaseTable";
import ActiveTranslation from "../../../shared/logic/translations/ActiveTranslation";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { EmployeeViewExtraCredits } from "../../../infra/requests/ClientRequests";

const Columns = [
  {
    dataIndex: "",
    type: "text",
    title: <Translate id="PRODUCT" />,
    render: (value) => <ProductLink product={value} />,
  },

  {
    type: "text",
    title: <Translate id="CREDITS" />,
    render: (value) => (
      <div>
        {value.creditsTotal - value.creditsUsed}/{value.creditsTotal}
      </div>
    ),
  },
  {
    dataIndex: "period",
    type: "text",
    title: <Translate id="PERIOD" />,
  },
  {
    dataIndex: "extraCredits",
    type: "text",
    title: <Translate id="EXTRA_CREDITS" />,
    render: (value) => (
      //data for extracredits
      <>{value}</>
    ),
  },
  {
    dataIndex: "fitted",
    type: "text",
    title: <Translate id="FITTED" />,
    render: (value) => (
      <StatusColumn
        value={value}
        onText={<Translate id="YES" />}
        offText={<Translate id="NO" />}
      />
    ),
  },

  {
    dataIndex: "fittedSize",
    type: "text",
    title: <Translate id="SIZE" />,
    render: (value) => value?.nameEu,
  },
  {
    dataIndex: "fittedProductWidth",
    type: "text",
    title: <Translate id="WIDTH" />,
    render: (value) => (
      <ActiveTranslation value={value?.productWidthTranslation} tag="name" />
    ),
  },
];

const DotationTable = ({ fields }) => {
  const dotation = fields.getAll() || [];
  const { client, id } = useParams();
  const [additionalData, setAdditionalData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await EmployeeViewExtraCredits(id);
        if (response.success) {
          setAdditionalData(response.data.items || []);
        } else {
          console.error("Error fetching additional data:", response.error);
        }
      } catch (error) {
        console.error("Error fetching additional data", error);
      }
    };

    fetchData();
  }, [id]);

  const updateRowsWithAdditionalData = () => {
    return dotation.map((row) => {
      const additionalInfo = additionalData.find(
        (item) => item.productId === row.productId
      );
      return {
        ...row,
        extraCredits: additionalInfo ? additionalInfo.creditsExtra : "N/A",
      };
    });
  };

  const updatedDotation = updateRowsWithAdditionalData();
  return (
    <Row gutter={[24]}>
      <Col xs={24}>
        <BaseTable
          datasource={updatedDotation}
          columns={Columns}
          pagination={{ render: false }}
          rowKey="productId"
        />
      </Col>
    </Row>
  );
};

DotationTable.propTypes = {
  fields: PropTypes.object.isRequired,
};

const Dotation = ({ router }) => (
  <FieldArray
    name="employeeDotationSection.dotation"
    component={DotationTable}
    router={router}
  />
);

export default Dotation;
