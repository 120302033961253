import React, {useState} from 'react'

import {Row, Col} from 'antd'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'
import {
  reduxForm,
  getFormValues,
  FieldArray,
  change
} from 'redux-form'

import {GetInventoryList, PutInventoryCount} from '../../../infra/requests/LogisticsRequests'
import AlertService from '../../../shared/components/alert/AlertService'
import BackButton from '../../../shared/components/buttons/BackButton'
import BaseButton from '../../../shared/components/buttons/BaseButton'
import SearchInput from '../../../shared/components/inputs/SearchInput'
import {ConstructQuery} from '../../../shared/logic/queries/EndpointQueries'
import {
  Margin,
  PageForm,
  PageTitle
} from '../../../shared/styles/BasicStyles'
import InventoryCountTable from './InventoryCountTable'
import SelectInputInventoryCount from './SelectInputInventoryCount'

let timeout

const InventoryCount = ({
  handleSubmit,
  dispatch,
  translate,
  router,
  formValues
}) => {
  const [loading, setLoading] = useState(false)
  const [saving, setSaving] = useState(false)
  const [filters, setFilters] = useState({
    warehouse: undefined,
    search: undefined
  })
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const pageSize = 10

  const updateTable = async (p, f) => {
    setLoading(true)
    const {data} = await GetInventoryList(p, pageSize, ConstructQuery(f))
    dispatch(change('inventory_form', 'items', data.items || []))
    setLoading(false)
    setTotal(data.totalItems || 0)
  }

  const onSubmit = async (values) => {
    try {
      setSaving(true)
      if (values.items && values.items.length) {
        const payload = values.items
          .filter((item) => item.countedStock)
          .map((item) => ({
            ProductId: item.productId,
            ColorId: item.colorId,
            SizeId: item.sizeId,
            ProductWidthId: item.productWidthId,
            WarehouseId: item.warehouseId,
            B2bclientId: item.stockOwnerId,
            Units: item.countedStock
          }))

        const {success} = await PutInventoryCount(payload)
        if (success) {
          AlertService.success(
            translate('INVENTORY_COUNT_SAVED'),
            translate('CHANGES_SAVED_SUCCESSFULLY')
          )
        }
      }
      updateTable(1, filters)
      setSaving(false)
    } catch (error) {
      console.warn(error)
    }
  }

  const onChangePagination = (newPage) => {
    setPage(newPage)
    updateTable(newPage, filters)
  }

  const onChangeSelect = (value) => {
    const newFilters = {...filters, warehouse: value}
    setFilters(newFilters)
    setPage(1)
    updateTable(1, newFilters)
  }

  const onChangeField = (key, value, time = 0) => {
    const result = {...filters}
    result[key] = value
    setFilters(result)
    if (timeout) clearTimeout(timeout)
    timeout = setTimeout(() => updateTable(1, result), time)
  }

  return (
    <PageForm>
      <Row gutter={[0, 50]}>
        <Col xs={24}>
          <BackButton
            label={<Translate id='BACK' />}
            onClick={() => router.history.push('/logistics#main')}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <PageTitle>
            <Translate id='INVENTORY_COUNT' />
          </PageTitle>
        </Col>
        <Col xs={12} style={{textAlign: 'right'}}>
          <BaseButton
            type='primary'
            auto
            htmlType='button'
            disabled={!formValues?.items?.length}
            onClick={handleSubmit(onSubmit)}
            loading={saving}
          >
            <Translate id='SAVE' />
          </BaseButton>
        </Col>
      </Row>
      <Margin size={47} />
      <Row>
        <Col xs={12}>
          <BaseButton type='secondary' auto disabled>
            <Translate id='STOCK_REPORT_VARIATION' />
          </BaseButton>
        </Col>
        <Col xs={12} style={{textAlign: 'right'}}>
          <BaseButton type='secondary' auto style={{marginRight: '10px'}}>
            <Translate id='IMPORT_FROM_PDA' />
          </BaseButton>
          <BaseButton type='secondary' auto>
            <Translate id='IMPORT_FROM_EXCEL' />
          </BaseButton>
        </Col>
      </Row>
      <Margin size={16} />

      <Row>
        <Col xs={6}>
          <SelectInputInventoryCount
            input={{
              value: filters.warehouse,
              onChange: onChangeSelect
            }}
          />
        </Col>
        <Col xs={9} />
        <Col xs={9}>
          <Translate id='SEARCH' />
          <SearchInput
            auto
            placeholder={translate('SEARCH')}
            input={{
              value: filters.search,
              onChange: (value) => onChangeField('search', value, 1000),
              onSearch: () => onChangeField('', '')
            }}
            disabled={!filters.warehouse || filters.warehouse === ''}
          />
        </Col>
      </Row>
      <FieldArray
        component={InventoryCountTable}
        name='items'
        page={page}
        pageSize={pageSize}
        total={total}
        onChangePagination={onChangePagination}
        loading={loading}
      />
    </PageForm>
  )
}
InventoryCount.propTypes = {
  router: PropTypes.object.isRequired,
  formValues: PropTypes.object
}

InventoryCount.defaultProps = {
  formValues: {}
}

const InventoryCountPage = reduxForm({
  form: 'inventory_form'
})(InventoryCount)

export default withLocalize(
  connect((state) => ({
    formValues: getFormValues('inventory_form')(state),
    clients: state.info.clients
  }))(InventoryCountPage)
)
