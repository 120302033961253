import React, {useEffect, useState} from 'react'

import {DropboxOutlined} from '@ant-design/icons'
import {Row, Col, Popconfirm} from 'antd'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Field, change} from 'redux-form'
import styled from 'styled-components'

import CloseBorderedIcon from '../../../../../assets/icons/cancel_border.svg'
import {ReactComponent as DropdownIcon} from '../../../../../assets/icons/dropdown_blue.svg'
import {GetEmployeesByB2bclient} from '../../../../../infra/requests/DepartureRequests'
import BaseButton from '../../../../../shared/components/buttons/BaseButton'
import SelectInput from '../../../../../shared/components/inputs/SelectInput'
import SmallTextInput from '../../../../../shared/components/inputs/SmallTextInput'
import {
  TableRow,
  ListCol,
  TableTitle,
  TableInput,
  ListCheck,
  TableText,
  FooterRow,
  TableOptions
} from '../../../../../shared/components/table/CustomTableStyles'
import {PrimaryButtonColor} from '../../../../../shared/styles/_colors'
import {TitleH3} from '../../../../../shared/styles/BasicStyles'
import DeliverySelect from '../deliveryLocations/DeliverySelect'
import {GetClientAddresses, GetEmployeeAddresses} from '../deliveryLocations/DeliveryTransforms'
import ItemsInBox from './ItemsInBox'

const EmptyTable = styled.div`
  display: flex;
  width: 100%;
  margin-top: 3px;
  background: white;
  padding: 50px 30px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 0.6;
  font-size: 18px;
  border-radius: 4px;

  .anticon {
    font-size: 40px;
    margin-bottom: 10px;
  }
`

const ToggleDropdown = styled(DropdownIcon)`
  display: inline-block;
  transform: ${({open}) => (open ? 'rotate(-180deg)' : 'rotate(0)')};
  transition: all 0.2s ease;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 5px;
`

const ValidateButton = styled.button`
  display: inline-block;
  border: none;
  width: auto;
  border-radius: 20px;
  color: white;
  background-color: ${PrimaryButtonColor};
  cursor: pointer;
  border: 1px solid ${PrimaryButtonColor};
  font-size: 14px;
  line-height: 22px;
  padding: 0 10px;
`

const CalculateBoxQuantity = (box) => {
  let total = 0
  if (box && box.items) {
    box.items.forEach((item) => {
      total += item.quantity
    })
  }
  return total
}

const BoxManageTable = ({formName, formValues, dispatch, disabled}) => {
  const [availableEmployees, setAvailableEmployees] = useState([])
  const [clientAddress, setClientAddress] = useState([])
  const [selected, setSelected] = useState([])
  const boxes = formValues?.boxes || []

  useEffect(() => {
    async function GetAvailableB2bEmployees() {
      let client = formValues?.clientId
      if (formValues.movementTypeId == '4') {
        client = formValues?.warehouse?.b2bclientId
      }
      const {data, success} = await GetEmployeesByB2bclient(client)
      if (success) {
        setAvailableEmployees(data.items.map((item) => (
          {id: item.employeeId, name: `${item.firstName} ${item.lastName}`}
        )))
      }
    }
    async function getClientAddress() {
      const list = await GetClientAddresses(formValues?.clientId)
      setClientAddress(list)
    }
    if (formValues.movementTypeId != '5') GetAvailableB2bEmployees()
    getClientAddress()
  }, [])

  const handleCheck = (e, index) => {
    const copy = [...selected]
    if (e.target.checked) {
      copy.push(index)
      setSelected(copy)
    } else {
      setSelected(copy.filter((i) => i !== index))
    }
  }

  const handleCheckAll = (e) => {
    if (e.target.checked) {
      setSelected(boxes.map((b, i) => i))
    } else {
      setSelected([])
    }
  }

  const toggleBox = (index) => {
    dispatch(
      change(formName, `boxes[${index}].open`, !boxes[index].open)
    )
  }

  const confirmBox = (index) => {
    dispatch(change(formName, `boxes[${index}].validated`, true))
  }

  const handleRemoveBoxes = () => {
    const products = JSON.parse(JSON.stringify(formValues?.products || []))
    selected
      .sort((a, b) => b - a)
      .forEach((index) => {
        boxes[index].items.forEach((item) => {
          const ind = products.findIndex(
            (x) =>
              x.reference === item.reference &&
              x.productId === item.productId &&
              x.colorId === item.colorId &&
              x.sizeId === item.sizeId &&
              x.productWidthId === item.productWidthId
          )
          if (ind > -1) {
            products[ind].left += item.quantity
          }
        })
        boxes.splice(index, 1)
      })

    dispatch(change(formName, 'boxes', boxes))
    dispatch(change(formName, 'products', products))
    setSelected([])
  }

  const getEmployeeAddresses = async (id, index) => {
    dispatch(change(formName, `boxes[${index}].deliveryAddressId`, ''))
    dispatch(change(formName, `boxes[${index}].finalAddressId`, ''))
    const result = await GetEmployeeAddresses(id)
    dispatch(change(formName, `boxes[${index}].clientAddresses`, result))
  }

  if (!boxes.length) return null

  return (
    <Row gutter={[16, 40]}>
      <Col xs={12}>
        <TitleH3 $op>Kits and Boxes</TitleH3>
      </Col>
      <Col xs={24}>
        <TableRow $header>
          <ListCol xs={1}>
            <ListCheck
              disabled={!boxes.length || disabled}
              checked={selected.length > 0}
              onChange={handleCheckAll}
              indeterminate={
                selected.length > 0 && selected.length < boxes.length
              }
            />
          </ListCol>
          <ListCol xs={3}>
            <TableTitle text='Package ID' />
          </ListCol>
          <ListCol xs={2}>
            <TableTitle text='Type' />
          </ListCol>
          <ListCol xs={1}>
            <TableTitle text='Items' />
          </ListCol>
          <ListCol xs={5}>
            <TableTitle text='Employee' />
          </ListCol>
          <ListCol xs={5}>
            <TableTitle text='Delivery Address' />
          </ListCol>
          <ListCol xs={5}>
            <TableTitle text='Final Address' />
          </ListCol>
          <ListCol xs={2} />
        </TableRow>
        {boxes.length === 0 && (
          <EmptyTable>
            <DropboxOutlined />
            Select products to create boxes
          </EmptyTable>
        )}
        {boxes.map((box, index) => (
          <TableRow key={index} $error={box.error && !box.validated}>
            <ListCol xs={1}>
              <ListCheck
                checked={selected.includes(index)}
                onChange={(e) => handleCheck(e, index)}
                disabled={disabled}
              />
            </ListCol>
            <ListCol xs={3}>
              <TableInput>
                <Field
                  component={SmallTextInput}
                  name={`boxes[${index}].packageId`}
                  width='80%'
                  disabled={disabled}
                />
              </TableInput>
            </ListCol>
            <ListCol xs={2}>
              <TableText $align='left'>
                {box.isKit ? 'Kit' : 'Box'}
              </TableText>
            </ListCol>
            <ListCol xs={1}>
              <TableText>{CalculateBoxQuantity(box)}</TableText>
            </ListCol>
            <ListCol xs={5}>
              <TableText>
                {box.isKit ? (
                  <Field
                    component={SelectInput}
                    small
                    adjustable
                    name={`boxes[${index}].employeeId`}
                    disabled={disabled || formValues.boxes[index].generatedKit}
                    placeholder='Set a Employee'
                    data={availableEmployees}
                    afterChange={(value) => getEmployeeAddresses(value, index)}
                  />
                ) : '-'}
              </TableText>
            </ListCol>
            <ListCol xs={5}>
              <TableInput>
                {box.isKit ? (
                  <Field
                    disabled={disabled}
                    component={DeliverySelect}
                    name={`boxes[${index}].deliveryAddressId`}
                    data={formValues.boxes[index].clientAddresses || []}
                    placeholder={box.employeeId ? 'Select delivery address' : 'Select employee first'}
                  />
                )
                  : (
                    <Field
                      disabled={disabled}
                      component={DeliverySelect}
                      name={`boxes[${index}].deliveryAddressId`}
                      data={clientAddress}
                      placeholder='Select delivery address'
                    />
                  )}
              </TableInput>
            </ListCol>
            <ListCol xs={5}>
              <TableInput>
                {box.isKit ? (
                  <Field
                    disabled={disabled}
                    component={DeliverySelect}
                    name={`boxes[${index}].finalAddressId`}
                    data={formValues.boxes[index].clientAddresses || []}
                    placeholder={box.employeeId ? 'Select final address' : 'Select employee first'}
                  />
                ) : '-'}
              </TableInput>
            </ListCol>
            <ListCol xs={2}>
              <TableText $first>
                <TableOptions>
                  {(box.error && !box.validated) && (
                    <Popconfirm
                      placement='topLeft'
                      title='By pressing confirm you are acknowledging that only the available product quantities will be sent to the employee'
                      onConfirm={() => confirmBox(index)}
                      okText='Confirm'
                    >
                      <ValidateButton type='button'>validate</ValidateButton>
                    </Popconfirm>
                  )}
                  <ToggleDropdown
                    open={box.open}
                    onClick={() => toggleBox(index)}
                  />
                </TableOptions>
              </TableText>
            </ListCol>
            <ItemsInBox box={box} />
          </TableRow>
        ))}
        <FooterRow gutter={[16, 16]}>
          <ListCol xs={3}>
            <BaseButton
              disabled={selected.length <= 0 || disabled}
              type='secondary'
              size='small'
              onClick={handleRemoveBoxes}
              imagePath={CloseBorderedIcon}
            >
              Remove
            </BaseButton>
          </ListCol>
        </FooterRow>
      </Col>
    </Row>
  )
}

BoxManageTable.propTypes = {
  dispatch: PropTypes.func.isRequired,
  formValues: PropTypes.object
}

BoxManageTable.defaultProps = {
  formValues: {}
}

export default connect()(BoxManageTable)
