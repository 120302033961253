import React from 'react'

import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'
import {connect} from 'react-redux'
import {Field} from 'redux-form'

import SelectInput from '../../../shared/components/inputs/SelectInput'
import SmallTextInput from '../../../shared/components/inputs/SmallTextInput'
import SwitchInput from '../../../shared/components/inputs/SwitchInput'
import CollapsableHeaderTitle from '../components/CollapsableHeaderTitle'
import {SupplierToggle} from '../components/POStyles'

const CollapsableHeader = ({
  SO,
  index,
  onSave,
  paymentConditions,
  resetReview
}) => (
  <>
    <CollapsableHeaderTitle
      max={350}
      name='Supplier'
      description={SO?.supplier?.name}
    />
    <CollapsableHeaderTitle
      stopPropagation
      name='Payment Condition'
      description={
        <Field
          component={SelectInput}
          small
          allowClear={false}
          name={`orders[${index}].paymentCondition.paymentConditionId`}
          placeholder={<Translate id='SELECT_PAYMENT_CONDITION' />}
          data={paymentConditions}
          dataLabel='name'
          dataKey='paymentConditionId'
          afterChange={() => resetReview(index)}
        />
      }
    />
    <CollapsableHeaderTitle
      stopPropagation
      name='Supplier Email'
      description={
        <Field
          component={SmallTextInput}
          name={`orders[${index}].email`}
          placeholder='Email'
          afterChange={() => resetReview(index)}
        />
      }
    />
    <SupplierToggle onClick={(e) => e.stopPropagation()}>
      <SwitchInput
        input={{
          value: SO.confirmationReviewed,
          onChange: onSave
        }}
        checkedText={<Translate id='REVIEWED' />}
        uncheckedText={<Translate id='UNREVIEWED' />}
      />
    </SupplierToggle>
  </>
)

CollapsableHeader.propTypes = {
  SO: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  paymentConditions: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  resetReview: PropTypes.func.isRequired
}

CollapsableHeader.defaultProps = {}

const mapStateToProps = (state) => ({
  paymentConditions: state.info.paymentConditions
})

export default connect(mapStateToProps)(CollapsableHeader)
