import React, { useEffect, useState } from 'react'

import { Col, Row } from 'antd'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { reduxForm, Field, initialize, getFormValues } from 'redux-form'

import { GetTrendingProducts } from '../../../../infra/requests/MarketingRequests'
import { GetRelatedProducts } from '../../../../infra/requests/ProductsRequests'
import BaseButton from '../../../../shared/components/buttons/BaseButton'
import SelectInput from '../../../../shared/components/inputs/SelectInput'
import { Margin, PageForm } from '../../../../shared/styles/BasicStyles'

const TrendingProductsTab = ({
  dispatch,
  handleSubmit,
  currentFormValues
}) => {
  const [hasFetchedTrendingProduct, setHasFetchedTrendingProduct] =
    useState(false)
  const [products, setProducts] = useState([])

  useEffect(() => {

    async function fetchTrendingProducts() {
      try {
        const { data, success } = await GetTrendingProducts()

        if (success) {
          dispatch(initialize('trending_products_form', data))
        }

        const resultP = await GetRelatedProducts(1)
        setProducts(resultP?.data?.items)
        setHasFetchedTrendingProduct(true)
      } catch (error) {
        console.log(error)
      }
    }
    if (!hasFetchedTrendingProduct) {
      fetchTrendingProducts()
    }
  }, [])

  const onSubmit = async (values) => {
    console.log(values)
  }

  return (
    <>
      <PageForm onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col xs={20} />
          <Col xs={4} style={{ textAlign: 'right' }}>
            <BaseButton auto htmlType='submit'>
              <Translate id='SAVE' />
            </BaseButton>
          </Col>
        </Row>
        <Margin size={54} />
        <Row gutter={24}>
          {currentFormValues?.length > 0 &&
            currentFormValues.map((item, index) => (
              <Col xs={6}>
                <Field
                  component={SelectInput}
                  name={`[${index}].productId`}
                  label={
                    <Translate id={`TRENDING_PRODUCT_${index + 1}`} />
                  }
                  data={products}
                  dataLabel='productTranslation'
                  dataKey='productId'
                />
              </Col>
            ))}
        </Row>
      </PageForm>
    </>
  )
}

TrendingProductsTab.propTypes = {
  dispatch: PropTypes.func,
  handleSubmit: PropTypes.func
}

TrendingProductsTab.defaultProps = {
  dispatch: undefined,
  handleSubmit: undefined
}

const TrendingProductsTabPage = reduxForm({
  form: 'trending_products_form'
  // validate: validations
})(TrendingProductsTab)

export default connect((state) => ({
  currentFormValues: getFormValues('trending_products_form')(state)
}))(TrendingProductsTabPage)
