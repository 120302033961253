import React, { useState, useEffect } from "react";

import { LoadingOutlined } from "@ant-design/icons";
import { Row, Col } from "antd";
import moment from "moment";
import PropTypes from "prop-types";
import { Translate } from "react-localize-redux";

import { GetCockpitEndpoint } from "../../../infra/requests/CockpitRequests";
import DateRangeInput from "../../../shared/components/inputs/DateRangeInput";
import SelectInput from "../../../shared/components/inputs/SelectInput";
import ActiveTranslation from "../../../shared/logic/translations/ActiveTranslation";
import {
  BarGraphicColors,
  PieGraphColors,
} from "../../../shared/styles/_colors";
import { Margin, StyledFlex } from "../../../shared/styles/BasicStyles";
import {
  ChartContainer,
  DashboardLoading,
  ChartSeparator,
  ChartTitle,
  ChartSubTitle,
  GraphicContainer,
} from "../DashboardStyles";
import DoubleAxisGraphic from "./DoubleAxisGraphic";
import GroupBarComponent from "./GroupBarComponent";
import PieComponent from "./PieComponent";
import StackBarComponent from "./StackBarComponent";
import TableComponent from "./TableComponent";

const DateRanges = {
  "This Month": [moment().startOf("month"), moment().endOf("month")],
  "Last Month": [
    moment().subtract(1, "month").startOf("month"),
    moment().subtract(1, "month").endOf("month"),
  ],
  "This Year": [moment().startOf("year"), moment().endOf("year")],
  "Last Year": [
    moment().subtract(1, "year").startOf("year"),
    moment().subtract(1, "year").endOf("year"),
  ],
};

const SplitLabelName = (value) => value.split(/(?=[A-Z])/).join(" ");

const GetInformationLabels = (data, graph) => {
  const options = {
    empty: false,
    x: undefined,
    y: [],
    table: [],
    colors: [],
  };
  if (data?.items && data.items.length > 0) {
    switch (graph.GraphType) {
      case "gbar":
      case "sbar":
      case "hbar":
      case "dbar":
        {
          let colorId = 0;
          Object.keys(data.items[0]).forEach((key) => {
            if (key.indexOf("x_") === 0) {
              options.x = {
                key,
                name: SplitLabelName(key.replace("x_", "")),
              };
            }
            if (key.indexOf("y_") === 0) {
              options.y.push({
                key,
                name: SplitLabelName(key.replace("y_", "")),
                color:
                  BarGraphicColors[colorId] ||
                  BarGraphicColors[
                    Math.floor(Math.random() * BarGraphicColors.length)
                  ],
              });
              colorId += 1;
            }
          });
        }
        break;
      case "pie": {
        let colorIndex = -1;
        Object.keys(data.items[0]).forEach((key) => {
          if (key.indexOf("value_") === 0) {
            options.y = key;
          }
          if (key.indexOf("label_") === 0) {
            options.x = key;
          }
        });
        options.colors = data.items.map((it) => {
          if (it.fill && it.fill !== "") return it.fill;
          colorIndex += 1;
          return (
            PieGraphColors[colorIndex] ||
            PieGraphColors[Math.floor(Math.random() * PieGraphColors.length)]
          );
        });
        break;
      }
      case "table":
        Object.keys(data.items[0]).forEach((key) => {
          options.table.push({
            dataIndex: key,
            title: SplitLabelName(key),
            render: (value) => <ActiveTranslation value={value} tag="name" />,
          });
        });
        break;
      default:
        options.empty = true;
    }
  } else options.empty = true;

  return options;
};

const CockpitGraphic = ({ graph, positions, clients }) => {
  const [loading, setLoading] = useState(true);
  const [updating, setUpdate] = useState(false);
  const [info, setInfo] = useState({ items: [], labels: [], options: {} });
  const [filters, setFilters] = useState([]);
  const [page, setPage] = useState(1);
  const pageSize = 12;

  const DefineEndpointFilters = (endpoint, params) => {
    const result = [];
    // const matches = endpoint.match(/(?<={)[^}]*/g)
    const stringParams = endpoint.match(/{([^}]*)}/g);
    const matches = stringParams.map((a) => {
      let newString = a.replace("{", "");
      newString = newString.replace("}", "");
      return newString;
    });
    if (matches && matches.length) {
      matches.forEach((key) => {
        if (key === "startdate") {
          result.push({
            type: "daterange",
            match: key,
            value: [moment.utc().startOf("year"), moment.utc().endOf("year")],
          });
        }
        if (key === "positionId") {
          const all = params.indexOf("if 0,") > -1;
          const list = all
            ? [{ positionId: "0", name: "All Workgroups" }, ...positions]
            : positions;
          result.push({
            type: "select",
            match: key,
            dataKey: "positionId",
            dataLabel: "name",
            data: list,
            value: list[0].positionId,
          });
        }
        if (key === "b2bclientId") {
          const all = params.indexOf("if 0,") > -1;
          const list = all
            ? [{ b2bclientId: "0", name: "All B2B clients" }, ...clients]
            : clients;
          result.push({
            type: "select",
            match: key,
            dataKey: "b2bclientId",
            dataLabel: "name",
            data: list,
            value: list[0].b2bclientId,
          });
        }
      });
    }

    return result;
  };

  useEffect(() => {
    const initialize = async () => {
      const ftrs = DefineEndpointFilters(graph.Endpoint, graph.Parameters);
      const { data, success } = await GetCockpitEndpoint(
        graph.Endpoint,
        ftrs,
        page,
        pageSize
      );
      if (success) {
        setInfo({
          ...data,
          options: GetInformationLabels(data, graph),
        });
        setFilters(ftrs);
        setLoading(false);
      }
    };
    initialize();
  }, []);

  const handleFilterChange = async (value, fIndex) => {
    setUpdate(true);
    const newFilters = [...filters];
    if (value != 0 && filters[fIndex].dataKey === "b2bclientId") {
      const positionIndex = newFilters.findIndex(
        (f) => f.dataKey === "positionId"
      );
      if (positionIndex > -1) newFilters[positionIndex].value = "0";
    }
    newFilters[fIndex].value = value;
    setFilters(newFilters);
    const { data } = await GetCockpitEndpoint(
      graph.Endpoint,
      newFilters,
      page,
      pageSize
    );
    setInfo({
      ...info,
      items: data?.items || [],
      totalItems: data?.totalItems || 0,
      options: GetInformationLabels(data, graph),
    });
    setUpdate(false);
  };

  const getFilteredData = (data) => {
    if (
      data.some((x) => x.positionId) &&
      filters.length > 0 &&
      filters.find((x) => x.dataKey === "b2bclientId").value != 0
    ) {
      return data.filter(
        (x) =>
          x.positionId == "0" ||
          x.b2bclientId ==
            filters.find((y) => y.dataKey === "b2bclientId").value
      );
    }
    return data;
  };

  const handlePageChange = async (p) => {
    setUpdate(true);
    setPage(p);
    const { data } = await GetCockpitEndpoint(
      graph.Endpoint,
      filters,
      p,
      pageSize
    );
    setInfo({
      ...info,
      items: data?.items || [],
      totalItems: data?.totalItems || 0,
      options: GetInformationLabels(data, graph),
    });
    setUpdate(false);
  };

  if (loading) {
    return (
      <ChartContainer>
        <DashboardLoading>
          <LoadingOutlined />
        </DashboardLoading>
      </ChartContainer>
    );
  }

  const renderGraph = () => {
    switch (graph.GraphType) {
      case "gbar":
        return (
          <GroupBarComponent info={info} filters={filters} loading={updating} />
        );
      case "sbar":
        return (
          <StackBarComponent info={info} filters={filters} loading={updating} />
        );
      case "table":
        return (
          <TableComponent
            info={info}
            page={page}
            pageSize={pageSize}
            handlePageChange={handlePageChange}
            loading={updating}
          />
        );
      case "pie":
        return <PieComponent info={info} loading={updating} />;
      case "dbar":
        return <DoubleAxisGraphic info={info} loading={updating} />;
      default:
        return <Translate id="UNKNOWN_GRAPHIC" />;
    }
  };

  return (
    <ChartContainer>
      <StyledFlex
        $direction="row"
        $justify="space-between"
        $align="flex-start"
        $top="20"
        $bottom="10"
        $left="40"
        $right="20"
      >
        <div>
          <ChartTitle>
            <ActiveTranslation
              value={info?.workspace?.workspaceTranslation}
              tag="title"
            />
          </ChartTitle>
          <ChartSubTitle>
            <ActiveTranslation
              value={info?.workspace?.workspaceTranslation}
              tag="subtitle"
            />
          </ChartSubTitle>
        </div>
      </StyledFlex>
      <ChartSeparator $color="#004B84" />
      <Margin size={6} />
      <GraphicContainer>
        <Row gutter={[24, 24]}>
          <div style={{ display: "flex", padding: "0px 20px", width: "100%" }}>
            {filters.map((filter, fIndex) => {
              if (filter.type === "select") {
                return (
                  <Col xs={12} key={fIndex}>
                    <SelectInput
                      allowClear={false}
                      dataKey={filter.dataKey}
                      dataLabel={filter.dataLabel}
                      data={getFilteredData(filter.data)}
                      input={{
                        value: filter.value,
                        onChange: (value) => handleFilterChange(value, fIndex),
                      }}
                    />
                  </Col>
                );
              }
              if (filter.type === "daterange") {
                return (
                  <Col xs={12} key={fIndex}>
                    <DateRangeInput
                      ranges={DateRanges}
                      allowClear={false}
                      input={{
                        value: filter.value,
                        onChange: (value) => handleFilterChange(value, fIndex),
                      }}
                    />
                  </Col>
                );
              }
              return null;
            })}
          </div>
        </Row>
        {renderGraph()}
      </GraphicContainer>
    </ChartContainer>
  );
};

CockpitGraphic.propTypes = {
  graph: PropTypes.object.isRequired,
  positions: PropTypes.array.isRequired,
  clients: PropTypes.array.isRequired,
};

export default CockpitGraphic;
