import { Row, Col } from "antd";
import styled from "styled-components";

export const ChartContainer = styled.div`
  z-index: 1;
  width: 100%;
  margin-top: 24px;
  padding: 0px;
  height: 100%;
  border-radius: 8px;
`;

export const ChartRow = styled(Row)`
  display: flex !important;
`;

export const ChartBox = styled.div`
  width: 100%;
  height: ${({ isAuto }) => (isAuto ? "auto" : "100%")};
  border-radius: 8px;
  margin: ${({ isStatic, isAuto }) =>
    isAuto ? "auto auto 24px" : isStatic ? "-40px -20px 10px" : "0px"};
  max-width: ${({ isAuto }) => (isAuto ? "80%" : "100%")};
  background: ${({ isStatic }) => (isStatic ? "transparent" : "white")};
`;

export const ChartCol = styled(Col)`
  padding: 24px 24px 24px 8px !important;
  display: flex !important;
  flex-direction: column;
`;

export const InfoTop = styled.div`
  display: block;
`;

export const ChartBoxInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-bottom: 8px;
  text-align: center;
`;

export const LegendContainer = styled.div`
  width: 100%;
  margin-top: auto;
`;

export const LegendMeditationContainer = styled.div`
  padding: 0px 24px;
`;

export const LegendLine = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: left;
`;

export const BoxColor = styled.div`
  width: 10px;
  margin-right: 8px;
  margin-top: 8px;
  min-width: 10px;
`;

export const LineSeparator = styled.div`
  width: calc(100% - 48px);
  margin: 24px 24px 0px;
`;

export const ChartTitle = styled.div`
  padding: 24px 24px 0px;
  text-align: left;
  margin-bottom: ${({ margin }) => margin || 0}px;
`;

export const ChartLabelScrollContainer = styled.div`
  max-height: 410px;
  overflow: auto;
`;
