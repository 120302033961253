import React, {useState, useEffect} from 'react'

import moment from 'moment'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import {Translate, withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'
import readXlsxFile from 'read-excel-file'
import {Field} from 'redux-form'

import CloseBorderedIcon from '../../../../assets/icons/cancel_border.svg'
import BaseButton from '../../../../shared/components/buttons/BaseButton'
import NumberIncrementInput from '../../../../shared/components/inputs/NumberIncrementInput'
import AddProductInput from '../../../../shared/components/inputs/Products/AddProductInput'
import {ProductActions, ImportButton} from '../../../../shared/components/inputs/Products/ProductsStyles'
import {
  TableRow,
  ListCol,
  TableTitle,
  TableInput,
  TextTooltip,
  TableText,
  ListCheck
} from '../../../../shared/components/table/CustomTableStyles'
import RoundCurrency from '../../../../shared/logic/numbers/RoundCurrency'
import ActiveTranslation from '../../../../shared/logic/translations/ActiveTranslation'
import {Margin} from '../../../../shared/styles/BasicStyles'
import PriceAlertPopup from './PriceAlertPopup'
import TotalFooter from './TotalFooter'

const CurrencyProductsTable = ({fields, editable, client, refresh, currency}) => {
  const [loading, setLoading] = useState(false)
  const [products, setProducts] = useState(fields.getAll() || [])
  const [selected, setSelected] = useState([])

  useEffect(() => {
    function resetForm() {
      setProducts(fields.getAll() || [])
      setSelected([])
    }
    resetForm()
  }, [client, refresh])

  const addTableProducts = (list) => {
    list.forEach((product) => {
      const found = products.find(
        (prod) => prod.reference === product.reference
      )
      if (!found) {
        fields.push(product)
        products.push(product)
      }
    })
    setProducts(products)
  }

  const handleXlSDrop = (accepted, rejected) => {
    if (accepted && accepted[0]) {
      setLoading(true)
      readXlsxFile(accepted[0])
        .then((rows) => {
          rows.forEach((row) => console.log(row))
        })
        .finally(() => setLoading(false))
    } else console.warn(rejected)
  }

  const handleRemove = () => {
    const list = [...products]
    selected
      .sort((a, b) => b - a)
      .forEach((index) => {
        list.splice(index, 1)
        fields.remove(index)
      })
    setProducts(list)
    setSelected([])
  }

  const handleQuantityUpdate = (value, index) => {
    const list = [...products]
    list[index].quantity = value
    setProducts(list)
    const id = `quantity_${index}`
    setTimeout(() => {
      const input = document.getElementById(id)
      if (input) input.focus()
    }, 100)
  }

  const handleCheckAll = (e) => {
    if (e.target.checked) {
      const all = []
      products.forEach((prod, index) => {
        all.push(index)
      })
      setSelected(all)
    } else {
      setSelected([])
    }
  }

  const handleCheck = (e, index) => {
    const copy = [...selected]
    if (e.target.checked) {
      copy.push(index)
      setSelected(copy)
    } else {
      setSelected(copy.filter((i) => i !== index))
    }
  }

  const otherCurrency = currency && currency.currencyId != 1

  const calculateProductPrices = (product) => {
    if (otherCurrency && product && product.productPrice) {
      const dated = product.productPrice.find((x) =>
        x.currencyId == currency.currencyId &&
      moment(x.dateFrom).isBefore(moment()) &&
      moment(x.dateTo).isSameOrAfter(moment())
      )
      if (dated) {
        const price = product.productTypeId === 4 ? dated.costValue : dated.baseValue
        return {
          value: price * currency.exchangeRate,
          sub: price * currency.exchangeRate * product.quantity,
          currVal: price,
          currSub: price * product.quantity
        }
      }
      const main = product.productPrice.find((x) => x.currencyId == currency.currencyId && !x.dateFrom && !x.dateTo)
      if (main) {
        const price = product.productTypeId === 4 ? main.costValue : main.baseValue
        return {
          value: price * currency.exchangeRate,
          sub: price * currency.exchangeRate * product.quantity,
          currVal: price,
          currSub: price * product.quantity
        }
      }
    }
    const price = product.productTypeId === 4 ? product.costValue : product.baseValue
    return {
      value: price,
      sub: price * product.quantity,
      currVal: 0,
      currSub: 0
    }
  }

  const revised = products.map((p) => ({
    ...p,
    prices: calculateProductPrices(p)
  }))

  return (
    <>
      {editable && (
        <ProductActions>
          <BaseButton
            disabled={selected.length <= 0}
            type='secondary'
            onClick={handleRemove}
            imagePath={CloseBorderedIcon}
            auto
          >
            <Translate id='REMOVE' />
          </BaseButton>
          <Dropzone
            onDrop={handleXlSDrop}
            accept='.xlsx'
            multiple={false}
            noDrag
          >
            {({getRootProps, getInputProps}) => (
              <ImportButton {...getRootProps()}>
                <input {...getInputProps()} />
                <BaseButton auto type='primary' loading={loading}>
                  <Translate id='IMPORT_XLXS' />
                </BaseButton>
              </ImportButton>
            )}
          </Dropzone>
        </ProductActions>
      )}
      <Margin size={20} />

      <TableRow $header>
        <ListCol xs={1}>
          <ListCheck
            disabled={products.length === 0}
            checked={selected.length > 1}
            indeterminate={
              selected.length > 0 && selected.length < products.length
            }
            onChange={handleCheckAll}
          />
        </ListCol>
        <ListCol xs={otherCurrency ? 3 : 4}>
          <TableTitle text={<Translate id='REFERENCE' />} />
        </ListCol>
        <ListCol xs={otherCurrency ? 2 : 3}>
          <TableTitle text={<Translate id='EAN_CODE' />} />
        </ListCol>
        <ListCol xs={otherCurrency ? 2 : 3}>
          <TableTitle text={<Translate id='CLIENT_REF' />} />
        </ListCol>
        <ListCol xs={otherCurrency ? 3 : 4}>
          <TableTitle text={<Translate id='DESCRIPTION' />} />
        </ListCol>
        <ListCol xs={1}>
          <TableTitle text={<Translate id='COLOR' />} />
        </ListCol>
        <ListCol xs={1}>
          <TableTitle text={<Translate id='SIZE' />} />
        </ListCol>
        <ListCol xs={1}>
          <TableTitle text={<Translate id='WIDTH' />} />
        </ListCol>
        <ListCol xs={2}>
          <TableTitle text={<Translate id='QUANTITY' />} />
        </ListCol>
        <ListCol xs={2}>
          <TableTitle text={<Translate id='PRICE_EUROS' />} />
        </ListCol>
        <ListCol xs={2}>
          <TableTitle text={<Translate id='SUB_TOTAL_EUROS' />} />
        </ListCol>
        {otherCurrency && (
          <ListCol xs={2}>
            <TableTitle text={`Price(${currency.code})`} />
          </ListCol>
        )}
        {otherCurrency && (
          <ListCol xs={2}>
            <TableTitle text={`Sub-Total(${currency.code})`} />
          </ListCol>
        )}
      </TableRow>

      <TableRow>
        <ListCol xs={1} />
        <ListCol xs={otherCurrency ? 3 : 4}>
          <TableInput $first>
            <AddProductInput
              onSelectValues={addTableProducts}
              field='ref'
              client={client}
              clientRequired
            />
          </TableInput>
        </ListCol>
        <ListCol xs={otherCurrency ? 2 : 3} />
        <ListCol xs={otherCurrency ? 2 : 3}>
          <TableInput $first>
            <AddProductInput
              dataLabel='referenceClient'
              onSelectValues={addTableProducts}
              field='client_ref'
              client={client}
              clientRequired
            />
          </TableInput>
        </ListCol>
        <ListCol xs={otherCurrency ? 3 : 4}>
          <TableInput $first>
            <AddProductInput
              dataLabel='productTranslation'
              onSelectValues={addTableProducts}
              field='name'
              client={client}
              clientRequired
            />
          </TableInput>
        </ListCol>
      </TableRow>
      {revised.map((product, index) => (
        <TableRow
          key={`${product.reference}_${product.quantity}`}
          $error={otherCurrency && !product?.prices.currVal}
        >
          <ListCol xs={1}>
            <ListCheck
              checked={selected.includes(index)}
              onChange={(e) => handleCheck(e, index)}
            />
          </ListCol>
          <ListCol xs={otherCurrency ? 3 : 4}>
            <TextTooltip text={product.reference || '-'} />
          </ListCol>
          <ListCol xs={otherCurrency ? 2 : 3}>
            <TextTooltip text={product.barcode || '-'} />
          </ListCol>
          <ListCol xs={otherCurrency ? 2 : 3}>
            <TextTooltip text={product.referenceClient || '-'} />
          </ListCol>
          <ListCol xs={otherCurrency ? 3 : 4}>
            <TextTooltip
              text={
                <ActiveTranslation
                  value={product.description}
                  tag='name'
                />
              }
            />
          </ListCol>
          <ListCol xs={1}>
            <TextTooltip
              text={
                <ActiveTranslation
                  value={product?.color?.colorTranslation || '-'}
                  tag='name'
                />
              }
            />
          </ListCol>
          <ListCol xs={1}>
            <TextTooltip text={product?.size?.nameEu || '-'} />
          </ListCol>
          <ListCol xs={1}>
            <TextTooltip text={product?.productWidth?.code || '-'} />
          </ListCol>
          <ListCol xs={2}>
            <TableInput>
              <Field
                component={NumberIncrementInput}
                name={`${fields.name}[${index}].quantity`}
                step='1'
                afterChange={(value) => handleQuantityUpdate(value, index)}
                id={`quantity_${index}`}
              />
            </TableInput>
          </ListCol>
          <ListCol xs={2}>
            <TableText>{RoundCurrency(product?.prices.value)}€</TableText>
          </ListCol>
          <ListCol xs={2}>
            <TableText>
              {RoundCurrency(product?.prices.sub)}€
            </TableText>
          </ListCol>
          {otherCurrency && (
            <ListCol xs={2}>
              <TableText>
                {product?.prices.currVal ?
                  RoundCurrency(product?.prices.currVal) : <PriceAlertPopup product={product} />}
              </TableText>
            </ListCol>
          )}
          {otherCurrency && (
            <ListCol xs={2}>
              <TableText>
                {RoundCurrency(product?.prices.currSub)}
              </TableText>
            </ListCol>
          )}
        </TableRow>
      ))}
      <TotalFooter currency={currency} products={revised} />
    </>
  )
}

CurrencyProductsTable.propTypes = {
  dispatch: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  editable: PropTypes.bool,
  meta: PropTypes.object.isRequired,
  client: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  refresh: PropTypes.number
}

CurrencyProductsTable.defaultProps = {
  editable: true,
  client: undefined,
  refresh: undefined
}

export default withLocalize(connect()(CurrencyProductsTable))
