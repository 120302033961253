import React from "react";

import { Row, Col, Checkbox, Tooltip } from "antd";
import styled from "styled-components";

import {
  BorderColor,
  ErrorColor,
  TableHeaderColor,
  TableTextColor,
} from "../../styles/_colors";

export const TableRow = styled(Row)`
  padding: 5px 0;
  border: 1px solid ${({ $error }) => ($error ? ErrorColor : BorderColor)};
  background: ${({ $header, $subHeader }) =>
    $header ? "#FBFBFB" : $subHeader ? "#E2E2E2" : "transparent"};
  margin-bottom: 3px;
  border-radius: 5px;
  opacity: ${({ $disable }) => ($disable ? 0.2 : 1)};
`;

export const ListRow = styled(Row)`
  padding: 5px 0;
  border-bottom: 1px solid ${BorderColor};

  &:last-child {
    border-bottom: 1px solid transparent;
  }
`;

export const ListCol = styled(Col)``;

const Title = styled.div`
  padding: 0 3px;
  border-left: 1px solid ${({ first }) => (first ? "transparent" : BorderColor)};
  color: ${({ subtitle }) => (subtitle ? TableTextColor : TableHeaderColor)};
  font-weight: ${({ subtitle }) => (subtitle ? "bold" : "regular")};
  font-size: 14px;
  line-height: 22px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  max-height: 22px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
`;

export const TableTitle = styled(({ first, text, abbr, subtitle }) => (
  <Tooltip title={text} mouseEnterDelay={0.2} placement="topLeft">
    <Title subtitle={subtitle} first={first}>
      {abbr || text}
    </Title>
  </Tooltip>
))``;

export const TableText = styled.div`
  position: relative;
  padding: 0 3px;
  border-left: 1px solid
    ${({ $first }) => ($first ? "transparent" : BorderColor)};
  color: ${TableTextColor};
  font-size: 14px;
  line-height: 26px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  height: 26px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
  text-align: ${({ $align }) => $align || "center"};
`;

export const TableInput = styled.div`
  padding: 0 3px;
  border-left: 1px solid
    ${({ $first }) => ($first ? "transparent" : BorderColor)};
  text-align: ${({ $left }) => ($left ? "left" : "center")};
`;

const CheckboxContainer = styled.div`
  width: 100%;
  text-align: center;
  display: inline-block;
`;

export const CheckboxGroup = styled(Checkbox.Group)``;

export const ListCheck = styled((props) => (
  <CheckboxContainer>
    <Checkbox {...props}>{props.label}</Checkbox>
  </CheckboxContainer>
))``;

export const TextTooltip = styled(({ text, first, align = "left" }) => (
  <Tooltip title={text} mouseEnterDelay={1} placement="topLeft">
    <TableText $first={first} $align={align}>
      {text}
    </TableText>
  </Tooltip>
))``;

export const EmptyTable = styled.div`
  display: flex;
  border: 1px solid ${BorderColor};
  padding: 30px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 4px;
`;

export const EmptyTableMessage = styled.div`
  color: rgb(35, 31, 32);
  font-size: 16px;
  line-height: 19px;
  opacity: 0.5;
  text-align: center;
  margin-bottom: 29px;
`;

export const EmptyTableIcon = styled.img`
  width: 92px;
`;

export const FooterRow = styled(Row)`
  padding: 10px;
  background: white;
  border-radius: 4px;
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-top: 3px;
`;

export const TableOptions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
`;
