import React, { useEffect, useState } from "react";

import { Row, Col } from "antd";
import moment from "moment";
import PropTypes from "prop-types";
import { Translate, withLocalize } from "react-localize-redux";
import { connect } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import {
  reduxForm,
  initialize,
  getFormValues,
  FieldArray,
  Field,
} from "redux-form";

import {
  GetOrderB2CDetails,
  GetOrderB2COrderItemsDetails,
  GetOrderPackagesInfo,
  GetOrderShipments,
  AddDocumentToOrder,
  DeleteOrderDocument,
  GetReturnMotiveTranslations,
} from "../../../infra/requests/OrdersB2CRequests";
import FormValidator from "../../../infra/services/validations/FormValidator";
import BackButton from "../../../shared/components/buttons/BackButton";
import BaseButton from "../../../shared/components/buttons/BaseButton";
import DocumentsInput from "../../../shared/components/inputs/Documents/NewDocumentsInput";
import SwitchInput from "../../../shared/components/inputs/SwitchInput";
import BaseLoading from "../../../shared/components/loading/BaseLoading";
import BaseSteps from "../../../shared/components/steps/BaseSteps";
import ActiveTranslation from "../../../shared/logic/translations/ActiveTranslation";
import {
  PageTitle,
  PageForm,
  Margin,
} from "../../../shared/styles/BasicStyles";
import CheckIfReturn from "../helpers/CheckIfReturn";
import PaymentData from "./components/1_PaymentData";
import AddressData from "./components/2_AddressData";
import ProductsData from "./components/3_ProductsData";
import PackingData from "./components/4_PackingData";
import ShippingData from "./components/5_ShippingData";
import FinalData from "./components/6_FinalData";
import ChangeProductModal from "./components/ChangeProductModal";
import EditOrderHeaderTitle from "./components/EditOrderHeaderTitle";
import ReturnsAndExchangesModal from "./components/ReturnsAndExchangesModal";
import CheckIfSameBillingAddress from "./helpers/CheckIfSameBillingAddress";
import GetDefaultOrderStep from "./helpers/GetDefaultOrderStep";
import { GetStatusColor } from "./helpers/StatusTypes";

const validations = FormValidator.make({
  client: "required",
  payment_condition: "required",
  delivery_location: "required",
  shipping_condition: "required",
  delivery_date: "required",
  products: "required|array",
});

const Steps = [
  <Translate id="PAYMENT_DATA" />,
  <Translate id="ADDRESS_CONTACTS" />,
  <Translate id="PICKING_ORDER_DETAILS" />,
  <Translate id="PACKING_DETAILS" />,
  <Translate id="SHIPPING_DETAILS" />,
  <Translate id="FINAL_OVERVIEW" />,
];

// eslint-disable-next-line camelcase
const ManageOrders_B2B2C_B2C = ({
  router,
  dispatch,
  currentFormValues,
  orderStatus,
  paymentMethods,
  countries,
  activeLanguage,
}) => {
  const [loading, setLoading] = useState(true);
  const [maxStep, setMaxStep] = useState(0);
  const [motives, setMotives] = useState([]);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [changeModal, setChangeModal] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [updating, setUpdating] = useState(false);
  const { id } = useParams();
  const location = useLocation();

  const goForward = (step, order) => {
    setCurrentStep(step);
    const selectedOrder = order != null ? order : currentFormValues;
    switch (step) {
      case 2:
        if (selectedOrder?.orderItem?.some((x) => x.quantityConfirmed > 0)) {
          if (selectedOrder.orderBox?.length > 0) {
            if (selectedOrder.orderBox?.some((x) => x.sent)) {
              setMaxStep(5);
            } else {
              setMaxStep(4);
            }
          } else {
            setMaxStep(3);
          }
        } else {
          setMaxStep(2);
        }
        break;
      case 3:
        if (selectedOrder.orderBox?.length > 0) {
          if (selectedOrder.orderBox?.some((x) => x.sent)) {
            setMaxStep(5);
          } else {
            setMaxStep(4);
          }
        } else {
          setMaxStep(3);
        }
        break;
      case 4:
        setMaxStep(4);
        break;
      case 5:
        setMaxStep(5);
        break;
      default:
        setMaxStep(step);
    }
  };

  async function fetchOrder() {
    const { data } = await GetOrderB2CDetails(id);

    // check and validate if is a return or an order
    if (CheckIfReturn(data)) {
      if (data.employeeId) {
        return router.history.push(`/returns/b2b2c/${id}`);
      }
      return router.history.push(`/returns/b2c/${id}`);
    }

    const resultItems = await GetOrderB2COrderItemsDetails(id);
    const resultPackages = await GetOrderPackagesInfo(id);
    const resultShipping = await GetOrderShipments(id);

    dispatch(
      initialize(
        "manage-order-b2b2c-b2c",
        {
          ...data,
          isB2B2C: location.pathname.indexOf("b2b2c") > -1,
          sendNotifications: true,
          orderItem: resultItems?.data?.items,
          billingDataSet: CheckIfSameBillingAddress(data),
          orderBox: resultPackages?.data,
          shipping:
            resultShipping?.data && resultShipping.data.length > 0
              ? resultShipping?.data.map((x) => ({
                carrierId: x.carrierId,
                incoterms: x.incoterms,
                shippingValue: x.shippingValue,
                shippingDate: x.date,
                contents: x.contents,
                estimatedShippingValue: x.shipmentCost,
                estimatedShippingDate: x.shipmentDeliveryDate,
                shipmentId: x.shipmentId,
                shipmentType: x.shipmentType,
                totalValue: x.totalValue,
                trackingNumber: x.trackingNumber,
                boxes: x.boxes,
              }))
              : [],
        } || {}
      )
    );

    const step = GetDefaultOrderStep({
      ...data,
      isB2B2C: location.pathname.indexOf("b2b2c") > -1,
      sendNotifications: true,
      orderItem: resultItems?.data?.items,
      billingDataSet: CheckIfSameBillingAddress(data),
      orderBox: resultPackages?.data,
    });

    goForward(step, {
      orderItem: resultItems?.data?.items,
      billingDataSet: CheckIfSameBillingAddress(data),
      orderBox: resultPackages?.data,
    });

    const { success, data: motvs } = await GetReturnMotiveTranslations(
      activeLanguage?.languageId
    );
    if (success) setMotives(motvs);
    setLoading(false);
    setUpdating(false);
  }

  useEffect(() => {
    fetchOrder();
  }, [id]);

  const renderContentStep = (index) => {
    if (updating) {
      return <BaseLoading />;
    }
    switch (index) {
      case 0:
        return (
          <PaymentData
            paymentMethods={paymentMethods}
            formValues={currentFormValues}
            dispatch={dispatch}
            handleNext={goForward}
          />
        );
      case 1:
        return (
          <AddressData
            formValues={currentFormValues}
            countries={countries}
            handleNext={goForward}
            setCurrentStep={setCurrentStep}
            dispatch={dispatch}
          />
        );

      case 2:
        return (
          <ProductsData
            handleNext={goForward}
            setCurrentStep={setCurrentStep}
            dispatch={dispatch}
          />
        );

      case 3:
        return (
          <PackingData
            handleNext={goForward}
            setCurrentStep={setCurrentStep}
            dispatch={dispatch}
          />
        );

      case 4:
        return (
          <ShippingData
            handleNext={goForward}
            setCurrentStep={setCurrentStep}
            formValues={currentFormValues}
            dispatch={dispatch}
          />
        );

      case 5:
        return (
          <FinalData
            order={currentFormValues}
            countries={countries}
            orderStatus={orderStatus}
            paymentMethods={paymentMethods}
            dispatch={dispatch}
          />
        );

      default:
        return null;
    }
  };

  const leaveOrder = () => {
    if (location.pathname.indexOf("b2b2c") > -1) {
      return router.history.push("/orders#b2b2c");
    }
    return router.history.push("/orders#b2c");
  };

  const handleAddDocument = async (doc) => {
    const payload = new FormData();
    payload.append("Attachments", doc.file);
    payload.append("AttachmentTypes", JSON.stringify([doc.type]));
    const { data, success } = await AddDocumentToOrder(id, payload);
    return { data: data[0], success };
  };

  const handleDeleteDocument = async (docId) => DeleteOrderDocument(id, docId);

  const shouldUpdateProducts = (updated) => {
    setChangeModal(false);
    if (updated) {
      setUpdating(true);
      fetchOrder();
    }
  };

  if (loading) return <BaseLoading margin={150} />;

  return (
    <PageForm>
      <Row gutter={[0, 50]}>
        <Col xs={24}>
          <BackButton
            label={<Translate id="BACK_ORDERS" />}
            onClick={leaveOrder}
          />
        </Col>
        <Col xs={12}>
          <PageTitle>
            <Translate id="ORDER_PROCESS" />
          </PageTitle>
        </Col>
        <Col xs={12} style={{ textAlign: "right" }}>
          <BaseButton
            style={{ marginRight: "10px" }}
            auto
            onClick={() => setChangeModal(true)}
            type="secondary"
          >
            <Translate id="VIEW_CHANGE_PRODUCTS" />
          </BaseButton>
          {currentFormValues?.orderStatusId > 3 && (
            <BaseButton
              auto
              onClick={() => setIsModalOpened(true)}
              type="secondary"
            >
              <Translate id="RETURN_AND_EXCHANGES_ITEMS" />
            </BaseButton>
          )}
          <ReturnsAndExchangesModal
            handleClose={() => setIsModalOpened(false)}
            onOk={() => setIsModalOpened(false)}
            open={isModalOpened}
            orderProducts={currentFormValues?.orderItem || []}
            motives={motives}
          />
          <ChangeProductModal
            open={changeModal}
            products={currentFormValues?.orderItem || []}
            handleClose={shouldUpdateProducts}
          />
        </Col>
      </Row>
      <Margin size={30} />
      <Row gutter={[20, 20]}>
        <Col xs={12} lg={3}>
          <EditOrderHeaderTitle
            label={<Translate id="CLIENT" />}
            value={currentFormValues?.deliveryName}
          />
        </Col>
        <Col xs={12} lg={2}>
          <EditOrderHeaderTitle
            label={<Translate id="ORDER_NMR" />}
            value={currentFormValues?.orderId}
          />
        </Col>
        <Col xs={12} lg={2}>
          <EditOrderHeaderTitle
            label={<Translate id="DATE" />}
            value={moment(currentFormValues?.date).format("DD/MM/YYYY")}
          />
        </Col>
        <Col xs={12} lg={2}>
          <EditOrderHeaderTitle
            label={<Translate id="HOUR" />}
            value={moment(currentFormValues?.date).format("HH:mm")}
          />
        </Col>
        <Col xs={12} lg={3}>
          <EditOrderHeaderTitle
            label={<Translate id="STATUS" />}
            value={
              <ActiveTranslation
                value={currentFormValues?.orderStatus?.orderStatusTranslation}
                tag="name"
              />
            }
            valueColor={GetStatusColor(currentFormValues?.orderStatusId)}
          />
        </Col>
      </Row>
      <Row gutter={[20, 20]} style={{ marginTop: 20 }}>
        <Col xs={12}>
          <EditOrderHeaderTitle
            label={<Translate id="SEND_NOTIFICATIONS" />}
            value={
              <Field
                component={SwitchInput}
                name="sendNotifications"
                checkedText={<Translate id="ON" />}
                uncheckedText={<Translate id="OFF" />}
              />
            }
          />
        </Col>
        <Col xs={6} offset={6}>
          <FieldArray
            addDocument={handleAddDocument}
            deleteDocument={handleDeleteDocument}
            component={DocumentsInput}
            name="attachment"
          />
        </Col>
      </Row>
      <Margin size={30} />
      <BaseSteps
        steps={Steps}
        currentStep={currentStep}
        max={maxStep}
        changeStep={setCurrentStep}
      />
      <Margin size={30} />
      {renderContentStep(currentStep)}
      <Margin size={50} />
    </PageForm>
  );
};

ManageOrders_B2B2C_B2C.propTypes = {
  router: PropTypes.object.isRequired,
  currentFormValues: PropTypes.object,
  orderStatus: PropTypes.array,
  paymentMethods: PropTypes.array,
  dispatch: PropTypes.func,
};

ManageOrders_B2B2C_B2C.defaultProps = {
  currentFormValues: {},
  orderStatus: [],
  paymentMethods: [],
  dispatch: undefined,
};

const myComponent = reduxForm({
  form: "manage-order-b2b2c-b2c",
  validate: validations,
})(ManageOrders_B2B2C_B2C);

export default withLocalize(
  connect((state) => ({
    orderStatus: state.info.orderStatus,
    paymentMethods: state.info.paymentMethods,
    countries: state.info.countries,
    currentFormValues: getFormValues("manage-order-b2b2c-b2c")(state),
  }))(myComponent)
);
