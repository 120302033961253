/* eslint-disable camelcase */
import React, {useEffect, useState} from 'react'

import {Col, Row} from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'
import {useLocation, useParams} from 'react-router'
import {change, Field, FieldArray, getFormValues, initialize, reduxForm} from 'redux-form'

import AddBorderedIcon from '../../../assets/icons/add_bordered.svg'
import {
  GetOrderB2CDetails,
  GetOrderB2COrderItemsDetails,
  MakeOrderItemsApproved,
  MakeOrderItemsDenied,
  MakeOrderItemsReceived,
  MakeOrderItemsRefunded,
  MakeOrderItemsExchanged
} from '../../../infra/requests/OrdersB2CRequests'
import FormValidator from '../../../infra/services/validations/FormValidator'
import AddressComponent from '../../../shared/components/address/AddressComponent'
import BackButton from '../../../shared/components/buttons/BackButton'
import BaseButton from '../../../shared/components/buttons/BaseButton'
import NewDocumentsInput from '../../../shared/components/inputs/Documents/NewDocumentsInput'
import SwitchInput from '../../../shared/components/inputs/SwitchInput'
import BaseLoading from '../../../shared/components/loading/BaseLoading'
import BaseSteps from '../../../shared/components/steps/BaseSteps'
import BaseList from '../../../shared/components/table/BaseList'
import GetCoverImage from '../../../shared/logic/arrays/ImageCoverFunction'
import ActiveTranslation from '../../../shared/logic/translations/ActiveTranslation'
import {TableHeaderColor} from '../../../shared/styles/_colors'
import {Margin, PageForm, PageTitle} from '../../../shared/styles/BasicStyles'
import CheckIfReturn from '../helpers/CheckIfReturn'
import EditOrderHeaderTitle from './components/EditOrderHeaderTitle'
import {EditOrderTitle, ProductDescription, ProductImage, ProductSelection, ProductTitle} from './components/Styles'
import {GetStatusColor} from './helpers/StatusTypes'
import {TransformOrderBillingAddress, TransformOrderShippingAddress} from './helpers/TransformContactsAddresses'

const Steps = [
  <Translate id='RETURNS_ARRIVAL_DATA' />,
  <Translate id='RETURNS_CONFIRMATION_DATA' />,
  <Translate id='RETURNS_PROCESS_DATA' />
]

const GetDefaultStep = (order) => {
  if (order.orderStatusId === 9) {
    return 2
  }

  if (order.orderStatusId === 5 || order.orderStatusId === 8) {
    return 1
  }

  if (order.orderStatusId >= 10) {
    return 2
  }

  return 0
}

const ExchangeAndReturns_B2B2C_B2C = (
  {
    router,
    formValues,
    countries,
    paymentMethods,
    dispatch
  }) => {
  const [loading, setLoading] = useState(true)
  const location = useLocation()
  const [selected, setSelected] = useState([])
  const [currentStep, setCurrentStep] = useState(0)

  const shippingAddress = TransformOrderShippingAddress(formValues)
  const billingAddress = TransformOrderBillingAddress(formValues)
  const isOrderB2B2C = location.pathname.indexOf('b2b2c') > -1
  const isReturn = true
  const {id} = useParams()

  useEffect(() => {
    async function fetchOrder() {
      const {data} = await GetOrderB2CDetails(id)
      if (!CheckIfReturn(data)) {
        if (data.employeeId) {
          return router.history.push(`/orders/b2b2c/${id}`)
        }
        return router.history.push(`/orders/b2c/${id}`)
      }

      const resultItems = await GetOrderB2COrderItemsDetails(id)
      const order = {
        ...data,
        isB2B2C: location.pathname.indexOf('b2b2c') > -1,
        blockNotifications: true,
        orderItem: resultItems?.data?.items
      }

      dispatch(
        initialize(
          'exchange-returns-b2c-b2b2c',
          order
        )
      )
      setCurrentStep(GetDefaultStep(order))
      setLoading(false)
    }
    fetchOrder()
  }, [id])

  if (loading) {
    return (
      <BaseLoading />
    )
  }

  const leaveOrder = () => {
    if (isOrderB2B2C) {
      return router.history.push('/orders#b2b2c')
    }
    return router.history.push('/orders#b2c')
  }

  const setHasArrived = async () => {
    if (selected.length > 0) {
      setLoading(true)
      const requestBody = selected.map((element) => ({orderItemId: element.orderItemId, quantity: element.quantity}))
      const {success} = await MakeOrderItemsReceived(formValues.orderId, formValues.blockNotifications, requestBody)
      if (success && selected.length > 0) {
        const {data} = await GetOrderB2CDetails(id)
        const resultItems = await GetOrderB2COrderItemsDetails(id)

        dispatch(change('exchange-returns-b2c-b2b2c', 'orderStatus', data.orderStatus))
        dispatch(change('exchange-returns-b2c-b2b2c', 'orderStatusId', data.orderStatusId))
        dispatch(change('exchange-returns-b2c-b2b2c', 'orderItem', resultItems?.data?.items))
        setSelected([])
        setCurrentStep(1)
      }
      setLoading(false)
    }
  }

  const setHasConfirmed = async () => {
    let isSuccess = true
    setLoading(true)

    const requestBody = selected.map((element) => ({orderItemId: element.orderItemId, quantity: element.quantity}))
    const {success} = await MakeOrderItemsApproved(formValues.orderId, formValues.blockNotifications, requestBody)
    if (!success) {
      isSuccess = false
    }

    if (isSuccess && selected.length > 0) {
      const {data} = await GetOrderB2CDetails(id)
      const resultItems = await GetOrderB2COrderItemsDetails(id)

      dispatch(change('exchange-returns-b2c-b2b2c', 'orderStatus', data.orderStatus))
      dispatch(change('exchange-returns-b2c-b2b2c', 'orderStatusId', data.orderStatusId))
      dispatch(change('exchange-returns-b2c-b2b2c', 'orderItem', resultItems?.data?.items))
      setSelected([])
      setCurrentStep(2)
    }

    setLoading(false)
  }

  const setHasRejected = async () => {
    let isSuccess = true
    setLoading(true)

    const requestBody = selected.map((element) => ({orderItemId: element.orderItemId, quantity: element.quantity}))
    const {success} = await MakeOrderItemsDenied(formValues.orderId, formValues.blockNotifications, requestBody)
    if (!success) {
      isSuccess = false
    }

    if (isSuccess && selected.length > 0) {
      const {data} = await GetOrderB2CDetails(id)
      const resultItems = await GetOrderB2COrderItemsDetails(id)

      dispatch(change('exchange-returns-b2c-b2b2c', 'orderStatus', data.orderStatus))
      dispatch(change('exchange-returns-b2c-b2b2c', 'orderStatusId', data.orderStatusId))
      dispatch(change('exchange-returns-b2c-b2b2c', 'orderItem', resultItems?.data?.items))
      setSelected([])
      setCurrentStep(2)
    }

    setLoading(false)
  }

  const setAsRefunded = async () => {
    let isSuccess = true
    setLoading(true)

    const requestBody = selected.map((element) => ({orderItemId: element.orderItemId, quantity: element.quantity}))
    const {success} = await MakeOrderItemsRefunded(formValues.orderId, formValues.blockNotifications, requestBody)
    if (!success) {
      isSuccess = false
    }

    if (isSuccess && selected.length > 0) {
      const {data} = await GetOrderB2CDetails(id)
      const resultItems = await GetOrderB2COrderItemsDetails(id)

      dispatch(change('exchange-returns-b2c-b2b2c', 'orderStatus', data.orderStatus))
      dispatch(change('exchange-returns-b2c-b2b2c', 'orderStatusId', data.orderStatusId))
      dispatch(change('exchange-returns-b2c-b2b2c', 'orderItem', resultItems?.data?.items))
      setSelected([])
      setCurrentStep(2)
    }

    setLoading(false)
  }

  const setAsExchanged = async () => {
    let isSuccess = true
    setLoading(true)

    const requestBody = selected.map((element) => ({orderItemId: element.orderItemId, quantity: element.quantity}))
    const {success} = await MakeOrderItemsExchanged(formValues.orderId, formValues.blockNotifications, requestBody)
    if (!success) {
      isSuccess = false
    }

    if (isSuccess && selected.length > 0) {
      const {data} = await GetOrderB2CDetails(id)
      const resultItems = await GetOrderB2COrderItemsDetails(id)

      dispatch(change('exchange-returns-b2c-b2b2c', 'orderStatus', data.orderStatus))
      dispatch(change('exchange-returns-b2c-b2b2c', 'orderStatusId', data.orderStatusId))
      dispatch(change('exchange-returns-b2c-b2b2c', 'orderItem', resultItems?.data?.items))
      setSelected([])
      setCurrentStep(2)
    }

    setLoading(false)
  }

  const setSelectedItemsWrapper = (item) => {
    if (currentStep === 0
      || currentStep === 1
      || (currentStep === 2 && item?.status?.orderStatusId < 10)) {
      if (selected.some((x) => x.orderItemId === item.orderItemId)) {
        setSelected(selected.filter((x) => x.orderItemId !== item.orderItemId))
      } else {
        setSelected([...selected, item])
      }
    }
  }

  const renderOrderItemDetails = (orderItem) => {
    const enableSelect = currentStep === 0
      || currentStep === 1
      || (currentStep === 2 && orderItem?.status?.orderStatusId < 10)
    return (
      <>
        <ProductImage
          src={GetCoverImage(
              orderItem?.productCombination?.product?.productImage
          )}
        >
          {enableSelect && (
            <ProductSelection checked={selected.some((x) => x.orderItemId === orderItem.orderItemId)} defaultChecked={false} />
          )}
        </ProductImage>
        <Margin size='10' />
        <ProductTitle>
          <Translate id='REF' />:
        </ProductTitle>
        <ProductDescription $color={TableHeaderColor}>
          {orderItem?.productCombination?.reference}
        </ProductDescription>
        <ProductTitle>
          <Translate id='NAME' />
        </ProductTitle>
        <ProductDescription>
          <ActiveTranslation
            value={
                orderItem?.productCombination?.product?.productTranslation
            }
            tag='name'
          />
        </ProductDescription>
        <ProductTitle>
          <Translate id='SIZE' />
        </ProductTitle>
        <ProductDescription>
          {orderItem?.productCombination?.size?.number}
        </ProductDescription>
        <ProductTitle>
          <Translate id='COLOR' />
        </ProductTitle>
        <ProductDescription>
          <ActiveTranslation
            value={
                orderItem?.productCombination?.color?.colorTranslation
            }
            tag='name'
          />
        </ProductDescription>
        <ProductTitle>
          <Translate id='QUANTITY' />
        </ProductTitle>
        <ProductDescription>{orderItem?.quantity}</ProductDescription>
        <ProductTitle>
          <Translate id='STATUS' />
        </ProductTitle>
        <ProductDescription>
          <ActiveTranslation
            value={
                orderItem?.status?.orderStatusTranslation
            }
            tag='name'
          />
        </ProductDescription>
      </>
    )
  }

  const renderContentStep = (index) => {
    const newDatasource = [...formValues.orderItem]
    // switch (index) {
    //   case 0:
    //     newDatasource = [...formValues.orderItem.filter((x) => x.quantity > x.quantityDelivered).map((x) => ({...x, quantity: x.quantity - x.quantityDelivered}))]
    //     break
    //   case 1:
    //     newDatasource = [...formValues.orderItem.filter((x) => x.quantityDelivered === x.quantity).map((x) => ({...x, quantity: x.quantityDelivered}))]
    //     break
    //   case 2:
    //     newDatasource = [...formValues.orderItem]
    //     break
    //   default:
    //     break
    // }

    if (newDatasource.length > 0) {
      return (
        <>
          <Row gutter={[20, 20]}>
            <Col xs={24} md={12}>
              <EditOrderTitle>
                <Translate id='PRODUCTS_INFORMATION' />
              </EditOrderTitle>
            </Col>
            {
              (index === 0) && (
                <Col xs={12} md={6} push={6}>
                  <BaseButton
                    onClick={setHasArrived}
                    imagePath={AddBorderedIcon}
                    disabled={selected.length <= 0}
                  >
                    <Translate id='SET_AS_ARRIVED' />
                  </BaseButton>
                </Col>
              )
            }
            {
              (index === 1) && (
                <>
                  <Col xs={12} md={6}>
                    <BaseButton
                      onClick={setHasRejected}
                      type='error'
                      disabled={selected.length <= 0}
                    >
                      <Translate id='SET_AS_DECLINED' />
                    </BaseButton>
                  </Col>
                  <Col xs={12} md={6}>
                    <BaseButton
                      onClick={setHasConfirmed}
                      disabled={selected.length <= 0}
                    >
                      <Translate id='SET_AS_CONFIRMED' />
                    </BaseButton>
                  </Col>
                </>
              )
            }
            {
              (index === 2 && formValues.orderStatusId === 9) && (
                <>
                  <Col xs={12} md={6}>
                    <BaseButton
                      onClick={setAsRefunded}
                      disabled={selected.length <= 0}
                    >
                      <Translate id='SET_AS_REFUNDED' />
                    </BaseButton>
                  </Col>
                  <Col xs={12} md={6}>
                    <BaseButton
                      onClick={setAsExchanged}
                      disabled={selected.length <= 0}
                    >
                      <Translate id='SET_AS_EXCHANGED' />
                    </BaseButton>
                  </Col>
                </>
              )
            }
          </Row>
          <Margin size={20} />
          <Row>
            <Col xs={24}>
              <BaseList
                grid={{
                  gutter: 30,
                  xs: 1,
                  sm: 2,
                  md: 4,
                  lg: 4,
                  xl: 6,
                  xxl: 6
                }}
                datasource={newDatasource}
                renderItem={renderOrderItemDetails}
                pagination={{
                  render: false
                }}
                onClickRow={setSelectedItemsWrapper}
              />
            </Col>
          </Row>
        </>
      )
    }
  }

  return (
    <PageForm>
      <Row gutter={[0, 50]}>
        <Col xs={24}>
          <BackButton
            label={<Translate id='BACK_ORDERS' />}
            onClick={leaveOrder}
          />
        </Col>
        <Col xs={24}>
          <PageTitle>
            {isReturn ? <Translate id='RETURNS_PROCESS' /> : <Translate id='EXCHANGE_PROCESS' />}
          </PageTitle>
        </Col>
      </Row>
      <Margin size={30} />
      <Row gutter={[20, 20]}>
        <Col xs={12} lg={3}>
          <EditOrderHeaderTitle
            label={<Translate id='CLIENT' />}
            value={formValues?.deliveryName}
          />
        </Col>
        <Col xs={12} lg={2}>
          <EditOrderHeaderTitle
            label={<Translate id='ORDER_NMR' />}
            value={(formValues.number && formValues.number != 'NULL') ? formValues.number : formValues.orderId}
          />
        </Col>
        <Col xs={12} lg={2}>
          <EditOrderHeaderTitle
            label={<Translate id='DATE' />}
            value={moment(formValues?.date).format('DD/MM/YYYY')}
          />
        </Col>
        <Col xs={12} lg={2}>
          <EditOrderHeaderTitle
            label={<Translate id='HOUR' />}
            value={moment(formValues?.date).format('HH:mm')}
          />
        </Col>
        <Col xs={12} lg={4}>
          <EditOrderHeaderTitle
            label={<Translate id='STATUS' />}
            value={
              <ActiveTranslation
                value={
                  formValues?.orderStatus?.orderStatusTranslation
                }
                tag='name'
              />
            }
            valueColor={GetStatusColor(formValues?.orderStatusId)}
          />
        </Col>
      </Row>
      <Row gutter={[20, 20]} style={{marginTop: 20}}>
        <Col xs={12} lg={3}>
          <EditOrderHeaderTitle
            label={<Translate id='BLOCK_NOTIFICATIONS' />}
            value={
              <Field
                component={SwitchInput}
                name='blockNotifications'
                checkedText={<Translate id='ON' />}
                uncheckedText={<Translate id='OFF' />}
              />
            }
          />
        </Col>
      </Row>
      <Margin size={10} />
      <Row align='end'>
        <Col xs={6}>
          <FieldArray component={NewDocumentsInput} name='attachment' />
        </Col>
      </Row>
      <Margin size={30} />
      <BaseSteps
        steps={Steps}
        currentStep={currentStep}
        changeStep={setCurrentStep}
        max={currentStep}
        min={currentStep}
      />
      <Margin size={30} />
      <Row>
        <Col xs={24}>
          <EditOrderTitle>
            <Translate id='PAYMENT_DATA' />
          </EditOrderTitle>
        </Col>
      </Row>
      <Margin size={20} />
      <Row>
        <Col xs={4}>
          <EditOrderHeaderTitle
            label={<Translate id='PAYMENT_METHOD' />}
            value={
              paymentMethods.find(
                (x) => x.paymentMethodID == formValues.paymentMethod
              )?.name || 'N/A'
            }
          />
        </Col>
        <Col xs={4}>
          <EditOrderHeaderTitle
            label={<Translate id='PAYMENT_AMOUNT' />}
            value={`${formValues?.totalValue}€`}
          />
        </Col>
        {formValues?.paymentTransactionId && (
          <Col xs={4}>
            <EditOrderHeaderTitle
              label={<Translate id='PAYMENT_ID' />}
              value={formValues?.paymentTransactionId}
            />
          </Col>
        )}
        {formValues?.datePaid && (
          <Col xs={4}>
            <EditOrderHeaderTitle
              label={<Translate id='DATE' />}
              value={moment(formValues?.datePaid).format('DD/MM/YYYY')}
            />
          </Col>
        )}
      </Row>
      <Margin size={10} />
      <Row gutter={[20, 20]}>
        <Col xs={4}>
          <AddressComponent countries={countries} address={shippingAddress} />
        </Col>
        <Col xs={4}>
          <AddressComponent countries={countries} address={billingAddress} />
        </Col>
        <Col xs={4}>
          <EditOrderHeaderTitle
            margin
            label={<Translate id='NAME' />}
            value={formValues?.deliveryName || 'N/A'}
          />
        </Col>
        <Col xs={4}>
          <EditOrderHeaderTitle
            margin
            label={<Translate id='PHONE_NUMBER' />}
            value={formValues?.deliveryTelephone || 'N/A'}
          />
        </Col>
        <Col xs={4}>
          <EditOrderHeaderTitle
            margin
            label={<Translate id='MOBILE_NUMBER' />}
            value={formValues?.deliveryMobile || 'N/A'}
          />
        </Col>
        <Col xs={4}>
          <EditOrderHeaderTitle
            margin
            label={<Translate id='EMAIL' />}
            value={formValues?.deliveryEmail || 'N/A'}
          />
        </Col>
      </Row>
      <Margin size={40} />
      {renderContentStep(currentStep)}
      <Margin size={50} />
    </PageForm>

  )
}

ExchangeAndReturns_B2B2C_B2C.propTypes = {
  router: PropTypes.object.isRequired,
  formValues: PropTypes.object,
  orderStatus: PropTypes.array,
  paymentMethods: PropTypes.array,
  dispatch: PropTypes.func
}

ExchangeAndReturns_B2B2C_B2C.defaultProps = {
  formValues: {},
  orderStatus: [],
  paymentMethods: [],
  dispatch: undefined
}

const validations = FormValidator.make({
  client: 'required',
  payment_condition: 'required',
  delivery_location: 'required',
  shipping_condition: 'required',
  delivery_date: 'required',
  products: 'required|array'
})

const myComponent = reduxForm({
  form: 'exchange-returns-b2c-b2b2c',
  validate: validations
})(ExchangeAndReturns_B2B2C_B2C)

export default withLocalize(connect((state) => ({
  countries: state.info.countries,
  clients: state.info.clients,
  paymentMethods: state.info.paymentMethods,
  formValues: getFormValues('exchange-returns-b2c-b2b2c')(state)
}))(myComponent))
