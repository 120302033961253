import React, { useState, useEffect } from 'react'

import { Row, Col } from 'antd'
import PropTypes from 'prop-types'
import { Translate, withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'

import CancelBordered from '../../../assets/icons/cancel_border.svg'
import {
  GetAllWarehouses,
  GetAllWarehousesDestiny,
  GetMovementTypeList
} from '../../../infra/requests/LogisticsRequests'
import { GetSuppliersList } from '../../../infra/requests/SupplierRequests'
import BordelessButton from '../../../shared/components/buttons/BordelessButton'
import DateRangeInput from '../../../shared/components/inputs/DateRangeInput'
import SearchInput from '../../../shared/components/inputs/SearchInput'
import SelectInput from '../../../shared/components/inputs/SelectInput'
import ActiveTranslation from '../../../shared/logic/translations/ActiveTranslation'

const MaintenanceFilters = ({
  filters,
  setFilters,
  clients,
  translate,
  dateRange
}) => {
  const [warehouses, setWarehouses] = useState([])
  const [warehousesDestiny, setWarehousesDestiny] = useState([])
  const [movements, setMovementsType] = useState([])
  const [suppliers, setSuppliers] = useState([])

  useEffect(() => {
    async function initwarehouses() {
      const { data, success } = await GetAllWarehouses()
      if (success) setWarehouses(data?.items || [])
    }

    async function initwarehousesDestiny() {
      const { data, success } = await GetAllWarehousesDestiny()
      if (success) setWarehousesDestiny(data?.items || [])
    }

    async function initmovementstype() {
      const { data, success } = await GetMovementTypeList()
      if (success) setMovementsType(data?.items || [])
    }

    async function initsuppliers() {
      const { data, success } = await GetSuppliersList(1, 1000, '')
      if (success) setSuppliers(data?.items || [])
    }

    initwarehouses()
    initwarehousesDestiny()
    initmovementstype()
    initsuppliers()
  }, [])

  const onChangeField = (key, value, timeout = 0) => {
    const result = { ...filters }
    result[key] = value
    setFilters(result, timeout)
  }

  const clearSearch = () => {
    setFilters({ Search: '' })
  }

  return (
    <Row gutter={[20, 20]}>
      <Col xs={6}>
        <SelectInput
          label={"B2BClient"}
          input={{
            value: filters.owner,
            onChange: (value) => onChangeField('B2bClientId', value)
          }}
          placeholder={<Translate id='SELECT' />}
          data={clients}
          dataKey='b2bclientId'
          dataLabel='name'
        />
      </Col>
      <Col xs={6}>
        <SelectInput
          label={<Translate id='ORIGIN_WAREHOUSE' />}
          input={{
            value: filters?.warehouse,
            onChange: (value) => onChangeField('OriginWarehouseId', value)
          }}
          placeholder={<Translate id='SELECT' />}
          data={warehouses}
          dataKey='warehouseId'
          dataLabel='name'
        />
      </Col>

      <Col xs={6}>
        <SelectInput
          label="Destiny Warehouse"
          input={{
            value: filters?.destinyWarehouseCode,
            onChange: (value) => onChangeField('DestinyWarehouseId', value)
          }}
          placeholder={<Translate id='SELECT' />}
          data={warehousesDestiny}
          dataKey='warehouseId'
          dataLabel='name'
        />
      </Col>

      <Col xs={6}>
        <SelectInput
          label={<Translate id='MOVEMENT_TYPE' />}
          input={{
            value: filters?.movementTypeId,
            onChange: (value) => onChangeField('TypeId', value)
          }}
          placeholder={<Translate id='SELECT' />}
          data={movements}
          dataKey='movementTypeId'
          dataLabel='movementTypeTranslation'
        />
      </Col>

      <Col xs={6}>
        <SelectInput
          label={<Translate id='SUPPLIER' />}
          input={{
            value: filters.supplierId,
            onChange: (value) => onChangeField('SupplierId', value)
          }}
          placeholder={<Translate id='SELECT' />}
          data={suppliers}
          dataKey='supplierId'
          dataLabel='name'
        />
      </Col>

      <Col style={{ display: "flex", alignItems: "end" }} xs={8}>
        <DateRangeInput
          label="Date"
          input={{
            value: dateRange,
            onChange: (date) => {
              setFilters(
                {
                  ...filters,
                  StartDate: date ? date[0].startOf('day') : undefined,
                  EndDate: date ? date[1].endOf('day') : undefined
                },
                0
              )
            }
          }}
        />
      </Col>
      <Col style={{ display: "flex", alignItems: "end" }} xs={10}>
        <SearchInput
          auto
          input={{
            value: filters.search,
            onChange: (value) => onChangeField('ProductSearch', value, 1000),
            onSearch: () => onChangeField('', '')
          }}
        />
      </Col>
      <Col xs={24} lg={24} md={24} style={{ textAlign: 'right' }}>
        <BordelessButton
          auto
          onClick={clearSearch}
          imagePath={CancelBordered}
        >
          <Translate id='CLEAR_SEARCH' />
        </BordelessButton>
      </Col>
    </Row>
  )
}

MaintenanceFilters.propTypes = {
  translate: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  clients: PropTypes.array.isRequired
}

const mapStateToProps = (state) => ({
  productTypes: state.info.productTypes,
  suppliers: state.info.suppliers,
  productCategories: state.info.productCategories,
  productStyles: state.info.productStyles,
  clients: state.info.clients,
  warehouses: state.info.warehouses
})

export default withLocalize(connect(mapStateToProps)(MaintenanceFilters))
