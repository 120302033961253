import React, { Component } from 'react'

import { Row, Col, Popover } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import AddWhite from '../../../assets/icons/add_white.svg'
import { GetDeparturesList } from '../../../infra/requests/DepartureRequests'
import { GetAllWarehouses, GetAllWarehousesDestiny } from '../../../infra/requests/LogisticsRequests'
import BaseButton from '../../../shared/components/buttons/BaseButton'
import SelectInput from '../../../shared/components/inputs/SelectInput'
import BaseTable from '../../../shared/components/table/BaseTable'
import { ConstructQuery, ConstructSort } from '../../../shared/logic/queries/EndpointQueries'
import ActiveTranslation from '../../../shared/logic/translations/ActiveTranslation'
import { TableTextColor, PrimaryButtonColor } from '../../../shared/styles/_colors'
import { SuccessColor, WarningColor } from '../../../shared/styles/_colors'
import { Margin } from '../../../shared/styles/BasicStyles'

let timeout

const StatusSection = styled.div`
  color: ${({ status }) => status ? SuccessColor : WarningColor};
`

const StatusColumn = ({ status }) => (
  <StatusSection status={status}>
    <Translate id={status ? 'COMPLETE' : 'PENDING'} />
  </StatusSection>
)

const DropMenu = styled.div`
  display: inline-block;
  padding: 0 10px;
`

const MenuLink = styled(NavLink)`
  display: block;
  margin-bottom: 10px;
  cursor: pointer;
    color: ${TableTextColor};
    &:hover {
        color: ${PrimaryButtonColor};
    }
`

class DeparturesList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      rows: [],
      page: 1,
      pageSize: 20,
      total: 0,
      warehouses: [],
      filters: {}
    }
  }

  getColumns = () => [
    {
      title: <Translate id='ID' />,
      type: 'ordered',
      sorter: true,
      dataIndex: 'movementId'
    },
    {
      title: <Translate id='DEPARTURE_TYPE' />,
      type: 'ordered',
      sorter: true,
      dataIndex: 'movementTypeId',
      render: (value, data) => <ActiveTranslation value={data?.movementTypeTranslation} tag='name' />
    },
    {
      title: <Translate id='DEPARTURE_DATE' />,
      dataIndex: 'date',
      type: 'ordered',
      sorter: true,
      render: (value) =>
        value ? moment.utc(value).format('DD/MM/YYYY') : 'N/A'
    },
    {
      title: 'Warehouse',
      type: 'ordered',
      sorter: true,
      dataIndex: 'warehouseId',
      render: (value, data) => data?.warehouse
    },
    {
      title: <Translate id='MOV_QTY' />,
      type: 'text',
      dataIndex: 'quantity',
      render: (value) => Math.abs(value)
    },
    {
      title: <Translate id='TOTAL' />,
      type: 'text',
      dataIndex: 'total',
      render: (value) => (value ? `${Math.abs(value.toFixed(2))}€` : '0€')
    },
    {
      title: <Translate id='STATUS' />,
      type: 'text',
      dataIndex: 'isValid',
      render: (value) => <StatusColumn status={value} />
    },
    {
      title: <Translate id='STAGE' />,
      type: 'text',
      dataIndex: 'stage',
      render: (value, data) => <ActiveTranslation value={data?.movementStatusTranslation} tag='name' />
    }
  ]

  componentDidMount = async () => {
    try {
      const { data, success } = await GetAllWarehousesDestiny()
      if (success) this.setState({ warehouses: data?.items || [] })
      this.updateTable()
    } catch (error) {
      console.warn(error)
    }
  }

  updateTable = async () => {
    const { page, pageSize, filters, sort } = this.state
    this.setState({ loading: true })
    const { data } = await GetDeparturesList(page, pageSize,
      ConstructQuery(filters),
      ConstructSort(sort)
    )
    this.setState({
      rows: data?.items || [],
      total: data?.totalItems || 0,
      loading: false
    })
  }

  onChangePagination = (page) => this.setState({ page }, this.updateTable)

  onChangeSort = (sort) => this.setState({ sort }, this.updateTable)

  setFilters = (values, time) => {
    this.setState({ filters: values, page: 1 }, () => {
      if (timeout) clearTimeout(timeout)
      timeout = setTimeout(this.updateTable, time)
    })
  }

  render() {
    const { loading, rows, page, pageSize, total, warehouses, filters } = this.state
    const { router, departureTypes } = this.props

    return (
      <>
        <Row gutter={[24, 16]}>
          <Col xs={24} sm={12} lg={6}>

            <Popover
              content={
                <DropMenu>
                  <MenuLink to='/logistics/departures/waybill-kits/new'>Waybill Kits</MenuLink>
                  <MenuLink to='/logistics/departures/transfers/new'>WH Transfer</MenuLink>
                </DropMenu>
              }
              placement='rightTop'
              trigger='click'
            >
              <BaseButton
                auto
                imagePath={AddWhite}
              >
                Create new Departure
              </BaseButton>
            </Popover>
          </Col>
          <Col xs={24} md={12} lg={6} />
          <Col xs={24} md={12} lg={6}>
            <SelectInput
              input={{
                value: filters.movementType,
                onChange: (value) => this.setFilters({ ...filters, movementType: value }, 0)
              }}
              data={departureTypes}
              placeholder={<Translate id='DEPARTURE_TYPE' />}
              dataKey='movementTypeId'
              dataLabel='movementTypeTranslation'
            />
          </Col>
          <Col xs={24} md={12} lg={6}>
            <SelectInput
              input={{
                value: filters.warehouse,
                onChange: (value) => this.setFilters({ ...filters, warehouse: value }, 0)
              }}
              data={warehouses}
              placeholder={<Translate id='WAREHOUSE' />}
              dataKey='warehouseId'
              dataLabel='name'
            />
          </Col>
        </Row>
        <Margin size={15} />
        <Row>
          <Col xs={24}>
            <BaseTable
              rowKey='movementId'
              datasource={rows}
              columns={this.getColumns()}
              sortCallback={this.onChangeSort}
              pagination={{
                render: true,
                page,
                pageSize,
                total,
                onChangePagination: this.onChangePagination
              }}
              onClickRow={(row) => {
                if (row.movementTypeId === 11) {
                  router.history.push(`/logistics/departures/waybill-kits/${row.movementId}`)
                }
                if (row.movementTypeId === 4) {
                  router.history.push(`/logistics/departures/transfers/${row.movementId}`)
                }
              }}
              loading={loading}
            />
          </Col>
        </Row>
      </>
    )
  }
}

DeparturesList.propTypes = {
  router: PropTypes.object.isRequired
}

export default connect((state) => ({
  departureTypes: state.info.departureTypes
}))(DeparturesList)
