import React, {useEffect, useState} from 'react'

import {DropboxOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined
} from '@ant-design/icons'
import {Row, Col, Popconfirm} from 'antd'
import moment from 'moment'
import {withLocalize} from 'react-localize-redux'
import styled from 'styled-components'

import CloseBorderedIcon from '../../../../../assets/icons/cancel_border.svg'
import {ReactComponent as DropdownIcon} from '../../../../../assets/icons/dropdown_blue.svg'
import {GetEmployeesByB2bclient} from '../../../../../infra/requests/DepartureRequests'
import AlertService from '../../../../../shared/components/alert/AlertService'
import BaseButton from '../../../../../shared/components/buttons/BaseButton'
import SearchInput from '../../../../../shared/components/inputs/SearchInput'
import SelectInput from '../../../../../shared/components/inputs/SelectInput'
import SmallTextInput from '../../../../../shared/components/inputs/SmallTextInput'
import Pagination from '../../../../../shared/components/pagination/Pagination'
import {
  TableRow,
  ListCol,
  TableTitle,
  TableInput,
  ListCheck,
  TableText,
  FooterRow,
  TableOptions,
  TextTooltip
} from '../../../../../shared/components/table/CustomTableStyles'
import {ErrorColor, SuccessColor} from '../../../../../shared/styles/_colors'
import {TitleH3} from '../../../../../shared/styles/BasicStyles'
import DeliverySelect from '../../components/deliveryLocations/DeliverySelect'
import {GetClientAddresses, GetEmployeeAddresses} from '../../components/deliveryLocations/DeliveryTransforms'
import ItemsInsideKits from './ItemsInsideKits'

const CheckIcon = styled(CheckCircleOutlined)`
  color: ${({color}) => color};
`

const CloseIcon = styled(CloseCircleOutlined)`
  color: ${({color}) => color};
  margin-left: 5px;
`

const EmptyTable = styled.div`
  display: flex;
  width: 100%;
  margin-top: 3px;
  background: white;
  padding: 50px 30px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 0.6;
  font-size: 18px;
  border-radius: 4px;

  .anticon {
    font-size: 40px;
    margin-bottom: 10px;
  }
`

const ToggleDropdown = styled(DropdownIcon)`
  display: inline-block;
  transform: ${({open}) => (open ? 'rotate(-180deg)' : 'rotate(0)')};
  transition: all 0.2s ease;
  cursor: pointer;
  margin-left: 15px;
  margin-right: 5px;
`

const FlexOptions = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`

const CalculateBoxQuantity = (box) => {
  let total = 0
  if (box && box.items) {
    box.items.forEach((item) => {
      total += item.quantity
    })
  }
  return total
}

const SearchOnList = (list, search, availableEmployees) => {
  if (search && search !== '') {
    return list.filter((box) => {
      if (box.packageId) {
        if (box.packageId.toUpperCase().includes(search.toUpperCase())) return true
      }
      if (!Number.isNaN(search)) {
        const total = CalculateBoxQuantity(box)
        if (total == search) return true
      }

      if (box.employeeId) {
        const found = availableEmployees.find((e) => e.id == box.employeeId)
        if (found) {
          if (found.name.toUpperCase().includes(search.toUpperCase())) return true
        }
      }
      return false
    })
  }
  return list
}

const KitsTable = ({workgroups, products, boxes, setBoxes, setProducts, clientId, activeLanguage}) => {
  const [page, setPage] = useState(1)
  const [availableEmployees, setAvailableEmployees] = useState([])
  const [clientAddress, setClientAddress] = useState([])
  const [selected, setSelected] = useState([])
  const [selectWork, setSelectWork] = useState()
  const [search, setSearch] = useState()
  const [manual, setManual] = useState(boxes)
  const [total, setTotal] = useState(boxes.length)
  const limit = 20

  useEffect(() => {
    async function GetAvailableB2bEmployees() {
      const {data, success} = await GetEmployeesByB2bclient(clientId)
      if (success) {
        setAvailableEmployees(data.items.map((item) => (
          {id: item.employeeId, name: `${item.firstName} ${item.lastName}`}
        )))
      }
    }
    async function getClientAddress() {
      const list = await GetClientAddresses(clientId)
      setClientAddress(list)
    }
    GetAvailableB2bEmployees()
    getClientAddress()
  }, [])

  useEffect(() => {
    const list = SearchOnList(boxes, search, availableEmployees)
    setTotal(list.length)
    setManual(list.slice((page - 1) * limit, page * limit))
    if (limit * (page - 1) > list.length) setPage(1)
  }, [boxes, search, page, limit])

  const handleCheck = (e, realIndex) => {
    const copy = [...selected]
    if (e.target.checked) {
      copy.push(realIndex)
      setSelected(copy)
    } else {
      setSelected(copy.filter((i) => i !== realIndex))
    }
  }

  const toggleBox = (realIndex) => {
    setBoxes((old) => {
      const result = [...old]
      result[realIndex].open = !result[realIndex].open
      return result
    })
  }

  const confirmBox = (realIndex) => {
    setBoxes((old) => {
      const result = [...old]
      result[realIndex].validated = true
      return result
    })
  }

  const handleRemoveBoxes = () => {
    const stock = products.map((p) => {
      p.left = p.departQty
      return p
    })

    const boxList = []
    let realIndex = 0
    boxes.forEach((box) => {
      if (!selected.includes(box.realIndex)) {
        const newBox = {...box}
        newBox.items = box.items.map((p) => {
          const found = stock.findIndex((f) => f.barcode === p.barcode)
          if (found > -1) {
            stock[found].left -= p.departQty
            p.error = p.departQty < p.empNeededQty || stock[found].left < 0
          }
          return p
        })
        newBox.realIndex = realIndex
        newBox.error = !!box.items.find((i) => i.error)
        boxList.push(newBox)
        realIndex++
      }
    })
    setBoxes(boxList)
    setProducts(stock)
    setSelected([])
  }

  const removeErrorKits = () => {
    const stock = [...products]
    const boxList = []
    let realIndex = 0
    boxes.forEach((box) => {
      if (box.error && !box.validated) {
        box.items.forEach((i) => {
          const found = stock.findIndex((f) => f.barcode == i.barcode)
          if (found > -1) {
            stock[found].left += i.departQty
          }
        })
      } else {
        boxList.push({...box, realIndex})
        realIndex++
      }
    })
    setProducts(stock)
    setBoxes(boxList)
  }

  const getEmployeeAddresses = async (id, realIndex) => {
    const addresses = await GetEmployeeAddresses(id)
    setBoxes((old) => {
      const result = [...old]
      result[realIndex].deliveryAddressId = undefined
      result[realIndex].finalAddressId = undefined
      result[realIndex].clientAddresses = addresses
      return result
    })
  }

  const changeField = (field, realIndex, value) => {
    setBoxes((old) => {
      const result = [...old]
      result[realIndex][field] = value
      return result
    })
  }

  const reduceQuantity = (itemIndex, item, realIndex) => {
    const prodList = [...products]
    const found = products.findIndex((p) => p.barcode == item.barcode)
    if (found < 0) return AlertService.error('Error', 'This product does not belong to the departure list')
    prodList[found].left += 1
    const boxList = [...boxes]
    boxList[realIndex].items[itemIndex].quantity -= 1

    let stock = prodList[found].departQty
    const revised = boxList.map((box) => {
      box.items = box.items.map((p) => {
        if (p.barcode === item.barcode) {
          stock -= p.quantity
          p.error = stock < 0 || p.quantity < p.empNeededQty
        }
        return p
      })
      box.error = !!box.items.find((i) => i.error)
      return box
    })

    setProducts(prodList)
    setBoxes(revised)
  }

  const increaseQuantity = (itemIndex, item, realIndex) => {
    const prodList = [...products]
    const found = products.findIndex((p) => p.barcode == item.barcode)
    if (found < 0) return AlertService.error('Error', 'This product does not belong to the departure list')
    if (!prodList[found].left || prodList[found].left < 1) return AlertService.error('Error', 'There are no more quantity available of this product')
    prodList[found].left -= 1
    const boxList = [...boxes]
    boxList[realIndex].items[itemIndex].quantity += 1

    let stock = prodList[found].departQty
    const revised = boxList.map((box) => {
      box.items = box.items.map((p) => {
        if (p.barcode === item.barcode) {
          stock -= p.quantity
          p.error = stock < 0 || p.quantity < p.empNeededQty
        }
        return p
      })
      box.error = !!box.items.find((i) => i.error)
      return box
    })

    setProducts(prodList)
    setBoxes(revised)
  }

  const handleRemoveWorkgroup = (value) => {
    const stock = [...products]
    const boxList = []
    let realIndex = 0
    boxes.forEach((box) => {
      if (box.workgroupid && box.workgroupid == value) {
        box.items.forEach((i) => {
          const found = stock.findIndex((f) => f.barcode == i.barcode)
          if (found > -1) {
            stock[found].left += i.departQty
          }
        })
      } else {
        boxList.push({...box, realIndex})
        realIndex++
      }
    })

    setProducts(stock)
    setBoxes(boxList)
    setSelectWork(undefined)
  }

  const showOnlyExistingWorkgroups = () => {
    const unique = boxes.map((b) => b.workgroupid)
      .filter((v, i, a) => a.indexOf(v) === i).filter((a) => a)
    const asd = workgroups.filter((w) => unique.includes(w.positionId))
    return asd
  }

  const ActiveTranslation = (value, tag) => {
    if (
      typeof value === 'string' ||
      typeof value === 'number' ||
      typeof value === 'boolean'
    ) {
      return value
    }
    if (!value || !Array.isArray(value) || value.length === 0) return ''
    const found = value.find(
      (elem) =>
        elem.languageID === activeLanguage.code ||
        elem.languageId === activeLanguage.code
    )
    if (found) return found[tag] || ''
    const en = value.find(
      (elem) => elem.languageID === 2 || elem.languageId === 2
    )
    if (en) return en[tag] || ''
    return value[0][tag] || ''
  }

  const exportCsv = () => {
    const svgRows = []
    svgRows.push(['Employee Id', 'Employee Name', 'Workgroup Id', 'Workgroup Name', 'Reference', 'EAN', 'Client Ref', 'Product Id', 'Description', 'Color', 'Size', 'Width', 'Credits', 'Send Quantity', 'Box Quantity'])
    boxes.forEach((box) => {
      const employee = availableEmployees.find((e) => e.id == box.employeeId)
      const workgroup = workgroups.find((w) => w.positionId == box.workgroupid)
      box.items.forEach((product) => {
        svgRows.push([
          box.employeeId,
          employee?.name || '-',
          box.workgroupid,
          workgroup?.name || '-',
          product.reference || '-',
          product.barcode || '-',
          product.referenceClient || '-',
          product.productId || '-',
          ActiveTranslation(product.description || '-', 'name'),
          ActiveTranslation(product?.color?.colorTranslation || '-', 'name'),
          product?.size?.nameEu || '-',
          product?.productWidth?.code || '-',
          product?.empNeededQty || '-',
          product?.departQty || '0',
          product?.quantity || '0'
        ])
      })
      svgRows.push([' '])
    })
    const csvContent = `data:text/csv;charset=utf-8,\n${svgRows.map((e) => e.join(';')).join('\n')}`
    const encodedUri = encodeURI(csvContent)
    const link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute('download', `kits-list-${moment().format('YYYY:MM:DD:HH:mm:ss')}.csv`)
    document.body.appendChild(link)
    link.click()
  }

  const setWorkgroup = (id) => {
    const workgroup = workgroups.find((w) => w.positionId == id)
    return workgroup?.name || '-'
  }

  if (!boxes.length) return null

  return (
    <Row gutter={[16, 40]}>
      <Col xs={24}>
        <TitleH3 $op>Kits and Boxes</TitleH3>
      </Col>
      <Col xs={24}>
        <FlexOptions>
          <FlexOptions>
            <BaseButton
              disabled={selected.length <= 0}
              type='secondary'
              auto
              onClick={handleRemoveBoxes}
              imagePath={CloseBorderedIcon}
            >
              Remove
            </BaseButton>

            <BaseButton
              type='error'
              auto
              onClick={removeErrorKits}
              style={{marginLeft: '30px'}}
            >
              Remove overstock kits
            </BaseButton>

            <div style={{width: '220px', marginLeft: '30px'}}>
              <SelectInput
                disabled={workgroups.length === 0}
                input={{value: selectWork, onChange: setSelectWork}}
                data={showOnlyExistingWorkgroups()}
                dataKey='positionId'
                dataLabel='name'
                placeholder='Select a workgroup to delete'
                afterChange={handleRemoveWorkgroup}
              />
            </div>

            <BaseButton
              type='secondary'
              auto
              onClick={exportCsv}
              style={{marginLeft: '30px'}}
            >
              Export list
            </BaseButton>
          </FlexOptions>
          <div style={{width: '350px'}}>
            <SearchInput
              input={{value: search, onChange: setSearch}}
            />
          </div>
        </FlexOptions>
      </Col>
      <Col xs={24}>
        <TableRow $header>
          <ListCol xs={1} />
          <ListCol xs={1}>
            <TableTitle text='Type' />
          </ListCol>
          <ListCol xs={1}>
            <TableTitle text='Items' />
          </ListCol>
          <ListCol xs={2}>
            <TableTitle text='Workgroup' />
          </ListCol>
          <ListCol xs={1}>
            <TableTitle text='Gender' />
          </ListCol>
          <ListCol xs={2}>
            <TableTitle text='Number' />
          </ListCol>
          <ListCol xs={3}>
            <TableTitle text='Employee' />
          </ListCol>
          <ListCol xs={4}>
            <TableTitle text='Delivery Address' />
          </ListCol>
          <ListCol xs={4}>
            <TableTitle text='Final Address' />
          </ListCol>
          <ListCol xs={3}>
            <TableTitle text='Notes' />
          </ListCol>
          <ListCol xs={2} />
        </TableRow>
        {boxes.length === 0 && (
          <EmptyTable>
            <DropboxOutlined />
            Select products to create boxes
          </EmptyTable>
        )}
        {manual.length === 0 && (
          <EmptyTable>
            <DropboxOutlined />
            There are no boxes for this search
          </EmptyTable>
        )}
        {manual.map((box) => (
          <TableRow key={box.realIndex} $error={box.error && !box.validated}>
            <ListCol xs={1}>
              <ListCheck
                checked={selected.includes(box.realIndex)}
                onChange={(e) => handleCheck(e, box.realIndex)}
              />
            </ListCol>
            <ListCol xs={1}>
              <TableText $align='left'>
                {box.isKit ? 'Kit' : 'Box'}
              </TableText>
            </ListCol>
            <ListCol xs={1}>
              <TableText>{CalculateBoxQuantity(box)}</TableText>
            </ListCol>
            <ListCol xs={2}>
              <TextTooltip text={setWorkgroup(box?.workgroupid)} />
            </ListCol>
            <ListCol xs={1}>
              <TableText>
                {box.generatedKit ? box?.gender ? 'F' : 'M' : '-'}
              </TableText>
            </ListCol>
            <ListCol xs={2}>
              <TextTooltip text={box.generatedKit ? box?.number || '-' : '-'} />
            </ListCol>
            <ListCol xs={3}>
              <TableText>
                {box.isKit ? (
                  <SelectInput
                    input={{value: box.employeeId, onChange: (v) => changeField('employeeId', box.realIndex, v)}}
                    small
                    adjustable
                    disabled={box.generatedKit}
                    placeholder='Set a Employee'
                    data={availableEmployees}
                    afterChange={(value) => getEmployeeAddresses(value, box.realIndex)}
                  />
                ) : '-'}
              </TableText>
            </ListCol>
            <ListCol xs={4}>
              <TableInput>
                {box.isKit ? (
                  <DeliverySelect
                    input={{value: box.deliveryAddressId, onChange: (v) => changeField('deliveryAddressId', box.realIndex, v)}}
                    data={box.clientAddresses || []}
                    placeholder={box.employeeId ? 'Select delivery address' : 'Select employee first'}
                  />
                )
                  : (
                    <DeliverySelect
                      input={{value: box.deliveryAddressId, onChange: (v) => changeField('deliveryAddressId', box.realIndex, v)}}
                      data={clientAddress}
                      placeholder='Select delivery address'
                    />
                  )}
              </TableInput>
            </ListCol>
            <ListCol xs={4}>
              <TableInput>
                {box.isKit ? (
                  <DeliverySelect
                    input={{value: box.finalAddressId, onChange: (v) => changeField('finalAddressId', box.realIndex, v)}}
                    data={box.clientAddresses || []}
                    placeholder={box.employeeId ? 'Select final address' : 'Select employee first'}
                  />
                ) : '-'}
              </TableInput>
            </ListCol>
            <ListCol xs={3}>
              <TableInput>
                <SmallTextInput
                  input={{value: box.boxText, onChange: (v) => changeField('boxText', box.realIndex, v)}}
                  placeholder='Notes'
                />
              </TableInput>
            </ListCol>
            <ListCol xs={2}>
              <TableText $first>
                <TableOptions>
                  {box.error && !box.validated && (
                    <Popconfirm
                      placement='topLeft'
                      title='By pressing confirm you are acknowledging that only the available product quantities will be sent to the employee'
                      onConfirm={() => confirmBox(box.realIndex)}
                      okText='Confirm'
                    >
                      <CheckIcon color='#c6c8ca' />
                    </Popconfirm>
                  )}
                  {box.error && box.validated && <CheckIcon color={SuccessColor} />}
                  {box.error && <CloseIcon
                    color={!box.validated ? ErrorColor : '#c6c8ca'}
                  />}

                  <ToggleDropdown
                    open={box.open}
                    onClick={() => toggleBox(box.realIndex)}
                  />
                </TableOptions>
              </TableText>
            </ListCol>
            <ItemsInsideKits
              box={box}
              reduceQuantity={(i, p) => reduceQuantity(i, p, box.realIndex)}
              increaseQuantity={(i, p) => increaseQuantity(i, p, box.realIndex)}
            />
          </TableRow>
        ))}
        <FooterRow gutter={[16, 10]}>
          <ListCol xs={24}>
            <Pagination
              small
              page={page}
              pageSize={limit}
              total={total}
              onChangePagination={(p) => {
                setPage(p)
                setSelected([])
              }}
            />
          </ListCol>
        </FooterRow>
      </Col>
    </Row>
  )
}

export default withLocalize(KitsTable)
