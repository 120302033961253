import React, {useState} from 'react'

import {Row, Col} from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'
import {useParams} from 'react-router-dom'
import {Field, change} from 'redux-form'

import DeleteIcon from '../../../../assets/icons/cancel_border.svg'
import AcceptIconWhite from '../../../../assets/icons/done_white.svg'
import {ConfirmOrderPayment} from '../../../../infra/requests/OrdersB2CRequests'
import BaseButton from '../../../../shared/components/buttons/BaseButton'
import {StyledButtonImg} from '../../../../shared/components/buttons/ButtonsStyles'
import NextButton from '../../../../shared/components/buttons/NextButton'
import SelectInput from '../../../../shared/components/inputs/SelectInput'
import BaseLoading from '../../../../shared/components/loading/BaseLoading'
import {
  BorderColor,
  PaymentAccepted,
  DisableButtonColor
} from '../../../../shared/styles/_colors'
import {Margin} from '../../../../shared/styles/BasicStyles'
import EditOrderHeaderTitle from './EditOrderHeaderTitle'
import EditOrderStatus from './EditOrderStatus'
import {EditOrderName} from './Styles'

const PaymentData = ({
  formValues,
  paymentMethods,
  dispatch,
  handleNext
}) => {
  const [loading, setLoading] = useState(false)

  const {id} = useParams()
  const confirmPayment = async () => {
    setLoading(true)
    try {
      const requestBody = {
        paid: 1,
        sendNotifications: !formValues.sendNotifications,
        paymentMethod: formValues.paymentMethod == 0 ? 0 : parseInt(formValues.paymentMethod, 10)
      }

      const {success} = await ConfirmOrderPayment(id, requestBody)
      if (success) {
        dispatch(change('manage-order-b2b2c-b2c', 'paid', true))
        dispatch(change('manage-order-b2b2c-b2c', 'datePaid', moment()))
        handleNext(1)
      }
    } catch (error) {
      console.warn(error)
    }

    setLoading(false)
  }

  const geTotalDiscount = () => {
    if (formValues?.totalNonItemDiscount > 0) {
      return formValues?.totalNonItemDiscount
    }
    let total = 0
    formValues.orderItem.forEach((x) => (total += x.unitVoucherDiscount))
    return total
  }

  if (loading) return <BaseLoading margin='100' />

  return (
    <>
      <Row>
        <Col xs={24}>
          {formValues.paid ? (
            <EditOrderStatus
              label={<Translate id='PAYMENT_STATUS' />}
              values={[{
                text: <Translate id='PAYMENT_ACCEPTED' />,
                background: PaymentAccepted,
                border: PaymentAccepted,
                color: 'white',
                icon: <StyledButtonImg src={AcceptIconWhite} />
              }]}
            />
          ) : (
            <EditOrderStatus
              label={<Translate id='PAYMENT_STATUS' />}
              values={[{
                text: <Translate id='UNPAID' />,
                background: DisableButtonColor,
                border: BorderColor,
                color: BorderColor
              }]}
            />
          )}
        </Col>
      </Row>
      <Margin size={40} />
      <Row gutter={[30, 30]}>
        <Col xs={12} lg={4}>
          <Field
            component={SelectInput}
            data={paymentMethods}
            dataLabel='name'
            dataKey='paymentMethodID'
            name='paymentMethod'
            label={<Translate id='PAYMENT_METHOD' />}
            disabled={
              !(
                (formValues?.paymentMethod === 2 ||
                  formValues?.paymentMethod === 4) &&
                formValues?.paid === false
              )
            }
          />
        </Col>
        <Col xs={12} lg={4}>
          <EditOrderHeaderTitle
            label={<Translate id='PAYMENT_AMOUNT' />}
            value={`${formValues?.totalValue}€`}
          />
        </Col>
        {formValues?.paymentTransactionId && (
          <Col xs={12} lg={6}>
            <EditOrderHeaderTitle
              label={<Translate id='PAYMENT_ID' />}
              value={formValues?.paymentTransactionId}
            />
          </Col>
        )}
        {formValues?.datePaid && (
          <Col xs={12} lg={3}>
            <EditOrderHeaderTitle
              label={<Translate id='DATE' />}
              value={moment(formValues?.datePaid).format('DD/MM/YYYY')}
            />
          </Col>
        )}
      </Row>
      {formValues?.exchangeVoucherId && (
        <Row gutter={[20, 30]} style={{marginTop: 40}}>
          <Col xs={24}>
            <EditOrderName>
              <Translate id='EXCHANGE_VOUCHER' />
            </EditOrderName>
          </Col>
          <Col xs={12} lg={4}>
            <EditOrderHeaderTitle
              label={<Translate id='VOUCHER_ID' />}
              value={formValues?.exchangeVoucherId}
            />
          </Col>
          <Col xs={12} lg={6}>
            <EditOrderHeaderTitle
              label={<Translate id='VOUCHER_CODE' />}
              value={formValues?.exchangeVoucherCode || 'N/A'}
            />
          </Col>
          <Col xs={12} lg={6}>
            <EditOrderHeaderTitle
              label={<Translate id='VALUE_USED' />}
              value={`${formValues?.exchangeVoucherValueUsed}€`}
            />
          </Col>
        </Row>
      )}
      {formValues?.discountVoucherId && (
        <Row gutter={[20, 30]} style={{marginTop: 40}}>
          <Col xs={24}>
            <EditOrderName>
              <Translate id='DISCOUNT_VOUCHER' />
            </EditOrderName>
          </Col>
          <Col xs={12} lg={4}>
            <EditOrderHeaderTitle
              label={<Translate id='VOUCHER_ID' />}
              value={formValues?.discountVoucherId}
            />
          </Col>
          <Col xs={12} lg={6}>
            <EditOrderHeaderTitle
              label={<Translate id='VOUCHER_CODE' />}
              value={formValues?.discountVoucherCode || 'N/A'}
            />
          </Col>
          <Col xs={12} lg={6}>
            <EditOrderHeaderTitle
              label={<Translate id='VALUE_USED' />}
              value={`${geTotalDiscount()}€`}
            />
          </Col>
        </Row>
      )}
      <Margin size={50} />
      <Row>
        <Col xs={12}>
          {/* <BaseButton
            onClick={() => console.warn('delete')}
            type='secondary'
            imagePath={DeleteIcon}
            auto
            disabled={loading}
          >
            <Translate id='CANCEL_ORDER' />
          </BaseButton> */}
        </Col>
        <Col xs={12} style={{textAlign: 'right'}}>
          {formValues?.paid ? (
            <NextButton
              label={<Translate id='NEXT' />}
              onClick={() => handleNext(1)}
              disabled={loading}
            />
          ) : (
            <BaseButton
              onClick={() => confirmPayment()}
              type='secondary'
              auto
              disabled={loading}
            >
              <Translate id='CONFIRM_PAYMENT' />
            </BaseButton>
          )}
        </Col>
      </Row>
    </>
  )
}

PaymentData.propTypes = {
  formValues: PropTypes.object
}

PaymentData.defaultProps = {
  formValues: {}
}

export default PaymentData
