import React, { useState, useEffect } from 'react'

// Importing necessary components from Ant Design, Moment.js, PropTypes, and Redux libraries
import { Row, Col } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Translate, withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'
import { Field, FieldArray, change } from 'redux-form'
import styled from 'styled-components'

// Importing API request functions
import {
  ValidateDepartureStock,
  GetIncotermsDrop,
  GetOrderB2bSimplyfiedPendingList,
  GetWarehouseTransferItemsForOrderB2BWithWarehouse
} from '../../../../infra/requests/DepartureRequests'
import { GetAllWarehouses, GetAllWarehousesDestiny } from '../../../../infra/requests/LogisticsRequests'
import { GetCarriers } from '../../../../infra/requests/OrdersB2CRequests'

// Importing custom input components and shared styles
import DateInput from '../../../../shared/components/inputs/DateInput'
import MultipleSelectInput from '../../../../shared/components/inputs/MultipleSelectInput'
import SelectInput from '../../../../shared/components/inputs/SelectInput'
import SwitchInput from '../../../../shared/components/inputs/SwitchInput'
import TextInput from '../../../../shared/components/inputs/TextInput'
import BaseLoading from '../../../../shared/components/loading/BaseLoading'
import { Margin } from '../../../../shared/styles/BasicStyles'
import DepartureProducts from './DepartureProducts'

// Styled components for custom layout
const Container = styled.div`
  width: 100%;
  position: relative;
`
const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`

// Main component function
const DepartureData = ({
  dispatch,
  formValues,
  disabled,
  translate
}) => {
  // Local state hooks to manage component state
  const [loadOrders, setLoadOrders] = useState(false)
  const [warehouses, setWarehouses] = useState([])
  const [warehousesDestiny, setWarehousesDestiny] = useState([])
  const [carriers, setCarriers] = useState([])
  const [incoterms, SetIncoterms] = useState([])
  const [ordersb2b, setOrdersb2b] = useState([])
  const [selectedProducts, setSelectedProducts] = useState([]) // New state for selected products


  // useEffect hook to fetch initial data on component mount
  useEffect(() => {
    async function init() {
      const warehousesData = await GetAllWarehouses()
      if (warehousesData.success) {
        setWarehouses(warehousesData?.data?.items || [])
      }

      const warehousesData2 = await GetAllWarehousesDestiny()
      if (warehousesData2.success) {
        setWarehousesDestiny(warehousesData2?.data?.items || [])
      }

      const carriersData = await GetCarriers()
      if (carriersData.success) {
        setCarriers(carriersData?.data || [])
      }
      const result = await GetIncotermsDrop()
      if (result.success) {
        const values = []
        result.data.forEach((v, i) => {
          values.push({ id: i, name: v[i] })
        })
        SetIncoterms(values)
      }
    }
    // Fetch pending B2B orders
    const orders = async () => {
      const { data, success } = await GetOrderB2bSimplyfiedPendingList()
      if (success) setOrdersb2b(data?.orderB2bList || [])
    }
    // Initialize data fetch
    init()
    orders()
  }, [])

  // Function to handle warehouse selection and validation
  const addWarehouse = async (warehouseId) => {
    dispatch(
      change(
        'departure_wh_form',
        'warehouse',
        warehouses.find((wh) => wh.warehouseId == warehouseId)
      )
    )
    dispatch(change('departure_wh_form', 'destinyId', undefined))

    if (formValues.products) {
      const { data } = await ValidateDepartureStock({
        warehouseId,
        skus: formValues.products.map((p) => ({
          productId: p.productId,
          productWidthId: p.productWidthId,
          sizeId: p.sizeId,
          colorId: p.colorId,
          units: p.departQty
        }))
      })

      const products = []
      formValues.products.forEach((product) => {
        const availability = data.find(
          (d) =>
            d.productId == product.productId &&
            d.productWidthId == product.productWidthId &&
            d.sizeId == product.sizeId &&
            d.colorId == product.colorId
        )
        products.push({
          ...product,
          availableStock: parseInt(availability?.available || 0, 10)
        })
      })
      dispatch(change('departure_wh_form', 'products', products))
    }
  }

  // Function to handle destiny warehouse selection
  const addDestiny = (warehouseId) => {
    dispatch(
      change(
        'departure_wh_form',
        'destiny',
        warehouses.find((wh) => wh.warehouseId == warehouseId)
      )
    )
  }

  // Function to handle B2B order selection
  const handleSelectOrders = async (selected) => {
    setLoadOrders(true)
    const payload = {
      OriginWarehouseId: formValues?.originId,
      DestinyWarehouseId: formValues?.destinyId,
      OrderB2bList: selected
    }
    const { data, success } = await GetWarehouseTransferItemsForOrderB2BWithWarehouse(payload)
    console.log('success', success)
    console.log('result', data)
    setLoadOrders(false)
  }


  // Main component render
  return (
    <>
      <Row gutter={[40, 20]}>
        <Col xs={5}>
          <Field
            allowClear={false}
            component={SelectInput}
            name='originId'
            label={<Translate id='ORIGIN_WAREHOUSE' />}
            placeholder={translate('ORIGIN_WAREHOUSE')}
            data={warehouses}
            dataKey='warehouseId'
            dataLabel='name'
            afterChange={addWarehouse}
            disabled={disabled}
          />
        </Col>
        <Col xs={5}>
          <Field
            allowClear={false}
            component={SelectInput}
            name='destinyId'
            label={<Translate id='DESTINY_WAREHOUSE' />}
            placeholder={translate('DESTINY_WAREHOUSE')}
            data={warehousesDestiny}
            dataKey='warehouseId'
            dataLabel='name'
            afterChange={addDestiny}
            disabled={disabled}
          />
        </Col>
        <Col xs={5}>
          <Field
            component={DateInput}
            name='departureDate'
            label={<Translate id='DEPARTURE_DATE' />}
            disabledDate={(value) => moment().startOf('day').isAfter(value.endOf('day'))}
            placeholder={translate('SELECT')}
            disabled={disabled}
          />
        </Col>
        <Col xs={5}>
          <Container>
            <Field
              component={MultipleSelectInput}
              name='ordersb2b'
              label={<Translate id='ORDERS_B2B' />}
              placeholder={translate('SELECT')}
              data={ordersb2b}
              dataKey='orderB2bId'
              dataLabel='orderNumber'
              allowAll={false}
              onClose={handleSelectOrders}
              disabled={!formValues?.originId || !formValues?.destinyId}
            />
            {loadOrders && (
              // Loading overlay to indicate data fetching
              <LoadingOverlay>
                <BaseLoading margin={0} />
              </LoadingOverlay>
            )}
          </Container>
        </Col>
        <Col xs={4}>
          <Field
            component={SwitchInput}
            name='requiresValidation'
            label={<Translate id='REQUIRES_VALIDATION' />}
            disabled={disabled}
          />
        </Col>
      </Row>
      <Row gutter={[40, 20]}>
        <Col xs={6}>
          <Field
            allowClear={false}
            component={SelectInput}
            name='carrierId'
            label={<Translate id='AVAILABLE_CARRIERS' />}
            placeholder={translate('SELECT')}
            data={carriers}
            dataKey='carrierID'
            dataLabel='name'
          />
        </Col>
        <Col xs={6}>
          <Field
            allowClear={false}
            component={SelectInput}
            name='incotermsId'
            label={<Translate id='INCOTERMS' />}
            placeholder={translate('SELECT')}
            data={incoterms}
            dataKey='id'
            dataLabel='name'
          />
        </Col>
        <Col xs={6}>
          <Field
            component={DateInput}
            name='shippingDate'
            label={<Translate id='SHIPPING_DATE' />}
            disabledDate={(value) => moment().startOf('day').isAfter(value.endOf('day'))}
            placeholder={translate('SELECT')}
          />
        </Col>
        <Col xs={6}>
          <Field
            component={TextInput}
            name='contents'
            label={<Translate id='SHIPPING_CONTENTS' />}
            placeholder={translate('CONTENT_INFORMATION')}
          />
        </Col>
      </Row>
      <Margin size='50' />
      <Row>
        <Col xs={24}>
          <FieldArray
            component={DepartureProducts}
            name='products'
            dispatch={dispatch}
            departureType={formValues?.movementTypeId}
            originWarehouse={formValues?.warehouse}
            client={formValues?.clientId}
            supplier={formValues?.supplierId}
            disabled={disabled}
          />
        </Col>
      </Row>
    </>
  )
}

// Prop types validation
DepartureData.propTypes = {
  dispatch: PropTypes.func.isRequired,
  formValues: PropTypes.object
}

// Default props
DepartureData.defaultProps = {
  formValues: {}
}

// Connecting component to Redux store and localization HOC
export default withLocalize(connect((state) => ({
  clients: state.info.clients,
  departureTypes: state.info.departureTypes
}))(DepartureData))
