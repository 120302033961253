import React from 'react'
import {Translate} from 'react-localize-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import {
  PropertyDescriptionContainer,
  TitleH5,
  BodyH5
} from '../../styles/BasicStyles'

const BodyText = styled(BodyH5)``

const PropertyDescription = ({label, value}) => (
  <PropertyDescriptionContainer>
    <TitleH5>{label}</TitleH5>
    <BodyText>{value}</BodyText>
  </PropertyDescriptionContainer>
)

PropertyDescription.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.number
  ]),
  value: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.number
  ])
}

PropertyDescription.defaultProps = {
  label: undefined,
  value: undefined
}

export default PropertyDescription
