import React, { Component } from 'react'

import { Row, Col } from 'antd'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { connect } from 'react-redux'

import { GetWarehousesList } from '../../../infra/requests/LogisticsRequests'
import BaseButton from '../../../shared/components/buttons/BaseButton'
import SelectInput from '../../../shared/components/inputs/SelectInput'
import StatusColumn from '../../../shared/components/Status/StatusColumn'
import BaseTable from '../../../shared/components/table/BaseTable'
import { ConstructQuery } from '../../../shared/logic/queries/EndpointQueries'
import { Margin } from '../../../shared/styles/BasicStyles'

class ConfigurationPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      rows: [],
      page: 1,
      pageSize: 20,
      total: 0,
      owner: undefined
    }
  }

  getColumns = () => [
    {
      title: <Translate id='WAREHOUSE' />,
      type: 'text',
      dataIndex: 'name'
    },
    {
      title: <Translate id='CODE' />,
      type: 'text',
      dataIndex: 'code',
      render: (value) => value || <Translate id='NA' />
    },
    {
      title: <Translate id='EXTERNAL_CODE' />,
      type: 'text',
      dataIndex: 'externalCode',
      render: (value) => value || <Translate id='NA' />
    },
    {
      title: <Translate id='OWNER' />,
      type: 'text',
      dataIndex: 'b2bclient',
      render: (value) => value?.name || <Translate id='NA' />
    },
    {
      title: <Translate id='PRIORITY' />,
      type: 'text',
      dataIndex: 'priority'
    },
    {
      title: <Translate id='STATUS' />,
      type: 'text',
      dataIndex: 'active',
      render: (value) => <StatusColumn value={value} />
    }
  ]

  componentDidMount = async () => {
    try {
      this.updateTable()
    } catch (error) {
      console.warn(error)
    }
  }

  updateTable = async () => {
    const { page, pageSize, owner } = this.state
    this.setState({ loading: true })
    const { data } = await GetWarehousesList(
      page,
      pageSize,
      ConstructQuery({ owner })
    )
    this.setState({
      rows: data?.items || [],
      total: data?.totalItems || 0,
      loading: false
    })
  }

  onChangePagination = (page) => this.setState({ page }, this.updateTable)

  render() {
    const { loading, rows, page, pageSize, total, owner } = this.state
    const { router, clients } = this.props

    return (
      <>
        <Row gutter={24}>
          <Col xs={6}>
            <SelectInput
              label={<Translate id='OWNER' />}
              input={{
                value: owner,
                onChange: (value) => {
                  this.onChangePagination(1)
                  this.setState({ owner: value }, this.updateTable)
                }
              }}
              placeholder={<Translate id='SELECT_OWNER' />}
              data={clients}
              dataKey='b2bclientId'
              dataLabel='name'
            />
          </Col>
        </Row>
        <Margin size={24} />
        <Row gutter={24}>
          <Col xs={6}>
            <BaseButton
              onClick={() =>
                router.history.push('/logistics/warehouses/new')
              }
            >
              <Translate id='ADD_WAREHOUSE' />
            </BaseButton>
          </Col>
          <Col xs={5}>
            <BaseButton
              onClick={() => router.history.push('/logistics/rates')}
            >
              <Translate id='SHIPPING_RATES' />
            </BaseButton>
          </Col>
          <Col xs={4}>
            <BaseButton
              onClick={() => router.history.push('/logistics/carriers')}
            >
              <Translate id='CARRIERS' />
            </BaseButton>
          </Col>
        </Row>
        <Margin size={15} />
        <Row>
          <Col xs={24}>
            <BaseTable
              rowKey='warehouseId'
              datasource={rows}
              columns={this.getColumns()}
              pagination={{
                render: true,
                page,
                pageSize,
                total,
                onChangePagination: this.onChangePagination
              }}
              onClickRow={(row) => {
                router.history.push(
                  `/logistics/warehouses/${row.warehouseId}`
                )
              }}
              loading={loading}
            />
          </Col>
        </Row>
      </>
    )
  }
}

ConfigurationPage.propTypes = {
  router: PropTypes.object.isRequired,
  clients: PropTypes.array.isRequired
}

export default connect((state) => ({
  clients: state.info.clients
}))(ConfigurationPage)
