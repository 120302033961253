import React, { useState, useEffect, memo } from 'react'

import { Row, Col } from 'antd'
import PropTypes from 'prop-types'
import { Translate, withLocalize } from 'react-localize-redux'
import { FieldArray } from 'redux-form'

import Trash from '../../../assets/icons/remove.svg'
import AlertService from '../../../shared/components/alert/AlertService'
import BaseButton from '../../../shared/components/buttons/BaseButton'
import DefaultValueInput from '../../../shared/components/inputs/DefaultValueInput'
import IntegerInput from '../../../shared/components/inputs/IntegerInput'
import SelectInput from '../../../shared/components/inputs/SelectInput'
import ProductLink from '../../../shared/components/links/ProductLink'
import BaseTable from '../../../shared/components/table/BaseTable'
import { Margin } from '../../../shared/styles/BasicStyles'

const DotationComponent = ({ fields, products, translate }) => {
  const [selected, setSelected] = useState([])
  const [type, setType] = useState('')
  const [dot, setDot] = useState('')
  const [order, setOrder] = useState('')
  const [renovation, setRenovation] = useState('')
  const [tableData, setTableData] = useState([]) // State for table data
  const [realSort, setRealSort] = useState({ keyToSort: 'product', direction: 'ascend' })

  useEffect(() => {
    const fieldsData = fields.getAll()
    const dataToSort = [...fieldsData]

    let sortedData
    if (realSort.direction === 'ascend') {
      sortedData = dataToSort.sort((a, b) => {
        switch (realSort.keyToSort) {
          case 'order':
            return a.order > b.order ? 1 : -1
          case 'dotation':
            return a.quantity > b.quantity ? 1 : -1
          case 'renovation':
            return a.time > b.time ? 1 : -1
          default:
            return a.product.productTranslation[0].name.localeCompare(b.product.productTranslation[0].name)
        }
      })
    } else if (realSort.direction === 'descend') {
      sortedData = dataToSort.sort((a, b) => {
        switch (realSort.keyToSort) {
          case 'order':
            return a.order > b.order ? -1 : 1
          case 'dotation':
            return a.quantity > b.quantity ? -1 : 1
          case 'renovation':
            return a.time > b.time ? -1 : 1
          default:
            return b.product.productTranslation[0].name.localeCompare(a.product.productTranslation[0].name)
        }
      })
    } else {
      sortedData = dataToSort.sort((a, b) =>
        a.product.productTranslation[0].name.localeCompare(b.product.productTranslation[0].name)
      )
    }
    setTableData(sortedData)
  }, [realSort])

  const filteredProducts = () =>
    products.filter((p) => !tableData.find((t) => t.productId === p.productId))

  const addDotation = () => {
    const item = products.find((x) => x.productId == type)
    const samePosition = tableData.find((r) => r.order == order)

    if (samePosition) {
      return AlertService.error(
        translate('DOTATION_DUPLICATED_ORDER'),
        translate('DOTATION_DUPLICATED_ORDER_MESSAGE')
      )
    }

    if (item !== undefined) {
      const object = {
        productId: type,
        product: {
          productTranslation: item?.productTranslation,
          productId: type
        },
        order,
        quantity: dot,
        time: renovation
      }
      fields.push(object)
      setTableData((prevState) => [...prevState, object])

      // Clear input fields after adding
      setType('')
      setDot('')
      setOrder('')
      setRenovation('')
    }
  }

  const removeDotation = () => {
    const selectedIds = selected.map((element) => element.key)

    for (let i = fields.length - 1; i >= 0; i--) {
      const field = fields.get(i)

      if (selectedIds.includes(field.productId)) {
        fields.remove(i)
      }
    }

    setSelected([])
    setTableData((prevState) => prevState.filter((item) => !selectedIds.includes(item.productId)))
  }

  const handleQuantityChange = (productId, value) => {
    const fieldIndex = fields.getAll().findIndex((field) => field.productId === productId)
    if (fieldIndex !== -1) {
      const updatedField = { ...fields.get(fieldIndex), quantity: value }
      fields.splice(fieldIndex, 1, updatedField)
    }
  }

  const handleRenovationChange = (productId, value) => {
    const fieldIndex = fields.getAll().findIndex((field) => field.productId === productId)
    if (fieldIndex !== -1) {
      const updatedField = { ...fields.get(fieldIndex), time: value }
      fields.splice(fieldIndex, 1, updatedField)
    }
  }

  const onChangeSort = (field) => {
    const { field: keyToSort, order: direction } = field

    setRealSort({
      keyToSort,
      direction
    })
  }

  const columns = [
    {
      title: <Translate id='PRODUCT' />,
      dataIndex: 'product',
      type: 'ordered',
      sorter: true,
      render: (value) => <ProductLink product={value} />
    },
    {
      dataIndex: 'order',
      type: 'ordered',
      sorter: true,
      title: <Translate id='ORDER' />
    },
    {
      dataIndex: 'dotation',
      type: 'ordered',
      sorter: true,
      title: <Translate id='DOTATION' />,
      render: (_, record) => (
        <DefaultValueInput
          initialValue={record.quantity || '0'}
          input={{ value: record.quantity, onChange: (value) => handleQuantityChange(record.productId, value) }}
        />
      )
    },
    {
      dataIndex: 'renovation',
      type: 'ordered',
      sorter: true,
      title: <Translate id='RENOVATION' />,
      render: (_, record) => (
        <DefaultValueInput
          initialValue={record.time}
          input={{ value: record.time, onChange: (value) => handleRenovationChange(record.productId, value) }}
        />
      )
    }
  ]

  const addDotationInputs = [
    {
      colWidth: 8,
      component: SelectInput,
      inputProps: {
        input: { value: type, onChange: setType },
        label: <Translate id='PRODUCTS' />,
        data: filteredProducts(),
        placeholder: <Translate id='CHOOSE' />,
        dataLabel: 'productTranslation',
        dataKey: 'productId'
      }
    },
    {
      colWidth: 4,
      component: IntegerInput,
      inputProps: {
        input: { value: order, onChange: setOrder },
        label: <Translate id='ORDER' />
      }
    },
    {
      colWidth: 4,
      component: IntegerInput,
      inputProps: {
        input: { value: dot, onChange: setDot },
        label: <Translate id='DOTATION' />,
        inputDescriptionText: <Translate id='UNITS' />
      }
    },
    {
      colWidth: 4,
      component: IntegerInput,
      inputProps: {
        input: { value: renovation, onChange: setRenovation },
        label: <Translate id='RENOVATION_TIME' />,
        inputDescriptionText: <Translate id='DAYS' />
      }
    }
  ]

  return (
    <>
      <Row gutter={[24]} align='bottom'>
        {addDotationInputs.map((input, index) => {
          const Component = input.component
          return (
            <Col xs={input.colWidth} key={index}>
              <Component {...input.inputProps} />
            </Col>
          )
        })}
        <Col xs={4}>
          <BaseButton
            auto
            type='secondary'
            onClick={addDotation}
            disabled={
              type === '' ||
              dot === '' ||
              renovation === '' ||
              order === ''
            }
          >
            <Translate id='ADD' />
          </BaseButton>
        </Col>
      </Row>
      <Margin size={38} />
      <Row>
        <Col xs={24}>
          <BaseButton
            auto
            type='secondary'
            imagePath={Trash}
            onClick={removeDotation}
            disabled={selected?.length <= 0}
          >
            <Translate id='DELETE' />
          </BaseButton>
        </Col>
      </Row>
      <Margin size={14} />
      <Row>
        <Col xs={24}>
          <BaseTable
            datasource={tableData} // Use tableData state for the table
            columns={columns}
            pagination={{
              render: false
            }}
            rowSelection={{
              selected,
              onRowSelection: setSelected
            }}
            rowKey='productId'
            sortCallback={onChangeSort}
          />
        </Col>
      </Row>
    </>
  )
}

DotationComponent.propTypes = {
  fields: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
  translate: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired
}

const DotationForm = memo(({ products, id }) => (
  <FieldArray
    component={withLocalize(DotationComponent)}
    name='positionEDSection.positionProduct'
    products={products}
    id={id}
  />
))

DotationForm.propTypes = {
  products: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired
}

export default withLocalize(DotationForm)
