import client from '../config/AxiosConfig'

export const GetInfoOrdersB2C = async () =>
  client.get('/Orders/GetInfoOrdersB2C')

export const GetNewOrders = async (isB2b2c, page, pageSize, filters, sort) =>
  client.get(
    `/Orders/GetNewOrders/${isB2b2c}?Page=${page}&ItemsPerPage=${pageSize}${filters}${sort}`
  )

export const GetBackOrders = async (isB2b2c, page, pageSize, filters, sort) =>
  client.get(
    `/Orders/GetBackOrders/${isB2b2c}?Page=${page}&ItemsPerPage=${pageSize}${filters}${sort}`
  )

export const GetHistoricalOrders = async (isB2b2c, page, pageSize, filters, sort) =>
  client.get(
    `/Orders/GetHistoricalOrders/${isB2b2c}?Page=${page}&ItemsPerPage=${pageSize}${filters}${sort}`
  )

export const GetReturnsExchanges = async (isB2b2c, page, pageSize, filters) =>
  client.get(
    `/Orders/GetReturnsExchanges/${isB2b2c}?Page=${page}&ItemsPerPage=${pageSize}${filters}`
  )

export const GetOrderB2CDetails = async (id) =>
  client.get(`/Orders/GetOrder/${id}`)

export const GetOrderB2COrderItemsDetails = async (id) =>
  client.get(`/Orders/GetOrder/${id}/OrderItems`)

export const ConfirmOrderPayment = async (id, data) =>
  client.put(`/Orders/UpdatePayment/${id}`, data)

export const UpdateOrderB2C = async (data) =>
  client.post('/Orders/UpdateOrder', data)

export const GetCarriers = async () =>
  client.get('Carrier/GetCarriersName')

export const GetCarrierShipping = async (id) =>
  client.get(`Carrier/GetCarrierShippingRates/${id}`)

export const UpdateOrderPicking = async (id, blockNotifications, data) =>
  client.put(`/Orders/UpdatePicking/${id}/${blockNotifications}`, data)

export const ChangeOrderAddressDelivery = async (id, data) =>
  client.put(`/Orders/ChangeOrderAddressDelivery/${id}`, data)

export const ChangeOrderAddressBilling = async (id, data) =>
  client.put(`/Orders/ChangeOrderAddressBilling/${id}`, data)

export const UpdatePackages = async (id, data) =>
  client.put(`/Orders/UpdatePackages/${id}`, data)

export const SendOrderItems = async (id, data) =>
  client.post(`/Orders/SendOrderItems/${id}`, data)

export const GetOrderPackagesInfo = async (id) =>
  client.get(`/Orders/GetOrderPackagesInfo/${id}`)

export const GetOrderShipments = async (id) =>
  client.get(`/Orders/GetOrderShipments/${id}`)

export const StartReturnExchange = async (id, data) =>
  client.post(`/Orders/StartReturnExchange/${id}`, data)

export const GetReturnMotiveTranslations = async (languageId) =>
  client.get(`/ReturnMotives/GetReturnMotiveTranslations/${languageId}`)

export const UpdateOrderItems = async (id, blockNotifications, requestBody) =>
  client.get(`/Orders/UpdateOrderItems/${id}/${blockNotifications}`, requestBody)

export const SetOrderItemDelivered = async (orderItemId) =>
  client.put(`/Orders/SetOrderItemDelivered/${orderItemId}`)

export const MakeOrderItemsReceived = async (orderId, blockNotifications, requestBody) =>
  client.put(`/Orders/MakeOrderItemsReceived/${orderId}/${blockNotifications}`, requestBody)

export const MakeOrderItemsApproved = async (orderId, blockNotifications, requestBody) =>
  client.put(`/Orders/MakeOrderItemsApproved/${orderId}/${blockNotifications}`, requestBody)

export const MakeOrderItemsDenied = async (orderId, blockNotifications, requestBody) =>
  client.put(`/Orders/MakeOrderItemsDenied/${orderId}/${blockNotifications}`, requestBody)

export const MakeOrderItemsRefunded = async (orderId, blockNotifications, requestBody) =>
  client.put(`/Orders/MakeOrderItemsRefunded/${orderId}/${blockNotifications}`, requestBody)

export const MakeOrderItemsExchanged = async (orderId, blockNotifications, requestBody) =>
  client.put(`/Orders/MakeOrderItemsExchanged/${orderId}/${blockNotifications}`, requestBody)

export const GetUserContactDetails = async (id) =>
  client.get(`/AspnetUsers/GetUserContactDetails/${id}`)

export const CreateNewOrder = async (blockNotifications, requestBody) =>
  client.post(`/orders/CreateNewOrder/${blockNotifications}`, requestBody)

export const AddDocumentToOrder = async (order, payload) =>
  client.post(`/orders/AddAttachmentsToOrder/${order}`, payload)

export const DeleteOrderDocument = async (order, doc) =>
  client.delete(`/orders/DeleteAttachmentFromOrder/${order}/${doc}`)


  export const OrderItemsController = async () =>
  client.put(`OrderItemsController/EditOrderItems`)