import client from '../config/AxiosConfig'

export const GetInfo = async () => client.get('/Info/GetInfo')

export const GetCurrencies = async () => client.get('/Info/GetCurrencies')

export const GetAuthUser = async () => client.get('/AspnetUsers/GetInfo')

export const GetPaymentCondition = async () =>
  client.get('/PaymentCondition/GetPaymentConditions')

export const GetDeliveryLocations = async () =>
  client.get('Warehouses/GetDeliveryLocations/1')

export const GetShippingConditions = async () =>
  client.get('/ShippingCondition/GetShippingConditions')

export const GetCurrency = async () =>
  client.get('/Currencies/getCurrenciesDrop')

export const GetOrderStatus = async () =>
  client.get('/OrderStatus/getOrderStatusDrop')

export const GetB2BClients = async () =>
  client.get('/B2bclient/getB2bclientsDrop')

export const GetPaymentMethods = async () =>
  client.get('/PaymentMethod/GetPaymentMethods')

export const GetCountries = async () =>
  client.get('/Countries/GetCountryOrderedBy')

export const GetAllServices = async () =>
  client.get('Services/getServicesDrop')

export const GetSearchProducts = async (ref) =>
  client.get(`/Products/GetProductsAndSkusByRef?ref=${ref}`)

export const GetProductTypes = async () =>
  client.get('/Products/getProductTypesDrop')

export const GetProductCategories = async () =>
  client.get('/Products/getCategoriesDrop')

export const GetProductStyles = async () =>
  client.get('/Products/getStylesDrop')

export const GetProductCharacteristics = async () =>
  client.get('/Products/getCharacteristicsDrop')

export const GetProductCharacteristicValues = async () =>
  client.get('/Products/getCharacteristicValuesDrop')

export const GetProductTechnologies = async () =>
  client.get('/Products/getTechnologiesDrop')

export const GetProductMeasures = async () =>
  client.get('/Products/getMeasuresDrop')

export const GetProductColors = async () =>
  client.get('/Products/getColorsDrop')

export const GetProductSizes = async () =>
  client.get('/Products/getSizesDrop')

export const GetProductWidths = async () =>
  client.get('/Products/getProductWidthsDrop')

export const GetProductTaxRates = async () =>
  client.get('/Products/getTaxRatesDrop')

export const GetSuppliersList = async () =>
  client.get('/Suppliers/getSuppliersDrop')

export const GetSuppliersRawMaterial = async () =>
  client.get('Suppliers/getSuppliersRawMaterialDrop')

export const GetPositions = async () =>
  client.get('/Positions/getPositionsDrop')

export const GetLanguages = async () =>
  client.get('/Languages/getLanguagesDrop')

export const GetContractTypes = async () =>
  client.get('/B2bclient/getContractTypesDrop')

export const GetModulationTypes = async () =>
  client.get('/B2bclient/getModulationTypesDrop')

export const GetEmployeesCategory = async () =>
  client.get('/Employees/getEmployeeCategoryDrop')

export const GetEmployeesStatus = async () =>
  client.get('/Employees/getEmployeeStatusDrop')

export const GetEmployeesCostCenter = async () =>
  client.get('/Employees/getCostCenterDrop')

export const GetEmployeesPosition = async () =>
  client.get('/Employees/getPositionDrop')

export const GetContactTypes = async () =>
  client.get('/Contacts/getContactTypesDrop')

export const GetBannersType = async () =>
  client.get('/Banners/getBannersTypesDrop')

export const GetReceivingServers = async () =>
  client.get('/MailServer/ListReceivingServersDrop')

export const GetReceivingServersData = async () =>
  client.get('/MailServer/ListReceivingServers')

export const GetGeneralServer = async () =>
  client.get('/MailServer/GetGeneralServer/')

export const PutGeneralServer = async (id) =>
  client.put(`/MailServer/PutGeneralServer/${id}/`)

export const GetUsers = async () => client.get('/AspnetUsers/getUsersDrop')

export const GetProcesses = async () =>
  client.get('/Processes/getProcessesDrop')

export const GetRawMaterialFamily = async () =>
  client.get('/Products/getRawMaterialFamilyDrop')

export const GetAllWarehouseTypes = async () =>
  client.get('/Warehouses/GetWarehouseTypesDrop')

export const GetNonEmployeesDrop = async () =>
  client.get('/AspnetUsers/GetNonEmployeesDrop')

export const GetSendServer = async () =>
  client.get('/MailServer/GetSendServer/')

export const GetReceiveServer = async (id) =>
  client.get(`/MailServer/GetReceivingServer/${id}`)

export const PutSendServer = async (data) =>
  client.put('/MailServer/PutSendServer/', data)

export const PostReceivingServer = async (info) =>
  client.post('/MailServer/PostReceivingServer/', info)

export const PutReceivingServer = async (id, data) =>
  client.put(`/MailServer/PutReceivingServer/${id}/`, data)
