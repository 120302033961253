import React from 'react'

import {DropboxOutlined} from '@ant-design/icons'
import {Row, Col} from 'antd'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {change} from 'redux-form'
import styled from 'styled-components'

import {ReactComponent as DropdownIcon} from '../../../../../assets/icons/dropdown_blue.svg'
import {
  TableRow,
  ListCol,
  TableTitle,
  TableText
} from '../../../../../shared/components/table/CustomTableStyles'
import {TitleH3} from '../../../../../shared/styles/BasicStyles'
import ItemsInBox from './ItemsInBox'

const EmptyTable = styled.div`
  display: flex;
  width: 100%;
  margin-top: 3px;
  background: white;
  padding: 50px 30px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 0.6;
  font-size: 18px;
  border-radius: 4px;

  .anticon {
    font-size: 40px;
    margin-bottom: 10px;
  }
`

const ToggleDropdown = styled(DropdownIcon)`
  display: inline-block;
  transform: ${({open}) => (open ? 'rotate(-180deg)' : 'rotate(0)')};
  transition: all 0.2s ease;
  cursor: pointer;
`

const CalculateBoxQuantity = (box) => {
  let total = 0
  if (box && box.items) {
    box.items.forEach((item) => {
      total += item.quantity
    })
  }
  return total
}

const BoxDetailTable = ({formName, formValues, dispatch}) => {
  const boxes = formValues?.boxes || []

  const toggleBox = (index) => {
    dispatch(
      change(formName, `boxes[${index}].open`, !boxes[index].open)
    )
  }

  if (!boxes.length) return null

  return (
    <Row gutter={[16, 40]}>
      <Col xs={12}>
        <TitleH3 $op>Kits and Boxes</TitleH3>
      </Col>
      <Col xs={24}>
        <TableRow $header>
          <ListCol xs={4}>
            <TableTitle first text='Package ID' />
          </ListCol>
          <ListCol xs={2}>
            <TableTitle text='Type' />
          </ListCol>
          <ListCol xs={1}>
            <TableTitle text='Items' />
          </ListCol>
          <ListCol xs={5}>
            <TableTitle text='Employee' />
          </ListCol>
          <ListCol xs={6}>
            <TableTitle text='Delivery Address' />
          </ListCol>
          <ListCol xs={5}>
            <TableTitle text='Final Address' />
          </ListCol>
          <ListCol xs={1} />
        </TableRow>
        {boxes.length === 0 && (
          <EmptyTable>
            <DropboxOutlined />
            Select products to create boxes
          </EmptyTable>
        )}
        {boxes.map((box, index) => (
          <TableRow key={index}>
            <ListCol xs={4}>
              <TableText $first $align='left'>{box.packageId}</TableText>
            </ListCol>
            <ListCol xs={2}>
              <TableText $align='left'>
                {box.isKit ? 'Kit' : 'Box'}
              </TableText>
            </ListCol>
            <ListCol xs={1}>
              <TableText>{CalculateBoxQuantity(box)}</TableText>
            </ListCol>
            <ListCol xs={5}>
              <TableText $align='left'>
                {box.isKit ? (
                  box.employeeName
                ) : '-'}
              </TableText>
            </ListCol>
            <ListCol xs={6}>
              <TableText $align='left'>
                {`${box.deliveryAddressLine1 || ''} ${box.deliveryAddressLine2 || ''} ${
                  box.deliveryAddressLine3 || ''
                } ${box.deliveryAddressCity || ''} ${box.deliveryAddressZipcode || ''}`}
              </TableText>
            </ListCol>
            <ListCol xs={5}>
              <TableText $align='left'>
                {box.isKit ? (
                  `${box.finalAddressLine1 || ''} ${box.finalAddressLine2 || ''} ${
                    box.finalAddressLine3 || ''
                  } ${box.finalAddressCity || ''} ${box.finalAddressZipcode || ''}`
                ) : '-'}
              </TableText>
            </ListCol>
            <ListCol xs={1}>
              <TableText $first>
                <ToggleDropdown
                  open={box.open}
                  onClick={() => toggleBox(index)}
                />
              </TableText>
            </ListCol>
            <ItemsInBox box={box} />
          </TableRow>
        ))}
      </Col>
    </Row>
  )
}

BoxDetailTable.propTypes = {
  dispatch: PropTypes.func.isRequired,
  formValues: PropTypes.object
}

BoxDetailTable.defaultProps = {
  formValues: {}
}

export default connect()(BoxDetailTable)
