import styled from "styled-components";

import {
  NavBackgroundColor,
  TableTextColor,
  PrimaryColor,
} from "../../shared/styles/_colors";
import {
  BoldFontWeight,
  BookFontWeight,
  Subtitle2Text,
  TabsText,
} from "../../shared/styles/_texts";
import { BodyH5 } from "../../shared/styles/BasicStyles";

export const ChartContainer = styled.div`
  position: relative;
  width: 100%;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
  background-color: ${NavBackgroundColor};
  height: 600px;
  overflow: hidden;

  @media (max-width: 500px) { 
    height:auto
  }
`;

export const DashboardLoading = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  color: ${PrimaryColor};
`;

export const StyledVictoryChartWrapper = styled.div`
  position: relative;
  display: flex;
  min-height: 300px;
  align-items: center;
  max-width: 600px;
  margin: auto;
  svg {
    overflow: visible;
  }
`;

export const RelativeContainer = styled.div`
  position: relative;
`;

export const ChartLoading = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  z-index: 2;
  color: ${PrimaryColor};
  background: rgba(255, 255, 255, 0.5);
`;

export const DashboardTitle = styled.div`
  width: 100%;
  position: relative;
  display: inline-block;
  text-align: left;
  font-size: 18px;
  margin-top: 15px;
  margin-bottom: 10px;
`;

export const DashboardSubtitle = styled.div`
  width: 100%;
  color: black;
  position: relative;
  display: inline-block;
  text-align: left;
  font-size: 14px;
  margin-top: 10px;
`;

export const DashboardUserContainer = styled.div`
  width: 100%;
  display: inline-block;
  margin-bottom: 20px;
`;

export const StyledMenuIcon = styled.img`
  cursor: pointer;
`;

export const LearnMoreText = styled(BodyH5)`
  text-decoration: underline;
  cursor: pointer;
`;

export const LegendItemColor = styled.div`
  border-radius: 4px;
  background-color: ${({ $color }) => $color};
  box-shadow: 0 5px 10px 0 rgba(198, 200, 202, 0.25);
  height: 15px;
  width: 15px;
  margin-left: 20px;
  margin-right: 5px;
`;

export const LegendItemText = styled(BodyH5)`
  text-transform: capitalize;
  max-width: calc(100% - 20px);
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  max-height: 20px;
  height: 20px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const ChartSeparator = styled.div`
  display: inline-block;
  width: 100%;
  height: 5px;
  background-color: ${({ $color }) => $color};
`;

export const PieChartLabelContainer = styled.div`
  position: relative;
  cursor: pointer;
  height: 420px;
`;

export const PieChartLabel = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  max-width: 150px;
`;

export const PieChartLabelText = styled.div`
  display: inline-block;
  width: 100%;
  height: 5px;
  background-color: ${({ $color }) => $color};
`;

export const ChartTitle = styled.div`
  color: ${TableTextColor};
  font-size: ${TabsText};
  line-height: 24px;
  font-weight: ${BoldFontWeight};
  margin-top: 14px;
`;

export const ChartSubTitle = styled.div`
  color: ${TableTextColor};
  font-size: ${Subtitle2Text};
  line-height: 17px;
  font-weight: ${BookFontWeight};
  opacity: 40%;
`;

export const CockpitTableContainer = styled.div`
  padding: 20px 10px;
`;

export const GraphicContainer = styled.div`
  padding: 0px;
`;

export const EmptyDashboardMessage = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: 155px 0;
  font-size: 22px;
  .anticon {
    font-size: 60px;
    margin-bottom: 10px;
  }
`;
